import React from 'react';
import SalesActivityCard from './SalesActivityCard';

interface IProps {
    salesActivity: any[];
}

const SalesActivities: React.FC<IProps> = (props) => {
    return (
        <>
            {props.salesActivity && props.salesActivity.map((activity, index) => <SalesActivityCard salesActivity={activity} key={index} />)}
        </>
    );
};

export default SalesActivities;