import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './CreateVoucher.css';
import { IonAlert, IonButton, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonPicker, IonRow, IonSelect, IonSelectOption, IonToast, useIonAlert } from '@ionic/react';
import { getFarmIdsOfUser, getPlotIdsOfFarm } from '../services/farm.service';
import { useLocation } from 'react-router-dom';
import { authorizePayment, createOrder, getPlans, getPlotPlan, getRecharges, getVouchers, updatePlotPlan } from '../services/payment.service';
import { addCircle, removeCircle } from 'ionicons/icons';
import { handleErrors } from '../services/common.service';
import Vouchers from './Vouchers';
import UserList from '../pages/UserList';

const FarmPicker = {
    name: "FarmId",
    options: [] as any[]
};

const setFarmPicker = (farmIds: any[]) => {
    FarmPicker.options = [];
    farmIds.forEach(item => {
        FarmPicker.options.push({
            text: item.farmId,
            value: item.farmId
        })
    });
};

const PlotPicker = {
    name: "PlotId",
    options: [] as any[]
};

const setPlotPicker = (plotIds: any[]) => {
    PlotPicker.options = [];
    plotIds.forEach(item => {
        PlotPicker.options.push({
            text: item.plotId,
            value: item.plotId
        })
    });
};

const CreateVoucher = () => {
    const [present] = useIonAlert();
    const location = useLocation();

    const formik = useFormik({
        initialValues: {
            farmUserId: '',
            farmId: '',
            plotId: '',
            planId: '',
            vouchers: [{
                rechargeId: '',
                count: 1
            }]
        },
        validationSchema: Yup.object({
            farmUserId: Yup.string().trim().required('Required'),
            farmId: Yup.string().trim().required('Required'),
            plotId: Yup.string().trim().required('Required'),
            planId: Yup.string().trim().required('Required'),
            vouchers: Yup.array().of(
                Yup.object().shape({
                    rechargeId: Yup.string().trim().required('Required')
                }))
        }),
        onSubmit: values => {
            setShowConfirmation(true);
        },
    });

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [prefilled, setPrefilled] = useState(false);
    const [onSuccess, setOnSuccess] = useState('');
    const [onError, setOnError] = useState('');

    useEffect(() => {
        const farmUserId = (location.state as any)?.farmUserId ?? '';
        if (farmUserId) {
            formik.setFieldValue('farmUserId', (location.state as any)?.farmUserId ?? '');
            formik.setFieldValue('farmId', (location.state as any)?.farmId ?? '');
            formik.setFieldValue('plotId', (location.state as any)?.plotId ?? '');
            setPrefilled(true);
        }
    }, [location]);

    const [farmPickerIsOpen, setFarmPickerIsOpen] = useState(false);
    useEffect(() => {
        let mounted = true;
        if (formik.values.farmUserId) {
            getFarmIdsOfUser(formik.values.farmUserId)
                .then(items => {
                    if (mounted) {
                        setFarmPicker(items)
                    }
                })
        }
        return () => { mounted = false };
    }, [formik.values.farmUserId])

    const [plotPickerIsOpen, setPlotPickerIsOpen] = useState(false);
    useEffect(() => {
        let mounted = true;
        if (formik.values.farmId) {
            getPlotIdsOfFarm(formik.values.farmId)
                .then(items => {
                    if (mounted) {
                        setPlotPicker(items)
                    }
                })
        }
        return () => { mounted = false };
    }, [formik.values.farmId])

    const [plans, setPlans] = useState([] as any[]);
    useEffect(() => {
        let mounted = true;
        getPlans()
            .then(items => {
                if (mounted) {
                    setPlans(items);
                }
            })
        return () => { mounted = false };
    }, [])

    const [recharges, setRecharges] = useState([] as any[]);
    useEffect(() => {
        let mounted = true;
        if (formik.values.planId) {
            getRecharges(formik.values.planId)
                .then(items => {
                    if (mounted) {
                        setRecharges(items);
                        resetVouchers();
                    }
                })
        }
        return () => { mounted = false };
    }, [formik.values.planId])

    const [currentPlotPlan, setCurrentPlotPlan] = useState();
    useEffect(() => {
        let mounted = true;
        if (formik.values.plotId) {
            getPlotPlan(formik.values.plotId)
                .then((data: any) => {
                    if (mounted) {
                        if (data.plotId) {
                            setCurrentPlotPlan(data);
                            formik.setFieldValue(`planId`, data.planId);
                        } else {
                            setCurrentPlotPlan(undefined);
                            formik.setFieldValue(`planId`, '');
                        }
                    }
                })
        }
        return () => { mounted = false };
    }, [formik.values.plotId])

    const [vouchers, setVouchers] = useState([]);
    useEffect(() => {
        let mounted = true;
        if (formik.values.plotId) {
            getVouchers(formik.values.plotId)
                .then((data: any) => {
                    if (mounted) {
                        setVouchers(data);
                    }
                })
        }
        return () => { mounted = false };
    }, [formik.values.plotId])

    const removeVoucherHandler = (voucher: any) => {
        const index = vouchers.findIndex((item: any) => item.id === voucher.id);
        vouchers.splice(index, 1);
        setVouchers([...vouchers]);
    }

    const [showSelectUserModal, setShowSelectUserModal] = useState(false);

    function setFarmUserId(item: any) {
        formik.setFieldValue('farmUserId', item.farmUserId);
        formik.setFieldValue('farmId', '');
        formik.setFieldValue('plotId', '');
        setVouchers([]);
        setShowSelectUserModal(false);
    }

    function updateVoucherCount(index: number, value: number) {
        formik.setFieldValue(`vouchers[${index}].count`, formik.values.vouchers[index].count + value);
    }

    function addVoucher() {
        formik.values.vouchers.push({
            rechargeId: '',
            count: 1
        });
        formik.setFieldValue(`vouchers`, formik.values.vouchers);
    }

    function removeVoucher() {
        formik.values.vouchers.pop();
        formik.setFieldValue(`vouchers`, formik.values.vouchers);
    }

    function resetVouchers() {
        formik.values.vouchers = [{
            rechargeId: '',
            count: 1
        }];
        formik.setFieldValue(`vouchers`, formik.values.vouchers);
    }

    const savePlotPlan = async (values: any) => {
        try {
            const plotPlanData = {
                plotId: formik.values.plotId,
                userId: formik.values.farmUserId,
                planId: formik.values.planId
            }
            const response = await updatePlotPlan(plotPlanData, currentPlotPlan).then(handleErrors);
            if (response) {
                setCurrentPlotPlan(response);
            }

            const newVouchers = [] as any[];
            formik.values.vouchers.forEach(item => {
                for (let i = 0; i < item.count; i++) {
                    newVouchers.push({
                        plotId: formik.values.plotId,
                        rechargeId: item.rechargeId
                    });
                }
            });
            const createdOrder = await createOrder(formik.values.farmUserId, newVouchers).then(handleErrors);
            present({
                header: 'Authorize',
                message: `Authorize payment of ${createdOrder.currency} ${createdOrder.amount}`,
                buttons: [
                    'Cancel',
                    {
                        text: 'Ok', handler: async (d) => {
                            await authorizePayment(createdOrder.id).then(handleErrors);
                            const plotId = formik.values.plotId;
                            formik.setFieldValue(`plotId`, '');
                            setTimeout(() => {
                                formik.setFieldValue(`plotId`, plotId);
                            }, 100);
                            setOnSuccess(`Successfully created ${newVouchers.length} vouchers`);
                            setTimeout(() => {
                                window.location.reload();
                            }, 1000);
                        }
                    },
                ],
                onDidDismiss: (e) => console.log('did dismiss'),
            });
        } catch (err: any) {
            setOnError(err);
        }
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <IonList>
                    <IonItem>
                        <IonLabel position="stacked">User Id</IonLabel>
                        <IonInput readonly disabled={prefilled} id="farmUserId" name="farmUserId" value={formik.values.farmUserId} placeholder="Select User Id" onClick={e => setShowSelectUserModal(true)}></IonInput>
                    </IonItem>
                    {formik.touched.farmUserId && formik.errors.farmUserId ? (<div className="errorMsg">{formik.errors.farmUserId}</div>) : null}

                    <IonItem>
                        <IonLabel position="stacked">Farm Id</IonLabel>
                        <IonInput readonly disabled={prefilled || !formik.values.farmUserId} id="farmId" name="farmId" value={formik.values.farmId} placeholder="Select Farm Id" onClick={e => setFarmPickerIsOpen(true)}></IonInput>
                    </IonItem>
                    {formik.touched.farmId && formik.errors.farmId ? (<div className="errorMsg">{formik.errors.farmId}</div>) : null}

                    <IonItem>
                        <IonLabel position="stacked">Plot Id</IonLabel>
                        <IonInput readonly disabled={prefilled || !formik.values.farmId} id="plotId" name="plotId" value={formik.values.plotId} placeholder="Plot Id" onClick={e => setPlotPickerIsOpen(true)}></IonInput>
                    </IonItem>
                    {formik.touched.plotId && formik.errors.plotId ? (<div className="errorMsg">{formik.errors.plotId}</div>) : null}

                    <IonItem>
                        <IonLabel position="stacked">Plan</IonLabel>
                        <IonSelect id="planId" name="planId" value={formik.values.planId} placeholder="Select Plan" onIonChange={formik.handleChange}>
                            {plans && plans.map(item => {
                                return <IonSelectOption key={item.id} value={item.id}>{item.name}</IonSelectOption>;
                            })}
                        </IonSelect>
                    </IonItem>
                    {formik.touched.planId && formik.errors.planId ? (<div className="errorMsg">{formik.errors.planId}</div>) : null}

                    <IonItem lines="none">
                        <IonLabel position="floating">Voucher(s)</IonLabel>
                        <IonGrid fixed={true} class="ion-no-padding">
                            {formik.values.vouchers.map((item, index) => {
                                return <React.Fragment key={index}>
                                    <IonRow class="ion-no-padding">
                                        <IonCol size="6" class="ion-no-padding">
                                            <IonSelect className="recharge-select" id={`vouchers[${index}].rechargeId`} name={`vouchers[${index}].rechargeId`} disabled={!formik.values.planId} value={formik.values.vouchers[index].rechargeId} placeholder="Select Recharge" onIonChange={formik.handleChange}>
                                                {recharges && recharges.map(item => {
                                                    return <IonSelectOption key={item.id} value={item.id}>{item.name}</IonSelectOption>;
                                                })}
                                            </IonSelect>
                                        </IonCol>
                                        <IonCol size="2" class="ion-no-padding ion-text-center">
                                            <IonButton fill="clear" size="small" disabled={formik.values.vouchers[index].count === 1} onClick={e => updateVoucherCount(index, -1)}><IonIcon slot="icon-only" icon={removeCircle} /></IonButton>
                                        </IonCol>
                                        <IonCol size="2" class="ion-no-padding ion-text-center">
                                            <IonButton fill="clear" size="small">{formik.values.vouchers[index].count}</IonButton>
                                        </IonCol>
                                        <IonCol size="2" class="ion-no-padding ion-text-center">
                                            <IonButton fill="clear" size="small" onClick={e => updateVoucherCount(index, 1)}><IonIcon slot="icon-only" icon={addCircle} /></IonButton>
                                        </IonCol>
                                    </IonRow>
                                    {formik.touched.vouchers && formik.touched.vouchers[index] && formik.touched.vouchers[index].rechargeId && formik.errors.vouchers && formik.errors.vouchers[index] && (formik.errors.vouchers[index] as any).rechargeId ? (<div className="errorMsg">{(formik.errors.vouchers[index] as any).rechargeId}</div>) : null}
                                </React.Fragment>
                            })}
                        </IonGrid>
                    </IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol class="ion-text-center">
                                <IonButton fill="clear" onClick={e => addVoucher()}>Add Voucher</IonButton>
                            </IonCol>
                            <IonCol class="ion-text-center">
                                <IonButton fill="clear" disabled={formik.values.vouchers.length === 1} onClick={e => removeVoucher()}>Delete Voucher</IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonList>

                <IonButton expand="block" type="submit">Create Vouchers</IonButton>
            </form>

            {vouchers && <Vouchers vouchers={vouchers} removeVoucherHandler={removeVoucherHandler} />}

            {showSelectUserModal && <IonModal isOpen={showSelectUserModal} >
            <UserList
                    selectedValue={formik.values.farmUserId}
                    onClickHandler={setFarmUserId}
                    onClose={setShowSelectUserModal}
                />
            </IonModal>}

            <IonPicker
                isOpen={farmPickerIsOpen}
                columns={[FarmPicker]}
                buttons={[
                    {
                        text: "Cancel",
                        role: "cancel",
                        handler: value => {
                            setFarmPickerIsOpen(false);
                        }
                    },
                    {
                        text: "Confirm",
                        handler: value => {
                            formik.values.farmId = value.FarmId.value;
                            formik.values.plotId = '';
                            setVouchers([]);
                            setFarmPickerIsOpen(false);
                        }
                    }
                ]}
            ></IonPicker>

            <IonPicker
                isOpen={plotPickerIsOpen}
                columns={[PlotPicker]}
                buttons={[
                    {
                        text: "Cancel",
                        role: "cancel",
                        handler: value => {
                            setPlotPickerIsOpen(false);
                        }
                    },
                    {
                        text: "Confirm",
                        handler: value => {
                            formik.values.plotId = value.PlotId.value;
                            setPlotPickerIsOpen(false);
                        }
                    }
                ]}
            ></IonPicker>

            <IonAlert
                isOpen={showConfirmation}
                onDidDismiss={() => setShowConfirmation(false)}
                header={'Confirm!'}
                message={'Please make sure that you have verified all the plot and plan details. Create Vouchers?'}
                buttons={[
                    {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary'
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            savePlotPlan(formik.values);
                        }
                    }
                ]}
            />

            <IonToast
                isOpen={!!onSuccess}
                onDidDismiss={() => setOnSuccess('')}
                message={onSuccess}
                duration={2000}
                color="success"
            />

            <IonToast
                isOpen={!!onError}
                onDidDismiss={() => setOnError('')}
                message={onError}
                duration={2000}
                color="danger"
            />
        </>
    );
};

export default CreateVoucher;