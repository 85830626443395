import {
    IonContent, IonHeader, IonPage,
    IonTitle, IonToolbar, IonButtons, IonMenuButton,
    IonCard, IonItem, IonLabel, IonButton
} from '@ionic/react';
import { useEffect, useState } from 'react';
import CustomSearchBox from '../components/CustomSearchBox/CustomSearchBox';
import { getAssignedDevice, unassignIMEI } from '../services/plot.service';

const UnassignIMEI: React.FC = () => {
    const [searchText, setSearchText] = useState('');

    const [devices, setDevices] = useState([] as any[]);
    useEffect(() => {
        let mounted = true;
        getAssignedDevice(searchText)
            .then(items => {
                if (mounted) {
                    setDevices(items);
                }
            });
        return () => { mounted = false };
    }, [searchText]);

    function unassign(imei: string) {
        unassignIMEI(imei).then(() => {
            getAssignedDevice(searchText)
                .then(items => {
                    setDevices(items);
                });
        });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Unassign IMEI</IonTitle>
                </IonToolbar>
                <CustomSearchBox setSearchText={setSearchText} />
            </IonHeader>
            <IonContent>
                {
                    devices && devices[0] &&
                    <IonCard>
                        <IonItem>
                            <IonLabel>{devices[0].imei}</IonLabel>
                        </IonItem>
                        <IonItem lines='none'>
                            <IonLabel>
                                <h2>Plot Id: {devices[0].plotId}</h2>
                            </IonLabel>
                        </IonItem>
                        <IonItem lines='none' >
                            <IonLabel>
                                <h2>Device Type: {devices[0].deviceType}</h2>
                            </IonLabel>
                        </IonItem>
                        {devices[0].installationDate && <IonItem lines='none' >
                            <IonLabel>
                                <h2>Installed On: {new Intl.DateTimeFormat('en-GB', {
                                    month: 'short',
                                    day: '2-digit',
                                    year: 'numeric',
                                }).format(new Date(devices[0].installationDate))}</h2>
                            </IonLabel>
                        </IonItem>}
                        <IonItem lines='none' >
                            <IonButton onClick={() => unassign(devices[0].imei)}>Unassign</IonButton>
                        </IonItem>
                    </IonCard>
                }

            </IonContent>
        </IonPage>
    );
};

export default UnassignIMEI;
