import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonMenuButton, IonBackButton } from '@ionic/react';
import CreateSalesPlan from '../components/CreateSalesPlan';
const SalesPlan: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Sales & Marketing Plan</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Sales & Marketing Plan</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <CreateSalesPlan />
            </IonContent>
        </IonPage>
    );
};

export default SalesPlan;
