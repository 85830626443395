import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonList, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonInput, IonModal, IonPicker } from '@ionic/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import './SelectMasterPlot.css';
import { getFarmIdsOfUser, getPlotIdsOfFarm } from '../services/farm.service';
import UserList from '../pages/UserList';

interface IProps {
    setMasterPlot: Function;
    setShowMasterPlotModal: Function;
}

const FarmPicker = {
    name: "FarmId",
    options: [] as any[]
};

const setFarmPicker = (farmIds: any[]) => {
    FarmPicker.options = [];
    farmIds.forEach(item => {
        FarmPicker.options.push({
            text: item.farmId,
            value: item.farmId
        })
    });
};

const PlotPicker = {
    name: "PlotId",
    options: [] as any[]
};

const setPlotPicker = (plotIds: any[]) => {
    PlotPicker.options = [];
    plotIds.forEach(item => {
        PlotPicker.options.push({
            text: item.plotId,
            value: item.plotId
        })
    });
};


const SelectMasterPlot: React.FC<IProps> = (props) => {
    const formik = useFormik({
        initialValues: {
            farmUserId: '',
            farmId: '',
            plotId: ''
        },
        validationSchema: Yup.object({
            farmUserId: Yup.string().trim().required('Required'),
            farmId: Yup.string().trim().required('Required'),
            plotId: Yup.string().trim().required('Required')
        }),
        onSubmit: values => {
            props.setMasterPlot(values.plotId);
        },
    });

    const [showSelectUserModal, setShowSelectUserModal] = useState(false);

    function setFarmUserId(item: any) {
        formik.setFieldValue('farmUserId', item.farmUserId);
        formik.setFieldValue('farmId', '');
        formik.setFieldValue('plotId', '');
        setShowSelectUserModal(false);
    }

    const [farmPickerIsOpen, setFarmPickerIsOpen] = useState(false);
    useEffect(() => {
        let mounted = true;
        if (formik.values.farmUserId) {
            getFarmIdsOfUser(formik.values.farmUserId)
                .then(items => {
                    if (mounted) {
                        setFarmPicker(items)
                    }
                })
        }
        return () => { mounted = false };
    }, [formik.values.farmUserId])

    const [plotPickerIsOpen, setPlotPickerIsOpen] = useState(false);
    useEffect(() => {
        let mounted = true;
        if (formik.values.farmId) {
            getPlotIdsOfFarm(formik.values.farmId)
                .then(items => {
                    if (mounted) {
                        setPlotPicker(items)
                    }
                })
        }
        return () => { mounted = false };
    }, [formik.values.farmId])

    function onClose() {
        props.setMasterPlot('');
        props.setShowMasterPlotModal(false);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Select Master Plot</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <form onSubmit={formik.handleSubmit}>
                    <IonList>
                        <IonItem>
                            <IonLabel position="stacked">User Id</IonLabel>
                            <IonInput readonly id="farmUserId" name="farmUserId" value={formik.values.farmUserId} placeholder="Select User Id" onClick={e => setShowSelectUserModal(true)}></IonInput>
                        </IonItem>
                        {formik.touched.farmUserId && formik.errors.farmUserId ? (<div className="errorMsg">{formik.errors.farmUserId}</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">Farm Id</IonLabel>
                            <IonInput readonly disabled={!formik.values.farmUserId} id="farmId" name="farmId" value={formik.values.farmId} placeholder="Select Farm Id" onClick={e => setFarmPickerIsOpen(true)}></IonInput>
                        </IonItem>
                        {formik.touched.farmId && formik.errors.farmId ? (<div className="errorMsg">{formik.errors.farmId}</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">Plot Id</IonLabel>
                            <IonInput readonly disabled={!formik.values.farmId} id="plotId" name="plotId" value={formik.values.plotId} placeholder="Plot Id" onClick={e => setPlotPickerIsOpen(true)}></IonInput>
                        </IonItem>
                        {formik.touched.plotId && formik.errors.plotId ? (<div className="errorMsg">{formik.errors.plotId}</div>) : null}

                    </IonList>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonButton className="shiftRight" type="submit">Select</IonButton></IonCol>
                            <IonCol><IonButton onClick={onClose}>Close</IonButton></IonCol>
                        </IonRow>
                    </IonGrid>

                </form>

                {showSelectUserModal && <IonModal isOpen={showSelectUserModal} >
                <UserList
                    selectedValue={formik.values.farmUserId}
                    onClickHandler={setFarmUserId}
                    onClose={setShowSelectUserModal}
                />
                </IonModal>}

                <IonPicker
                    isOpen={farmPickerIsOpen}
                    columns={[FarmPicker]}
                    buttons={[
                        {
                            text: "Cancel",
                            role: "cancel",
                            handler: value => {
                                setFarmPickerIsOpen(false);
                            }
                        },
                        {
                            text: "Confirm",
                            handler: value => {
                                formik.values.farmId = value.FarmId.value;
                                formik.values.plotId = '';
                                setFarmPickerIsOpen(false);
                            }
                        }
                    ]}
                ></IonPicker>

                <IonPicker
                    isOpen={plotPickerIsOpen}
                    columns={[PlotPicker]}
                    buttons={[
                        {
                            text: "Cancel",
                            role: "cancel",
                            handler: value => {
                                setPlotPickerIsOpen(false);
                            }
                        },
                        {
                            text: "Confirm",
                            handler: value => {
                                formik.values.plotId = value.PlotId.value;
                                setPlotPickerIsOpen(false);
                            }
                        }
                    ]}
                ></IonPicker>
            </IonContent>
        </IonPage>
    );
};

export default SelectMasterPlot;
