import { getData, postData } from "./common.service";

export async function getFarmUserIds() {
    return getData('/farmusers/farmuserids', {
        sendTokenInUrl: true
    }).then(data => data.json())
}
export async function getFarmUserIdsPaginated(text: string = "", skip: number = 0, limit: number = 20) {
    const qs = {
        where: {
            or:
                [
                    { farmUserId: { like: `.*${text}.*`, options: 'i' } },
                    { name: { like: `.*${text}.*`, options: 'i' } },
                ]
        },
        limit: limit,
        skip: skip,
        fields: {
            farmUserId: true,
            name: true
        }
    };
    return getData("/farm-users?filter=" + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    }).then((data) => data.json());
}

export async function getFarms(text: string = '', skip = 0) {
    const qs = {
        where: {
            or:
                [
                    { farmId: { like: `.*${text}.*`, options: 'i' } },
                    { name: { like: `.*${text}.*`, options: 'i' } },
                    { place: { like: `.*${text}.*`, options: 'i' } },
                    { taluka: { like: `.*${text}.*`, options: 'i' } },
                    { district: { like: `.*${text}.*`, options: 'i' } },
                    { farmUserId: { like: `.*${text}.*`, options: 'i' } }
                ]
        },
        order: 'created_date DESC',
        include: [
            {
                relation: 'farmUser',
                scope: {
                    fields: ['name', 'mobile'],
                }
            },
            {
                relation: 'plots',
                scope: {
                    fields: ['plotId', 'name'],
                }
            }
        ],
        limit: 20,
        skip: skip
    };
    return getData('/farms?filter=' + JSON.stringify(qs), {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function getFarm(farmId: string) {
    return getData(`/farms/${farmId}`, {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function searchFarm(farmId: string){
    const qs = {
        'fields': {
            'farmId': true
        }
    }
    return getData(`/farms/${farmId}?filter=` + JSON.stringify(qs), { sendTokenInUrl: true })
            .then(data => data.json())
            .catch((e) => console.log(e));
}


export async function getFarmIdsOfUser(farmUserId: string) {
    return getData(`/farms/farmuserid/${farmUserId}`, {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function getPlotIdsOfFarm(farmId: string) {
    return getData(`/farms/${farmId}/plots`, {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function registerFarm(farmData: any) {
    return postData('/farms/register', {
        sendTokenInUrl: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: farmData
    });
}

export async function updateFarm(farmData: any) {
    return postData('/farms', {
        sendTokenInUrl: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: farmData,
        usePutMethod: true
    });
}

export async function postpubliclyAccessible(farmId: string, value: boolean) {
    return postData(`/farms/${farmId}/public`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        usePatchMethod: true,
        body: {
            value: value
        }
    })
}