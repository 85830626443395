import { getData, postData } from "./common.service";

export async function getLeads(text: string = '', farmUserId='',  skip = 0) {
    const qs = {
        where: {
            and: [
                { account: 'lead' }, 
                { servicedBy: farmUserId },
                { status: { inq: ['new', 'open'] }},
                {
                    or:
                        [
                            { type: { like: `.*${text}.*`, options: 'i' } },
                            { source: { like: `.*${text}.*`, options: 'i' } },
                            { contactName: { like: `.*${text}.*`, options: 'i' } },
                            { contactPhoneNumber: { like: `.*${text}.*`, options: 'i' } },
                            { location: { like: `.*${text}.*`, options: 'i' } },
                            { response: { like: `.*${text}.*`, options: 'i' } },
                        ]
                }
            ]
        },
        include: [{
            relation: 'servicer',
            scope: {
                fields: ['farmUserId', 'name'],
            }
        }],
        order: 'date DESC',
        limit: 20,
        skip: skip
    };
    return getData('/leads?filter=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getOpenLeads(userId: string) {
    const qs = {
            status: 'open',
            followUpDate: {gte: new Date()},
            servicedBy: userId
    };
    return getData('/leads/count?where=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getUnattendedLeads(userId: string) {
    const qs = {
            status: 'open',
            followUpDate: {lt: new Date()},
            servicedBy: userId
    };
    return getData('/leads/count?where=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getUpcomingFollowups(userId: string) {
    const qs = {
        where: {
            followUpDate: { gte: new Date().setHours(0, 0, 0, 0) },
            servicedBy: userId
        },
        order: 'followUpDate ASC',
        limit: 10
    };
    return getData('/leads?filter=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getLead(leadId: string) {
    const qs = {
        include: [{
            relation: 'servicer',
            scope: {
                fields: ['farmUserId', 'name'],
            }
        }]
    };
    return getData(`/leads/${leadId}?filter=` + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getLeadConfig() {
    return getData('/leads/config', {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function addLead(data: any) {
    return postData('/leads', {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: [data]
    })
}

export async function updateLead(data: any) {
    return postData(`/leads/${data.id}`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        usePutMethod: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    })
}

export async function getMeetings(text: string = '', skip = 0) {
    const qs = {
        where: {
            and: [
                {
                    account: 'sales_meeting'
                },
                {
                    or:
                        [
                            { location: { like: `.*${text}.*`, options: 'i' } },
                            { taluka: { like: `.*${text}.*`, options: 'i' } },
                            { district: { like: `.*${text}.*`, options: 'i' } },
                            { state: { like: `.*${text}.*`, options: 'i' } },
                            { servicedBy: { like: `.*${text}.*`, options: 'i' } },
                        ]
                }
            ]
        },
        include: [{
            relation: 'servicer',
            scope: {
                fields: ['farmUserId', 'name'],
            }
        }],
        order: 'date DESC',
        limit: 20,
        skip: skip
    };
    return getData('/leads?filter=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}