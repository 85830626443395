import { getData, postData } from './common.service';

export async function getTargetByRegion(regionId: string, month: string, year: string) {
    const qs = {
      where: {
        region: regionId,
        month: month,
        year: year
      }
    }
    const url = `/targets?filter=${JSON.stringify(qs)}`;
  
    return getData(url, {
      sendTokenInUrl: true,
      basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    }).then(res => res.json());
  }


  export async function updateTarget(payload: any) {
  
    const {
      id,
      month,
      year,
      region,
      revenue, 
      installation, 
      collections,
      weeklyInstallation
    } = payload;
  
    const url = `/targets/${id}`;
  
    return postData(url, {
      sendTokenInUrl: true,
      basePath: process.env.REACT_APP_CORE_API_BASEPATH,
      usePutMethod: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: {
        id: id,
        month: month,
        year: year,
        region: region,
        revenue: revenue, 
        installation: installation, 
        collections: collections,
        weeklyInstallation: weeklyInstallation
      },
    });
  }

