import { useEffect, useState } from "react";
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonIcon,
  IonModal,
  IonHeader,
  IonToast,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import useNavigation from "../hooks/useNavigation";
import { getTargetRegions } from "../services/location.service";
import {
  addSalesPlan,
  getPlanById,
  updateSalesPlan,
  getCompletedSalesActivity,
  getAllSalesActivities,
} from "../services/sales.service";
import { addCircle } from "ionicons/icons";

import SelectItem from "./SelectItem";
import { handleErrors } from "../services/common.service";
import { useLocation } from "react-router";
import { getTargetByRegion, updateTarget } from "../services/target.service";

interface PlanOption {
  id: string;
  description: string;
}

interface Activities {
  [key: string]: number[];
}

interface SalesPlan {
  id: string;
  month: number;
  year: number;
  region: string;
  subDistrict: string;
  village: string;
  plans: object;
}

const CreateSalesPlan = () => {
  const navigateTo = useNavigation();
  const location = useLocation();
  const [salesPlan, setsalesPlan] = useState<SalesPlan>();
  const [regionOptions, setRegionOptions] = useState([]);
  const [planOptions, setPlanOptions] = useState<PlanOption[]>([]);
  const [showLocation, setShowLocation] = useState({
    state: false,
    district: false,
    subDistrict: false,
    village: false,
    region: false,
  });
  const [activites, setActivites] = useState<Activities>({});
  const [region, setRegion] = useState("");
  const [onSuccess, setOnSuccess] = useState("");
  const [onError, setOnError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [showPlans, setShowPlans] = useState(true);
  const [completedActivity, setCompletedActivity] = useState<any>();
  const [targetEstimations, setTargetEstimations] = useState<any>(null);
  const [updatedEstimations, setUpdatedEstimations] = useState<any>(null);
  const [regionReport, setRegionReport] = useState<any>();

  useEffect(() => {
    let mounted = true;
    const id: any = (location.state as any)?.salesPlanId ?? null;
    if (id) {
      getPlanById(id).then((plan) => {
        if (mounted) {
          setRegion(plan.region);
          setActivites(plan.plans);

          const formattedMonth =
            plan.month < 10 ? `0${plan.month}` : plan.month.toString();
          getTargetByRegion(
            plan.region,
            formattedMonth,
            plan.year.toString()
          ).then((res) => {
            if (res.length) {
              setRegionReport(res[0]);
              const defaultWeeklyInstallation = {
                first: 0,
                second: 0,
                third: 0,
                fourth: 0,
              }
              const { collections, installation, revenue, weeklyInstallation=defaultWeeklyInstallation } = res[0];
              const estimations = {
                revenue: revenue.estimation,
                collections: collections.estimation,
                installation: installation.estimation,
                weeklyInstallation: weeklyInstallation
              };
              setTargetEstimations(estimations);
              setUpdatedEstimations(estimations);
            } else {
              setRegionReport(null);
              setTargetEstimations(null);
              setUpdatedEstimations(null);
            }
          });
          setsalesPlan(plan);
        }
      });
      getCompletedSalesActivity(id).then((activity) => {
        countSalesActivitiesByDateRange(activity);
      });
    } else {
      setRegion("");
      setsalesPlan(undefined);
    }
    return () => {
      mounted = false;
    };
  }, [location]);

  useEffect(() => {
    getTargetRegions().then((res) => {
      setRegionOptions(res);
    });
  }, [showLocation]);

  useEffect(() => {
    let mounted = true;
    getAllSalesActivities().then((res) => setPlanOptions(res));
    mounted = false;
  }, []);

  useEffect(() => {
    let mounted = true;
    if (!salesPlan?.id) {
      setActivites((prevData) => {
        const newActivities: Activities = {};
        planOptions.forEach((option: PlanOption) => {
          newActivities[option.id] = [0, 0, 0];
        });
        return newActivities;
      });
    }

    setShowPlans(salesPlan ? false : true);
    mounted = false;
  }, [salesPlan, planOptions]);

  const countSalesActivitiesByDateRange = (data: any) => {
    const countsByType: any = {};

    data.forEach((item: any) => {
      const dayOfMonth = new Date(item.date).getDate();

      let range;
      if (dayOfMonth <= 10) {
        range = 1;
      } else if (dayOfMonth <= 20) {
        range = 2;
      } else {
        range = 3;
      }

      if (!countsByType[item.salesActivityTypeId]) {
        countsByType[item.salesActivityTypeId] = [0, 0, 0];
      }
      countsByType[item.salesActivityTypeId][range - 1]++;
    });

    setCompletedActivity(countsByType);
  };
  const handleInputChange = (key: string, index: number, value: string) => {
    setActivites((prevData) => ({
      ...prevData,
      [key]: prevData[key].map((item, i) =>
        i === index ? parseFloat(value) : item
      ),
    }));
  };

  const handleEstimationChange = (
    key: string,
    index: string,
    value: number
  ) => {
    setUpdatedEstimations((prevEstimations: any) => ({
      ...prevEstimations,
      [key]: {
        ...prevEstimations[key],
        [index]: value,
      },
    }));
  };

  

  const submit = async () => {
    setDisabled(true);
    try {
      if (region.length > 0) {
        if (!salesPlan) {
          const response = await addSalesPlan({
            region,
            plans: activites,
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
          }).then(handleErrors);

          setOnSuccess("Sales Plan Added");
        } else {
          const response = await updateSalesPlan(salesPlan?.id, {
            region,
            plans: activites,
            month: salesPlan?.month,
            year: salesPlan?.year,
          }).then(handleErrors);
         if (!!regionReport && !!updatedEstimations) {
            const payloadRegionReport = regionReport;
            Object.keys(updatedEstimations)?.map((key: string) => {
              if(key!=="weeklyInstallation"){
              payloadRegionReport[key].estimation = updatedEstimations[key];
              }
            else{
              payloadRegionReport[key] = updatedEstimations[key];
            }
            });
            const updateEstimates = await updateTarget(payloadRegionReport)
              .then((res) => console.log(res))
              .catch((err) => console.log(err));
          }
          setOnSuccess("Sales Plan Updated");
        }
        navigateTo("/salesPlans", { reload: true });
      } else setOnError("Some Error occurred");
    } catch (err: any) {
      setOnError(err);
    }

    setDisabled(false);
  };

  return (
    <>
      <IonItem>
        <IonLabel position="stacked">Region</IonLabel>
        <IonInput
          readonly
          id="region"
          name="region"
          value={region}
          placeholder="Select Region"
          onClick={(e) =>
            !salesPlan && setShowLocation({ ...showLocation, region: true })
          }
        ></IonInput>
      </IonItem>

      <IonItem
        button
        style={{ alignItems: "center" }}
        onClick={() => setShowPlans(!showPlans)}
      >
        <IonHeader style={{ fontSize: 23 }}>Plans</IonHeader>
      </IonItem>

      {showPlans &&
        Object.keys(activites)?.map((activity: string) => (
          <IonList key={activity}>
            <IonItem>
              <IonLabel>
                {planOptions?.find((plan) => plan.id === activity)?.description}
              </IonLabel>
            </IonItem>
            <IonGrid>
              <IonRow style={{ alignItems: "baseline" }}>
                {activites[activity]?.map((value, index) => (
                  <IonCol key={index} size="4">
                    <IonItem
                      style={{ position: "relative", paddingRight: "2px" }}
                    >
                      {!!salesPlan ? (
                        <IonRow style={{ alignItems: "center" }}>
                          <IonLabel style={{ marginRight: "4px" }}>
                            {`${completedActivity?.[activity]?.[index] || 0} /`}
                          </IonLabel>
                          <IonInput
                            type="number"
                            step="1"
                            min="0"
                            value={value}
                            onIonChange={(e) =>
                              handleInputChange(
                                activity,
                                index,
                                e.detail.value!
                              )
                            }
                          ></IonInput>
                        </IonRow>
                      ) : (
                        <IonInput
                          type="number"
                          step="1"
                          min="0"
                          disabled={!!salesPlan}
                          value={value}
                          onIonChange={(e) =>
                            handleInputChange(activity, index, e.detail.value!)
                          }
                          style={{ marginBottom: "8px" }}
                        ></IonInput>
                      )}
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: 0,
                          transform: "translateY(-50%)",
                          width: "1px",
                          height: "80%",
                          backgroundColor: "#ccc",
                        }}
                      ></div>
                    </IonItem>
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>
          </IonList>
        ))}

      <IonModal isOpen={showLocation.region}>
        <SelectItem
          label="Select Region"
          selectedValue={region}
          labelKey="name"
          valueKey="id"
          setItem={(item: any) => {
            setRegion(item.id);
            setShowLocation({ ...showLocation, region: false });
          }}
          onClose={() => setShowLocation({ ...showLocation, region: false })}
          items={regionOptions}
        />
      </IonModal>

      {salesPlan && (
        <IonItem
          onClick={() =>
            navigateTo("/tabs/salesActivity", { planId: salesPlan.id })
          }
        >
          <IonLabel>Add Activity</IonLabel>
          <IonIcon icon={addCircle} slot="end" />
        </IonItem>
      )}

      <IonItem style={{ alignItems: "center" }}>
        <IonHeader style={{ fontSize: 23 }}>Estimations</IonHeader>
      </IonItem>
      {targetEstimations &&
        Object.keys(targetEstimations)?.map((item: string) => {
          if(item!=='weeklyInstallation')
          return (
            <IonList key={item}>
              <IonItem>
                <IonLabel>
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                </IonLabel>
              </IonItem>
              <IonRow style={{ alignItems: "baseline" }}>
                {Object.keys(targetEstimations[item])?.map((value, index) => {
                  if (index < 3)
                    return (
                      <IonCol key={index} size="2.8">
                        <IonItem
                          style={{ position: "relative", paddingRight: "2px" }}
                        >
                          <IonRow style={{ alignItems: "center" }}>
                            <IonInput
                              type="number"
                              step="any"
                              min="0"
                              value={updatedEstimations? updatedEstimations[item][value]: targetEstimations[item][value]}
                              onIonChange={(e) =>
                                handleEstimationChange(
                                  item,
                                  value,
                                  parseFloat(e.detail.value!)
                                )
                              }
                              placeholder={
                                value.charAt(0).toUpperCase() + value.slice(1)
                              }
                            ></IonInput>
                          </IonRow>
                        </IonItem>
                      </IonCol>
                    );
                })}
              </IonRow>
            </IonList>
          );
        })}


<IonItem style={{ alignItems: "center" }}>
        <IonHeader style={{ fontSize: 23 }}>Weekly Installation</IonHeader>
      </IonItem>
      {targetEstimations &&             
              <IonRow style={{ alignItems: "baseline" }}>
                {Object.keys(targetEstimations?.weeklyInstallation)?.map((value, index) => {
                    return (
                      <IonCol key={index} size="2.8">
                        <IonItem
                          style={{ position: "relative", paddingRight: "2px" }}
                        >
                          <IonRow style={{ alignItems: "center" }}>
                            <IonInput
                              type="number"
                              step="any"
                              min="0"
                              value={updatedEstimations? updatedEstimations.weeklyInstallation[value]: targetEstimations.weeklyInstallation[value]}
                              onIonChange={(e) =>
                                handleEstimationChange(
                                  'weeklyInstallation',
                                  value,
                                  parseFloat(e.detail.value!)
                                )
                              }
                              placeholder={
                                value.charAt(0).toUpperCase() + value.slice(1)
                              }
                            ></IonInput>
                          </IonRow>
                        </IonItem>
                      </IonCol>
                    );
                })}
              </IonRow>
          
        }

      <IonButton
        disabled={disabled}
        expand="block"
        type="submit"
        onClick={submit}
      >
        {(!salesPlan ? "Create" : "Update") + " Plan"}
      </IonButton>
      <IonToast
        isOpen={!!onSuccess}
        onDidDismiss={() => setOnSuccess("")}
        message={onSuccess}
        duration={2000}
        color="success"
      />

      <IonToast
        isOpen={!!onError}
        onDidDismiss={() => setOnError("")}
        message={onError}
        duration={2000}
        color="danger"
      />
    </>
  );
};

export default CreateSalesPlan;
