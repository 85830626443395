import React from 'react';
import MeetingCard from './MeetingCard';

interface IProps {
    meetings: any[];
}

const Meetings: React.FC<IProps> = (props) => {
    return (
        <>
            {props.meetings && props.meetings.map((meeting, index) => <MeetingCard meeting={meeting} key={index} />)}
        </>
    );
};

export default Meetings;