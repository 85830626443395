import {
    IonContent, IonHeader, IonPage,
    IonTitle, IonToolbar, IonButtons, IonMenuButton,
    IonSearchbar, IonRefresher, IonRefresherContent
} from '@ionic/react';
import { chevronDownCircleOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import Formulas from '../components/Formulas';
import { doReload } from '../hooks/common';
import { getFormulas } from '../services/formula.service';
// debounce hook
import useDebounce from "../hooks/useDebounce";
import CustomSearchBox from '../components/CustomSearchBox/CustomSearchBox';

const FormulaList: React.FC = () => {
    const [searchText, setSearchText] = useState('');
    const debounceSearchText = useDebounce(searchText, 1000);

    const [formulas, setFormulas] = useState([] as any[]);
    useEffect(() => {
        let mounted = true;
        getFormulas(debounceSearchText)
            .then(items => {
                if (mounted) {
                    setFormulas(items);
                }
            });
        return () => { mounted = false };
    }, [debounceSearchText]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Formulas</IonTitle>
                </IonToolbar>
                <CustomSearchBox setSearchText={setSearchText} />
            </IonHeader>
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={doReload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>
                <Formulas formulas={formulas} />
            </IonContent>
        </IonPage>
    );
};

export default FormulaList;
