import { deleteData, postData } from "./common.service";

export async function uploadFile(primaryId: string, secondaryId: string, file: File) {
    const formdata = new FormData();
    formdata.append(file.name, file);
    return postData(`/images/${primaryId}/${secondaryId}/upload`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json'
        },
        body: formdata
    })
}

export async function deleteFile(imageUrl: string) {
    return deleteData(`/image?imageUrl=${imageUrl}`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json'
        }
    })
}
