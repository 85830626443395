import React, { useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { LeadStages, LeadStagesLabels } from "./CreateLeadOrder";
import "./OrderFilter.css";
import { getUsersWithRolesSupport } from "../services/user.service";

type Props = {
  filters: Record<string, any>;
  updateFilters: (filters: Record<string, any>) => void;
  onClose: () => void;
  setAssignId: (id: string) => void; // Corrected type for the setter function
  assignId:string
};

interface Agent {
  farmUserId: string;
  username: string;
  mobile: string;
}

const OrderFilter: React.FC<Props> = (props) => {
  const { filters, updateFilters, onClose, setAssignId ,assignId} = props;
  const [agentsList, setAgentsList] = useState<Agent[]>([]);

  const [searchTerm, setSearchTerm] = useState("");

  const [showAgentUserModal, setShowAgentUserModal] = useState(false);

  const [assignedDetails, setAssignedDetails] = useState({
    agentId: "",
    agentName: "",
  });

  



  const leadStage = useRef<LeadStages[]>([]);
  useEffect(() => {
    if (!!filters && filters.hasOwnProperty("stage")) {
      leadStage.current = filters.stage as LeadStages[];
    }
  }, [filters]);

  //getting the agents support
  useEffect(() => {
    let isMounted = true;

    getUsersWithRolesSupport().then((res) => {
      if (isMounted) {
        setAgentsList(res);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (assignId) {
      const filteredAgent = agentsList.find((item) => item.farmUserId === assignId);
      if (filteredAgent) {
        setAssignedDetails((prevDetails) => ({
          ...prevDetails,
          agentId: filteredAgent?.farmUserId,
          agentName: filteredAgent?.username,
        }));
      }
    }
  }, [agentsList, assignId]);
 

  const submitFilters = () => {
    const filters: Record<string, any> = {};
    if (!!leadStage.current) { 

      filters.stage = leadStage.current;
    }
    updateFilters(filters);
    onClose();
  };

  const handleStageChange = (newStageIds: LeadStages[]) => {
    leadStage.current = [...newStageIds];
  };

  const handleReset = () => {
    setAssignedDetails({
      agentId: "",
      agentName: "",
    });
    setAssignId("");
    setAssignId("")
    updateFilters({});
    onClose();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Filter</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Stages</IonLabel>
            <IonSelect
              id="stages"
              name="stages"
              compareWith={compareWith}
              multiple={true}
              value={leadStage.current}
              placeholder="Select Stages"
              onIonChange={(e) => handleStageChange(e.detail.value)}
            >
              {Object.entries(LeadStagesLabels).map(([key, value]) => {
                return (
                  <IonSelectOption key={key} value={key}>
                    {value}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
        </IonList>
        {showAgentUserModal && (
          <IonModal isOpen={showAgentUserModal}>
            <IonHeader>
              <IonToolbar>
                {showAgentUserModal ? (
                  <IonButtons slot="end">
                    <IonButton onClick={(e) => setShowAgentUserModal(false)}>
                      Close
                    </IonButton>
                  </IonButtons>
                ) : (
                  <IonButtons slot="start">
                    <IonMenuButton />
                  </IonButtons>
                )}
                <IonTitle>Assigned To</IonTitle>
              </IonToolbar>
              <IonToolbar>
                <IonSearchbar
                  placeholder="Search by name or mobile..."
                  value={searchTerm}
                  onIonChange={(e) => setSearchTerm(e.detail.value!)}
                ></IonSearchbar>
              </IonToolbar>
            </IonHeader>

            <IonContent>
              {agentsList
                ?.filter((agent: any) =>
                  searchTerm
                    ? `${agent.username} ${agent.mobile}`
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    : true
                )
                .map((agent: any) => (
                  <IonCard
                    key={agent.id}
                    onClick={() => {
                      setAssignedDetails({
                        agentId: agent?.farmUserId,
                        agentName: `${agent?.username}`,
                      });
                      setAssignId(agent?.farmUserId);

                      setShowAgentUserModal(false);
                    }}
                  >
                    <IonItem lines="none">
                      <IonLabel>
                        <h2>Name: {`${agent?.username} `}</h2>
                      </IonLabel>
                    </IonItem>
                    <IonItem lines="none">
                      <IonLabel>
                        <h2>
                          Mobile:{" "}
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              padding: 0,
                              color: "blue",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            // onClick={(e) => makeCall(e, agent?.mobile)}
                          >
                            {agent?.mobile || "No Mobile Number"}
                          </button>
                        </h2>
                      </IonLabel>
                    </IonItem>
                  </IonCard>
                ))}
            </IonContent>
          </IonModal>
        )}

        <IonItem>
          <IonLabel position="stacked">Assigned To</IonLabel>
          <IonInput
            readonly
            id="ownerId"
            name="ownerId"
            value={
              !assignedDetails.agentId && agentsList.length === 0
                ? "loading..."
                : assignedDetails.agentName
            }
            placeholder="Select Agent"
            onClick={() => setShowAgentUserModal(true)}
          ></IonInput>
        </IonItem>
      </IonContent>
      <IonToolbar>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                fill="outline"
                color="danger"
                onClick={handleReset}
              >
                Reset
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton expand="block" color="success" onClick={submitFilters}>
                Submit
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonPage>
  );
};

export default OrderFilter;

const compareWith = (o1: any, o2: any) => {
  if (Array.isArray(o2)) {
    return o2.indexOf(o1) !== -1;
  }
  return o1 === o2;
};
