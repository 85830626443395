import React from 'react';
import { IonCard, IonIcon, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonChip } from '@ionic/react';
import useNavigation from '../hooks/useNavigation';
import { makeCall } from '../hooks/common';
import { trashBinSharp } from 'ionicons/icons';
import { navigate, pulseOutline } from 'ionicons/icons';
import "./InteractionCard.css"

interface IProps {
    key: number;
    interaction: any;
    ignoreInteractionHandler?: Function
}

const InteractionCard: React.FC<IProps> = (props) => {
    const navigateTo = useNavigation();
    function showSensorData( plot: any) {
        navigateTo('/sensor-data', { plot: plot, sinceHours: 24 });
    }


    return (
        <>
            <IonCard onClick={() => navigateTo('/tabs/interaction', { interactionId: props.interaction.id })}>
                <IonItem>
                    <IonLabel>
                        {new Intl.DateTimeFormat('en-GB', {
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric',
                        }).format(new Date(props.interaction.lastInteractionDate))}
                    </IonLabel>
                    {props.interaction.autoGenerated && <IonIcon icon={trashBinSharp} onClick={e => { props.ignoreInteractionHandler && props.ignoreInteractionHandler(props.key, props.interaction.id, e) }}></IonIcon>}
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Farm User: {props.interaction.farmUser?.name || props.interaction.farmUserId}</h2>
                    </IonLabel>
                </IonItem>
                {props.interaction.farmUser?.mobile && <IonItem lines='none'>
                    <IonLabel>
                        <h2>Mobile: <a onClick={e => makeCall(e, props.interaction.farmUser.mobile)}>{props.interaction.farmUser.mobile}</a></h2>
                    </IonLabel>
                </IonItem>
                }
                {props.interaction.farmId && <IonItem lines='none'>
                    <IonLabel>
                        <h2>Farm Name: {props.interaction.farm?.name || props.interaction.farmId || ''}</h2>
                    </IonLabel>
                </IonItem>
                }
                {props.interaction.autoGenerated && props.interaction.reason && <IonItem lines='none'>
                    <IonLabel>
                        <h2>Reason: {props.interaction.reason}</h2>
                    </IonLabel>
                </IonItem>
                }
                {props.interaction.plotId && <IonItem lines='none'>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonChip outline={true} color="primary" >
                                    <IonLabel onClick={e => { e.stopPropagation(); navigateTo('/tabs/plot', { plotId: props.interaction.plotId }) }}>{props.interaction.plotId}</IonLabel>  
                                    <IonIcon className="iconContainer"  color='primary' icon={pulseOutline} onClick={e=>{e.stopPropagation();showSensorData(props.interaction)}}></IonIcon>  
                                </IonChip>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>
                }
                {/* {props.interaction.supportUserId && <IonItem lines='none'>
                    <IonLabel>
                        <h2>Fyllo User: {props.interaction.supportUserId}</h2>
                    </IonLabel>
                </IonItem>
                } */}
            </IonCard>
        </>
    );
};

export default InteractionCard;
