import React from 'react';
import InteractionCard from './InteractionCard';

interface IProps {
    interactions: any[];
    ignoreInteractionHandler?: Function
}

const Interactions: React.FC<IProps> = (props) => {
    return (
        <>
            {props.interactions && props.interactions.map((interaction, index) => <InteractionCard interaction={interaction} ignoreInteractionHandler={props.ignoreInteractionHandler} key={index} />)}
        </>
    );
};

export default Interactions;