import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonList, IonItem, IonLabel, IonSelect, IonSelectOption, IonGrid, IonRow, IonCol } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './PlotFilter.css';
import { getAllSalesActivities, getSalesActivityByActivityTypeId } from '../services/sales.service';


export interface Filters {
    type?: boolean;
}

interface IProps {
    filter: any;
    filterChangeHandler: Function;
    onDoneHandler: Function;
    onCloseHandler: Function;
    hideFilters?: Filters;
}

interface PlanOption {
    id: string;
    description: string;
}

const SalesActivityFilter: React.FC<IProps> = (props) => {
    const [acitivitesOptions, setActivitesOptions] = useState<PlanOption[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<string[]>(props?.filter?.type || []);
    const { hideFilters } = props;

    
    useEffect(() => {
        let mounted = true;
        getAllSalesActivities().then((res) => setActivitesOptions(res));
        mounted = false;

    }, []);
    
    const compareWith = (o1: any, o2: any) => {
        if (Array.isArray(o2)) {
            return o2.indexOf(o1) !== -1;
        }
        return o1 === o2;
    };

    async function onDone() {
        try{
            const activityIds = await getSalesActivityByActivityTypeId(selectedActivity);
            const uniqueActivityIds = new Set(activityIds.map((item: any) => item.id));
            props.filterChangeHandler(Object.assign({}, props?.filter, {type: selectedActivity}));
            props.onDoneHandler(
                Array.from(uniqueActivityIds)
            );
            onClose();
        } catch(err){
            console.log(err);
   
        }
        
    }

    function onReset() {
        props.filterChangeHandler({});
        props.onDoneHandler([]);
        onClose();
    }

    function onClose() {
        props.onCloseHandler(false);
    }

   

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Filter</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonList>
                    {
                        !hideFilters?.type &&
                        
                        <IonItem>
                            <IonLabel position="stacked">Activity Type</IonLabel>
                            <IonSelect id="activityType" name="activityType" compareWith={compareWith} multiple={true} value={selectedActivity} placeholder="Select Activity Type(s)" onIonChange={(e) => setSelectedActivity(e.detail.value)}>
                                {acitivitesOptions && acitivitesOptions.map((item: any) => {
                                    return <IonSelectOption key={item.id} value={item.id}>{item.description 
                                    }</IonSelectOption>;
                                })}
                            </IonSelect>
                        </IonItem>
                    }
                </IonList>
                <IonGrid>
                    <IonRow>
                        <IonCol><IonButton color="danger" className="shiftRight" onClick={onReset}>Reset</IonButton></IonCol>
                        <IonCol><IonButton onClick={onDone}>Done</IonButton></IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default SalesActivityFilter;
