import React from 'react';
import { IonCard, IonIcon, IonItem, IonLabel } from '@ionic/react';
import useNavigation from '../hooks/useNavigation';
import { makeCall } from '../hooks/common';
import { checkmark } from 'ionicons/icons';

interface IProps {
    user: any;
    selectedValue?: string;
    onClickHandler?: Function
}

const UserCard: React.FC<IProps> = (props) => {
    const navigateTo = useNavigation();

    return (
        <>
            <IonCard onClick={() => props.onClickHandler ? props.onClickHandler(props.user) : navigateTo('/tabs/user', { userId: props.user.farmUserId })}>
                <IonItem>
                    <IonLabel>{props.user.farmUserId}</IonLabel>
                    {props.selectedValue === props.user.farmUserId
                        && <IonIcon icon={checkmark} slot="end" />}
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Name: {props.user.name}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Mobile: <a onClick={e => makeCall(e, props.user.mobile)}>{props.user.mobile}</a></h2>
                    </IonLabel>
                </IonItem>
            </IonCard>
        </>
    );
};

export default UserCard;