import {
    IonContent, IonHeader, IonPage,
    IonTitle, IonToolbar, IonButtons, IonMenuButton,
    IonRefresher, IonRefresherContent, IonInfiniteScroll, IonInfiniteScrollContent
} from '@ionic/react';
import { chevronDownCircleOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import PendingPayments from '../components/PendingPayments';
import { doReload } from '../hooks/common';
import { getPendingPayments } from '../services/payment.service';

const PendingPaymentList: React.FC = () => {
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
    const [skip, setSkip] = useState<number>(20);

    const [payments, setPayments] = useState([] as any[]);
    useEffect(() => {
        let mounted = true;
        setSkip(20);
        setDisableInfiniteScroll(false);
        getPendingPayments()
            .then(items => {
                if (mounted) {
                    setPayments(items);
                }
            });
        return () => { mounted = false };
    }, []);

    async function fetchNext($event: CustomEvent<void>) {
        setSkip(skip + 20);
        getPendingPayments(skip)
            .then(items => {
                if (items?.length > 0) {
                    setPayments([...payments, ...items]);
                    setDisableInfiniteScroll(items.length < 20);
                } else {
                    setDisableInfiniteScroll(true);
                }
                ($event.target as HTMLIonInfiniteScrollElement).complete();
            });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Pending Payments</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={doReload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>
                <PendingPayments payments={payments} />
                <IonInfiniteScroll threshold="100px"
                    disabled={disableInfiniteScroll}
                    onIonInfinite={(e: CustomEvent<void>) => fetchNext(e)}>
                    <IonInfiniteScrollContent
                        loadingText="Loading more...">
                    </IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonContent>
        </IonPage>
    );
};

export default PendingPaymentList;
