import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { IonButton, IonDatetime, IonInput, IonItem, IonLabel, IonList, IonToast } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import useNavigation from '../hooks/useNavigation';
import { getReferral } from '../services/referral.service';


const ViewReferral = () => {
    const navigateTo = useNavigation();
    const location = useLocation();
    const [referral, setReferral] = useState();

    function setValues(data: any) {
        if (data) {
            formik.setValues({
                name: data.name,
                mobile: data.mobile,
                created_date: data.created_date,
                referredBy: data.referredBy,
                status: data.status
            });
        }
        setReferral(data);
    }

    useEffect(() => {
        let mounted = true;
        const id: any = (location.state as any)?.referralId ?? null;
        if (id) {
            getReferral(id)
                .then(user => {
                    if (mounted) {
                        setValues(user);
                    }
                });
        }
        return () => { mounted = false };
    }, []);

    const formik = useFormik({
        initialValues: {
            name: '',
            mobile: '',
            created_date: '',
            referredBy: '',
            status: ''
        },
        onSubmit: values => {
            navigateTo('/tabs/lead', {
                contactName: formik.values.name,
                contactPhoneNumber: formik.values.mobile,
            })
        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <IonList>

                    <IonItem>
                        <IonLabel position="stacked">Contact Name</IonLabel>
                        <IonInput id="name" name="name" value={formik.values.name} placeholder="Contact Name" onIonChange={formik.handleChange}></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Phone Number</IonLabel>
                        <IonInput id="mobile" name="mobile" value={formik.values.mobile} placeholder="Phone Number" onIonChange={formik.handleChange}></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Status</IonLabel>
                        <IonInput id="status" name="status" value={formik.values.status} placeholder="Status" onIonChange={formik.handleChange}></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Referred On</IonLabel>
                        <IonDatetime id="created_date" name="created_date" displayFormat="DD/MM/YYYY" placeholder="Referred On" value={formik.values.created_date} onIonChange={formik.handleChange}></IonDatetime>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Referred By</IonLabel>
                        <IonInput id="referredBy" name="referredBy" value={formik.values.referredBy} placeholder="Referred By" onIonChange={formik.handleChange}></IonInput>
                    </IonItem>

                </IonList>

                <IonButton expand="block" type="submit">Convert to a Lead</IonButton>
            </form>

        </>
    );
};

export default ViewReferral;