import { useEffect, useState } from 'react';
import { IonButton, IonInput, IonItem, IonLabel, IonIcon, IonModal, IonToast, IonGrid, IonRow, IonCol, IonThumbnail, IonTextarea, IonDatetime, IonList } from '@ionic/react';
import { getAllSalesActivities, addSalesActivity } from '../services/sales.service';
import { addCircle } from 'ionicons/icons';

import SelectItem from './SelectItem';
import { handleErrors } from '../services/common.service';
import { useLocation } from 'react-router';
import ImageViewer from './ImageViewer';
import { dataUrlToFile, takePicture } from '../hooks/common';
import { uploadFile } from '../services/image.service';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { userProfileState } from '../services/state.service';
import { addLead } from '../services/lead.service';
import UserList from '../pages/UserList';


interface PlanOption {
    id: string;
    description: string;
}


const CreateSalesActivity = () => {
 

    const location = useLocation();
    const history = useHistory();
    const [acitivitesOptions, setActivitesOptions] = useState<PlanOption[]>([]);
    const [showActivities, setShowActivities] = useState(false);
    const [attachments, setAttachments] = useState({
        images: [] as string[],
        thumbnails: [] as string[]
    });
    const [activityType, setActivityType] = useState<PlanOption>();
    const [region, setRegion] = useState({ region: '' });
    const [date, setDate] = useState({date: new Date().toISOString()});
    const [comment, setComment] = useState('');
    const [userid, setUserid] = useState('');
    const [quantity, setQuantity] = useState(1); 
    
    const [onSuccess, setOnSuccess] = useState('');
    const [onError, setOnError] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [meetingProps, setMeetingProps] = useState<{[key:string]: any}>(
        {
         personPresent: 0,
         devicesSold: 0, 
         hotLeads: 0,
         
        });
    const userProfile: any = useRecoilValue(userProfileState);
    const [leadProps, setLeadProps] = useState<{[key:string]: any}>(
        {
            account: 'lead',
            contactName: '', 
            contactPhoneNumber: '', 
            followUpDate: '',
        }
    );


    const meetingPropsDict:{[key:string]: string} = {
        personPresent: 'No. of persons present',
         devicesSold: 'No. of devices sold', 
         hotLeads: 'No. of Hot leads',
    }

    const [selectedImage, setSelectedImage] = useState('');
    const [servicedBy, setServicedBy] = useState(userProfile.username);
    const [showImageModal, setShowImageModal] = useState(false);
    const [showSelectUserModal, setShowSelectUserModal] = useState(false);
    const [servicer, setServicer] = useState(false);
    
    useEffect(() => {
        let mounted = true;
        getAllSalesActivities().then((res) => setActivitesOptions(res));
        mounted = false;
    }, [])

    useEffect(() => {
        setQuantity(1);
    }, [activityType]);
    
    function setUserId(item: any) {
        setUserid(item.farmUserId);
        setShowSelectUserModal(false);
    }

    function setServicedById(item: any) {
        setServicedBy(item.farmUserId);
        setShowSelectUserModal(false);
    }

    async function uploadImage() {
        try {
            const base64Image = await takePicture();
            if (base64Image && activityType?.description) {
                const imageFile = await dataUrlToFile(base64Image);
                const uploadResponse = await uploadFile('salesActivity', activityType?.description.replaceAll(" ", ""), imageFile);
                const responseBody = await uploadResponse.json();
                setAttachments({ images: [...attachments.images, responseBody.images[0]], thumbnails: [...attachments.thumbnails, responseBody.thumbnails[0]] });
            }
        } catch (err) {
            console.log(err);
            setOnError('Error occured while uploading image');
        }
    }

    function deleteImage(url: string) {
        const imageIndex = attachments.images.findIndex(item => item === url);
        if (imageIndex > -1) {
            attachments.images.splice(imageIndex, 1);
            attachments.thumbnails.splice(imageIndex, 1);
        }
        setAttachments({ images: [...attachments.images], thumbnails: [...attachments.thumbnails] });
    }

    const saveLead = async (values: any) => {
        try {
            values.servicedBy = userProfile.username || values.servicedBy;
            values.date = date.date;
            const response = await addLead(values).then(handleErrors);
            setOnSuccess('Lead details added'); 
        } catch (err: any) {
            console.log(err);
            setOnError(err);
        }
    };

    const submit = async () => {
        setDisabled(true);
        try {
            if (activityType && activityType?.id?.length > 0) {

                var salesActivities = [];
                for (let index = 0; index < quantity; index++) {
                    const activity = { location: region, attachments, monthlySalesPlanId: (location?.state as any)?.planId, userid: userid,comment: comment, date: new Date(date.date), salesActivityTypeId: activityType?.id, servicedBy: servicedBy, meetingProps:meetingProps, leadProps: leadProps }
                    salesActivities.push(activity);
                    if(activityType?.description === "Personal Meetings - New Customers"){
                        saveLead(leadProps);
                    }
                }

                const response = await addSalesActivity(salesActivities).then(handleErrors);
                setOnSuccess("Sales Activity Added");
                history.goBack();
            }
            else
                setOnError("Some error occurred");
                
        }
        catch (err: any) {
            setOnError(err);
            console.log(err);
        }

        setDisabled(false);
    }

    const [lessPresent, setLessPresent] = useState(false);
    const [morePresent, setMorePresent] = useState(false);


    useEffect(() => {
        if(activityType?.description === undefined) setDisabled(true);
        
        else if(activityType?.description === "Personal Meetings - New Customers"){
            if(leadProps.contactName === '') setDisabled(true);
            else if(leadProps.contactPhoneNumber === '') setDisabled(true);
            else if(leadProps.followUpDate === '') setDisabled(true);
            else setDisabled(false);
        }
        else if(activityType?.description==="Small Group Meetings (Less than 25)" || activityType?.description==="Large Group Meetings (More than 25)"){
            if(meetingProps['personPresent'] >25 && activityType?.description==="Small Group Meetings (Less than 25)"){
                setMorePresent(true);
                setDisabled(true);
            }   
            else if(meetingProps['personPresent'] <=25 && activityType?.description==="Large Group Meetings (More than 25)"){
                setLessPresent(true);
                setDisabled(true);
            }   
            else{
                setLessPresent(false);
                setMorePresent(false);
                setDisabled(false);
            }
        }

        else if(activityType?.description==="Personal Meetings - Existing Customers"){
            if(userid==="") setDisabled(true);
            else setDisabled(false);
        }
        else setDisabled(false);
    }, [userid, meetingProps, leadProps, activityType]);


    return (
        <>

            <IonItem>
                <IonLabel position="stacked">Activity Type</IonLabel>
                <IonInput clearInput readonly id="activityType" name="activityType" value={activityType?.description} placeholder="Select Activity Type" onClick={e => setShowActivities(true)}></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position='stacked'> Date </IonLabel>
                <IonDatetime name="activityDate" value= {date.date} onIonChange={e => setDate({ date: e.detail.value?? ""})}></IonDatetime>
            </IonItem>
            {activityType?.description==="Personal Meetings - Existing Customers" && 
            <>
            <IonItem>
                <IonLabel position="stacked">User Id</IonLabel>
                <IonInput readonly id="userid" name="userid" value={userid} placeholder="Select User Id" onClick={e => {setShowSelectUserModal(true); setServicer(false);}}></IonInput>
            </IonItem>
            {userid === '' && <div className="errorMsg">Required</div>}
            </>
            }
            

            {activityType?.description==="Personal Meetings - New Customers" && 
               <IonList>

                    <IonItem>
                        <IonLabel position="stacked">Contact Name</IonLabel>
                        <IonInput id="contactName" name="contactName" value={leadProps.contactName} placeholder="Contact Name" onIonChange={e=> setLeadProps({...leadProps, contactName: e.detail.value!})}></IonInput>
                    </IonItem>
                    {leadProps.contactName === '' && <div className="errorMsg">Required</div>}

                    <IonItem>
                        <IonLabel position="stacked">Phone Number</IonLabel>
                        <IonInput id="contactPhoneNumber" name="contactPhoneNumber" value={leadProps.contactPhoneNumber} placeholder="Contact Phone Number" onIonChange={e=> setLeadProps({...leadProps, contactPhoneNumber: e.detail.value!})}></IonInput>
                    </IonItem>
                    {leadProps.contactPhoneNumber === '' && <div className="errorMsg">Required</div>}

                    <IonItem>
                        <IonLabel position="stacked">Follow-up Date</IonLabel>
                        <IonDatetime id="followUpDate" name="followUpDate" displayFormat="DD/MM/YYYY" placeholder="Follow-up Date" value={leadProps.followUpDate} onIonChange={e=> setLeadProps({...leadProps, followUpDate: e.detail.value!})}></IonDatetime>
                    </IonItem>
                    {leadProps.followUpDate === '' && <div className="errorMsg">Required</div>}

                    
                    
               </IonList>
            }

            {activityType?.description==="Installations" && <IonItem>
                <IonLabel position="stacked">Quantity</IonLabel>
                <IonInput clearInput type="number"id="quantity" name="quantity" value={quantity ?? ''} placeholder="Select Quantity" onIonChange={e => setQuantity(parseInt(e.detail.value??''))}></IonInput>
            </IonItem>}

            <IonItem>
                <IonLabel position="stacked">Location</IonLabel>
                <IonInput clearInput id="region" name="region" value={region?.region ?? ''} placeholder="Location" onIonChange={e => setRegion({ region: e.detail.value ?? '' })}></IonInput>
            </IonItem>

            {(activityType?.description==="Small Group Meetings (Less than 25)" || activityType?.description ==="Large Group Meetings (More than 25)") && 
                Object.keys(meetingProps).map((key, index) => {
                    return(
                        <>
                        <IonItem key={index}>
                            <IonLabel position="stacked">{meetingPropsDict[key]}</IonLabel>
                            <IonInput clearInput type='number' id={key} name={key} value={meetingProps[key]??0} placeholder={key} onIonChange={e => setMeetingProps({...meetingProps, [key]: parseInt(e.detail.value!)}) }></IonInput>
                        </IonItem>
                        {(lessPresent && key==='personPresent') && <div className="errorMsg">Persons less than 25</div>}
                        {(morePresent && key==='personPresent') && <div className="errorMsg">Persons more than 25</div>}
                        </>
                    )
                })
                }

            <IonItem>
                <IonLabel position="stacked">Serviced By</IonLabel>
                <IonInput readonly id="servicedBy" name="servicedBy" value={servicedBy} placeholder="Select Servicer" onClick={e => {setShowSelectUserModal(true); setServicer(true);}}></IonInput>
            </IonItem>

            <IonItem>
                <IonLabel position="stacked">Comment</IonLabel>
                <IonTextarea id="comment" name="comment" value={comment ?? ''} placeholder="Comment" onIonChange={e => setComment( e.detail.value ?? '' )}></IonTextarea>
            </IonItem>

            
       
            <>
                <IonItem lines='none'>
                    <IonLabel>Images</IonLabel>
                    <IonIcon icon={addCircle} slot="end" onClick={e => uploadImage()} />
                </IonItem>
                <IonItem lines='none'>
                    <IonGrid>
                        <IonRow>
                            {attachments?.thumbnails?.map((item: any, index: number) =>
                                <IonCol key={index}>
                                    <IonThumbnail>
                                        <img src={item} alt='activityImage' onClick={e => {
                                            setSelectedImage(attachments?.images[index]);
                                            setShowImageModal(true);
                                        }} />
                                    </IonThumbnail>
                                </IonCol>
                            )}
                        </IonRow>
                    </IonGrid>
                </IonItem>
            </>

            <IonModal isOpen={showImageModal} cssClass='my-custom-class'>
                <ImageViewer imageUrl={selectedImage} onDeleteHandler={deleteImage} onCloseHandler={setShowImageModal} />
            </IonModal>


            <IonModal isOpen={showActivities} >
                <SelectItem
                    label='Select Activity Type'
                    selectedValue={activityType?.id ?? ''}
                    labelKey='description'
                    valueKey='id'
                    setItem={(item: any) => {
                        setActivityType(item)
                        setShowActivities(false)
                    }}
                    onClose={() => setShowActivities(false)}
                    items={acitivitesOptions}
                />
            </IonModal>

            {showSelectUserModal && <IonModal isOpen={showSelectUserModal} >
            <UserList
                    selectedValue={servicer? servicedBy: userid}
                    onClickHandler={servicer? setServicedById: setUserId}
                    onClose={setShowSelectUserModal}
                />
            </IonModal>}

            <IonButton disabled={disabled} expand="block" type="submit" onClick={submit}>Save Activity</IonButton>

            <IonToast
                isOpen={!!onSuccess}
                onDidDismiss={() => setOnSuccess('')}
                message={onSuccess}
                duration={2000}
                color="success"
            />

            <IonToast
                isOpen={!!onError}
                onDidDismiss={() => setOnError('')}
                message={onError}
                duration={2000}
                color="danger"
            />
        </>
    );
};

export default CreateSalesActivity;