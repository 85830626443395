import React, { useEffect, useState } from "react";
import { getTicketsByState, getUserListByRole } from "../services/zammad.service";
import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonRefresher, IonRefresherContent, IonList, IonItem, IonLabel, IonText, IonSpinner, IonRow, IonBackButton } from "@ionic/react";
import { chevronDownCircleOutline } from "ionicons/icons";
import { doReload } from "../hooks/common";

const TicketsOverview: React.FC = () => {
  const [fetchingAgents, setFetchingAgents] = useState<boolean>(false);
  const [fetchingTickets, setFetchingTickets] = useState<boolean>(false);
  const [totalTickets, setTotalTickets] = useState<number>(0);

  const [ownerTicketCount, setOwnerTicketCount] = useState<Record<number, number>>();
  const [ownerDetail, setOwnerDetail] = useState<Record<number, any>>();

  useEffect(() => {
    fetchAgents();
    fetchTickets();
  }, [])

  // api calls

  const fetchAgents = async () => {
    setFetchingAgents(true);
    getUserListByRole(2, 1, 1000)
      .then((response: any[]) => {
        const ownerDetail = extractOwnerDetail(response);
        setOwnerDetail(ownerDetail);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setFetchingAgents(false);
      })
  }

  const fetchTickets = () => {
    setFetchingTickets(true);
    getTicketsByState(2, 1, 1000)
      .then(response => {
        const { Ticket } = response?.assets;
        const groupedTickets = groupTicketByOwner(Ticket);
        setOwnerTicketCount(groupedTickets);
        setTotalTickets(response.tickets_count)
      })
      .catch(error => {
        console.log('error', error);
      })
      .finally(() => {
        setFetchingTickets(false);
      })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Ticket Overview</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doReload}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing...">
          </IonRefresherContent>
        </IonRefresher>
        <UserTicketInfo
          ownerDetail={ownerDetail}
          ownerTicketCount={ownerTicketCount}
          fetchingTickets={fetchingTickets}
          fetchingAgents={fetchingAgents}
          totalTickets={totalTickets}
        />
      </IonContent>
    </IonPage>
  )
}

export default TicketsOverview;

type UserTicketInfoProps = {
  ownerDetail?: Record<number, any>,
  ownerTicketCount?: Record<number, number>,
  fetchingTickets: boolean;
  fetchingAgents: boolean;
  totalTickets: number;
}
const UserTicketInfo: React.FC<UserTicketInfoProps> = (props) => {
  const { ownerDetail, ownerTicketCount, fetchingTickets, fetchingAgents, totalTickets } = props;

  if (fetchingAgents || fetchingTickets) {
    return (
      <IonRow>
        <IonSpinner style={{ margin: '10px auto' }} />
      </IonRow>
    );
  }

  const sortedEntries = ownerTicketCount
    ? Object.entries(ownerTicketCount).sort(([ownerIdA, countA], [ownerIdB, countB]) => countB - countA)
    : [];

  return (
    <IonList>
      <IonItem>
        <IonLabel style={{ fontWeight: '600' }}>Open Tickets</IonLabel>
        <IonText style={{ fontWeight: '600' }}>{totalTickets}</IonText>
      </IonItem>
      {sortedEntries.map(([ownerId, ticketCount]) => {
        const details = ownerDetail?.[Number(ownerId)];
        const ownerName = details ? details.firstname + " " + details.lastname : "Unknown";

        return (
          <IonItem key={ownerId}>
            <IonLabel>{ownerName}</IonLabel>
            <IonText>{ticketCount}</IonText>
          </IonItem>
        );
      })}
    </IonList>
  );
};



/**
 * Helper functions
*/
function groupTicketByOwner(Ticket: Record<number, any>) {
  const groupedTickets: Record<number, number> = {};

  Object.entries(Ticket).forEach(([ticketId, ticket]) => {
    const ownerId = ticket?.owner_id;
    if (!groupedTickets[ownerId]) {
      groupedTickets[ownerId] = 1;
    } else {
      groupedTickets[ownerId]++;
    }
  })

  return groupedTickets;
}

function extractOwnerDetail(owners: any[]) {
  const ownerDetails: Record<number, any> = {};
  owners.forEach(owner => {
    const { id, firstname, lastname, mobile } = owner;
    ownerDetails[id] = {
      firstname,
      lastname,
      mobile
    }
  })

  return ownerDetails;
}