import React from 'react';
import TicketCard from './TicketCard';

interface IProps {
    tickets: any[];
}

const Tickets: React.FC<IProps> = (props) => {
    return (
        <>
            {props.tickets && props.tickets.map((ticket, index) => <TicketCard ticket={ticket} key={index} />)}
        </>
    );
};

export default Tickets;