import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonList, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import './OutlierPlotDetails.css';
import TimeSeriesChart from "./TimeSeriesChart";

interface IProps {
    plot?: any;
    sensor?: string;
}

const OutlierPlotDetails: React.FC<IProps> = (props) => {
    const location = useLocation();
    const [plot, setPlot] = useState<any>();
    const [sensor, setSensor] = useState<any>();

    useEffect(() => {
        let mounted = true;
        setPlot((location.state as any)?.plot ?? null);
        setSensor((location.state as any)?.sensor ?? null);
        return () => { mounted = false };
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Outlier Values</IonTitle>
                </IonToolbar>
            </IonHeader>
            {plot && sensor &&
                <IonContent>
                    <IonList>
                        <IonItem>
                            <IonLabel position="stacked">Farm Id</IonLabel>
                            <IonInput readonly id="farmId" name="farmId" value={plot.farmId} ></IonInput>
                        </IonItem>

                        <IonItem>
                            <IonLabel position="stacked">Plot Id</IonLabel>
                            <IonInput readonly id="plotId" name="plotId" value={plot.plotId} ></IonInput>
                        </IonItem>

                        <IonItem>
                            <IonLabel position="stacked">Plot Name</IonLabel>
                            <IonInput readonly id="name" name="name" value={plot.name} ></IonInput>
                        </IonItem>

                        <IonItem>
                            <IonLabel position="stacked">Sensor</IonLabel>
                            <IonInput readonly id="sensor" name="sensor" value={sensor} ></IonInput>
                        </IonItem>

                        <IonCard>
                            <TimeSeriesChart format={plot.sensors[sensor]?.format} chartData={plot.sensors[sensor]?.values.map((item: any, index: number) => {
                                return {
                                    time: plot.sensors[sensor!]?.timestamp[index],
                                    value: plot.sensors[sensor!]?.values[index].toFixed(2)
                                }
                            })}></TimeSeriesChart>
                        </IonCard>
                    </IonList>
                </IonContent>
            }
        </IonPage>
    );
};

export default OutlierPlotDetails;
