import { LeadStages } from "../components/CreateLeadOrder";
import { getData, postData } from "./common.service";

export const API_RESPONSE_LIMIT = 10;

export async function getOrders(text: string = '', userId='', skip = 0, filter: Record<string, any> = {}) {
    const qs: any = {
        where: {
            and: [
                {
                    assignedTo: userId
                },
                {
                    or:
                        [
                            { detail: { like: `.*${text}.*`, options: 'i' } },
                            // { stage: { like: `.*${text}.*`, options: 'i' } },
                        ]
                }
            ]
        }, 
        include: [{
            relation: 'servicer',
            scope: {
                fields: ['farmUserId', 'name'],
            }
        },{
            relation: 'crop',
            scope: {
                fields: ['id', 'name'],
            }
        }],
        order: 'created_date DESC',
        limit: API_RESPONSE_LIMIT,
        skip: skip
    };

    if(!!filter && Object.keys(filter).length > 0) {
        Object.entries(filter).forEach(([key, value]) => {
            if(Array.isArray(value)) {
                qs.where.and.push({ [key]: { in: value } });
            } else {
                qs.where.and.push({ [key]: value });
            }
        })
    } else {
        // default filters, only show active orders
        qs.where.and.push({ stage: { in: [
            LeadStages.Installing, 
            LeadStages.NewLead, 
            LeadStages.FieldVisitScheduled, 
            LeadStages.ReferenceCheck, 
            LeadStages.Negotiation,
            LeadStages.Later
        ]}})
    }

    return getData('/orders?filter=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}


export async function getOrder(orderId: string) {
    const qs = {
        include: [{
            relation: 'servicer',
            scope: {
                fields: ['farmUserId', 'name'],
            }
        },{
            relation: 'crop',
            scope: {
                fields: ['id', 'name'],
            }
        }]
    };
    return getData(`/orders/${orderId}?filter=` + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function addOrder(data: any) {
    return postData('/orders', {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    }).then(res => res.json());
}

export async function updateOrder(data: any) {
    return postData(`/orders/${data.id}`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        usePutMethod: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    });
}

export async function filterOrders(where: Record<string, string>, skip: number = 0) {
    const qs = {
        where,
        include: [{
            relation: 'servicer',
            scope: {
                fields: ['farmUserId', 'name'],
            }
        },{
            relation: 'crop',
            scope: {
                fields: ['id', 'name'],
            }
        }],
        order: 'created_date DESC',
        limit: API_RESPONSE_LIMIT,
        skip 
    }

    return getData('/orders?filter=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => {data.json()})
}