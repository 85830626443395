import React from 'react';
import { IonCard, IonChip, IonCol, IonGrid, IonItem, IonLabel, IonRow,IonIcon } from '@ionic/react';
import useNavigation from '../hooks/useNavigation';
import { makeCall } from '../hooks/common';
import { navigate, pulseOutline, bugOutline } from 'ionicons/icons';
import "./FarmCard.css"

interface IProps {
    farm: any;
}

const FarmCard: React.FC<IProps> = (props) => {
    const navigateTo = useNavigation();
    function showSensorData( plot: any) {
        navigateTo('/sensor-data', { plot: plot, sinceHours: 24 });
    }

    function showObservationData(plot: any){
        const plotId = plot.plotId || plot._id;
        navigateTo('/add-disease-pest-observation', { plotId });
    }

    return (
        <>
            <IonCard onClick={() => navigateTo('/tabs/farm', { farmId: props.farm.farmId })}>
                <IonItem>
                    <IonLabel>{props.farm.farmId}</IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Farm Name: {props.farm.name}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>User Id: {props.farm.farmUserId}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>User Name: {props.farm.farmUser?.name}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Mobile: <a onClick={e => makeCall(e, props.farm.farmUser?.mobile)}>{props.farm.farmUser?.mobile}</a></h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Place: {props.farm.place}{props.farm.taluka ? ', ' + props.farm.taluka : ''}{props.farm.district ? ', ' + props.farm.district : ''}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonGrid>
                        <IonRow>
                            {props.farm.plots.length > 0 && props.farm.plots.map((item: any, index: number) =>
                                <IonCol key={index}>
                                    <IonChip outline={true} color="primary" style={{maxWidth: '90%'}}>
                                        <>
                                        <IonLabel onClick={e => { e.stopPropagation(); navigateTo('/tabs/plot', { plotId: item.plotId }) }}>{item.plotId}</IonLabel>
                                        <IonIcon className="iconContainer" icon={bugOutline} onClick={e=>{e.stopPropagation();showObservationData(item)}}></IonIcon>
                                        <IonIcon  className="iconContainer"  icon={pulseOutline} onClick={e=>{e.stopPropagation();showSensorData(item)}}></IonIcon>
                                        </>
                                    </IonChip>
                                </IonCol>
                            )}
                            {props.farm.plots.length === 0 &&
                                <IonCol><IonLabel class="secondary-text">No Plots Found</IonLabel></IonCol>}
                        </IonRow>
                    </IonGrid>
                </IonItem>
            </IonCard>
        </>
    );
};

export default FarmCard;
