import React from 'react';
import VoucherCard from './VoucherCard';

interface IProps {
    vouchers: any[];
    removeVoucherHandler?: Function
}

const Vouchers: React.FC<IProps> = (props) => {

    return (
        <>
            {props.vouchers && props.vouchers.map((voucher, index) => <VoucherCard voucher={voucher} key={index} removeVoucherHandler={props.removeVoucherHandler}/>)}
        </>
    );
};

export default Vouchers;