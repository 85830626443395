import { IonIcon, IonInput, IonItem, IonLabel, IonModal, IonNote, IonSearchbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import Plots from '../components/Plots';
import { GeolocationService } from '../services/geolocation.service';
import { filterPlots, getInactivePlots } from '../services/plot.service';
import LoadingSkeleton from './LoadingSkeleton';
import { funnelOutline } from 'ionicons/icons';
import CustomSearchBox from './CustomSearchBox/CustomSearchBox';
import PlotFilter from './PlotFilter';

// debounce hook
import useDebounce from "../hooks/useDebounce";
import { useRecoilValue } from 'recoil';
import { userProfileState } from '../services/state.service';

const InactivePlots: React.FC = () => {
    const userProfile = useRecoilValue(userProfileState);
    const [searchText, setSearchText] = useState('');
    const [sinceHours, setSinceHours] = useState(6);
    const [loading, setLoading] = useState(false);

    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filterData, setFilterData] = useState<any>();
    const [filteredPlotIds, setFilteredPlotIds] = useState<string[]>([]);
    const [plots, setPlots] = useState([] as any);

    const debounceSearchText = useDebounce(searchText, 1000);

    useEffect(() => {
        let mounted = true;
        setLoading(true);
        if (!sinceHours) {
            setPlots([]);
            setLoading(false);
            return;
        }
        if (Number(sinceHours) > 0) {
            const geoService = new GeolocationService();
            geoService.getCurrentPosition()
                .then(geolocation => {
                    setInactivePlots(mounted, geolocation);
                }).catch(err => {
                    setInactivePlots(mounted, null);
                });
        }
        return () => { mounted = false };
    }, [sinceHours, filteredPlotIds]);

    function setInactivePlots(mounted: boolean, geolocation: any) {
        getInactivePlots(sinceHours, userProfile, geolocation, filteredPlotIds)
            .then(items => {
                if (items.error) {
                    setPlots([]);
                    setLoading(false);
                }
                else if (mounted) {
                    setPlots(items);
                    setLoading(false);
                }
            });
    }

    return (
        <>
            <IonItem>
                <IonLabel position="stacked">Since Hours (1-72 hrs)</IonLabel>
                <IonInput id="sinceHours" name="sinceHours" type="number" inputmode="numeric" value={sinceHours} placeholder="Since hours (Default is 6 hrs)" debounce={1000} onIonChange={(e: any) => setSinceHours(e.target.value)}></IonInput>
            </IonItem>

            <div className="searchBarContainer">
                <div className="sbContainer">
                    <CustomSearchBox setSearchText={setSearchText} />
                </div>
                <div className="iconContainer">
                    <IonIcon
                        icon={funnelOutline}
                        slot="end"
                        onClick={(e) => setShowFilterModal(true)}
                    ></IonIcon>
                </div>
            </div>

            {!loading && <IonItem lines="none" >
                <IonNote slot="end">{filterPlots(plots, debounceSearchText)?.length} plots found</IonNote>
            </IonItem>
            }

            {loading && <LoadingSkeleton />}
            {!loading && (plots?.length > 0 ? <Plots plots={filterPlots(plots, debounceSearchText)} /> : <IonItem><IonLabel>No plots found</IonLabel></IonItem>)}

            <IonModal isOpen={showFilterModal} cssClass="my-custom-class">
                <PlotFilter
                    filter={filterData}
                    filterChangeHandler={setFilterData}
                    onDoneHandler={setFilteredPlotIds}
                    onCloseHandler={setShowFilterModal}
                    hideFilters={{
                        crop: true,
                        tag: true
                    }}
                ></PlotFilter>
            </IonModal>
        </>

    );
};

export default InactivePlots;
