import {
    IonContent, IonHeader, IonPage,
    IonTitle, IonToolbar, IonButtons, IonMenuButton,
    IonSearchbar, IonFab, IonFabButton, IonIcon,
    IonRefresher, IonRefresherContent,
} from '@ionic/react';
import { add, chevronDownCircleOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { doReload } from '../hooks/common';
import useNavigation from '../hooks/useNavigation';
import { getScreenData } from '../services/dynamicForm.service';
import FarmerProfiles from '../components/FarmerProfiles';
import { useLocation } from 'react-router';

const FarmerProfileList: React.FC = () => {
    const navigateTo = useNavigation();
    const location = useLocation();

    const [profiles, setProfiles] = useState([] as any[]);
    useEffect(() => {
        let mounted = true;
        getScreenData("farmer-profiles")
            .then(items => {
                if (mounted) {
                    setProfiles(items);
                }
            });
        return () => { mounted = false };
    }, [location]);  

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Farmer Profiles</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={doReload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>
                <FarmerProfiles profiles={profiles} />
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton>
                        <IonIcon icon={add} onClick={() => navigateTo('/tabs/farmer-profile', { profileId: null })} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
        </IonPage>
    );
};

export default FarmerProfileList;
