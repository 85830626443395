import React from 'react';
import { IonCard, IonIcon, IonItem, IonLabel } from '@ionic/react';
import useNavigation from '../hooks/useNavigation';
import { checkmarkDone } from 'ionicons/icons';

interface IProps {
    maintenance: any;
}

const MaintenanceCard: React.FC<IProps> = (props) => {
    const navigateTo = useNavigation();

    return (
        <>
            <IonCard onClick={() => navigateTo('/tabs/maintenance', { maintenanceId: props.maintenance.id })}>
                <IonItem>
                    <IonLabel>
                        {new Intl.DateTimeFormat('en-GB', {
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric',
                        }).format(new Date(props.maintenance.date))}
                    </IonLabel>
                    {props.maintenance.done && <IonIcon icon={checkmarkDone} ></IonIcon>}
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Plot: {props.maintenance.plotId}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Farm User: {props.maintenance.farmUserId}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Serviced By: {props.maintenance.servicedBy}</h2>
                    </IonLabel>
                </IonItem>
            </IonCard>
        </>
    );
};

export default MaintenanceCard;
