import {
    IonContent, IonHeader, IonPage,
    IonTitle, IonToolbar, IonButtons, IonMenuButton,
    IonSegment,
    IonSegmentButton,
    IonLabel,

} from '@ionic/react';

import {useState } from 'react';


import SalesPlanCardList from '../components/SalesPlanCardList';
import SalesActivityCardList from '../components/SalesActivityCardList';

enum ListType {
    plan = 'plan',
    activity = 'activity'
};
const SalesPlanList: React.FC = () => {
    const [listType, setListType] = useState(ListType.plan);
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Sales & Marketing Plans</IonTitle>
                </IonToolbar>
                
            </IonHeader>
            <IonContent>
                <IonSegment value={listType} onIonChange={e => setListType(e.detail.value as ListType)}>
                    <IonSegmentButton value={ListType.plan}>
                        <IonLabel>Plan</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value={ListType.activity}>
                        <IonLabel>Activity</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
                {listType === ListType.plan && <SalesPlanCardList />}
                {listType === ListType.activity &&  <SalesActivityCardList/>}
            </IonContent>
        </IonPage>
    );
};

export default SalesPlanList;
