import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IonAlert, IonButton, IonDatetime, IonInput, IonItem, IonLabel, IonList, IonSelect, IonSelectOption, IonToast, IonModal, IonTextarea } from '@ionic/react';
import { handleErrors } from '../services/common.service';
import { useLocation } from 'react-router-dom';
import useNavigation from '../hooks/useNavigation';
import { addLead, getLead, getLeadConfig, updateLead } from '../services/lead.service';
import { useRecoilValue } from 'recoil';
import { userProfileState } from '../services/state.service';
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import {E164Number } from 'libphonenumber-js';
import UserList from '../pages/UserList';


const CreateLead = () => {
    const navigateTo = useNavigation();
    const location = useLocation();
    const [lead, setLead] = useState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [onSuccess, setOnSuccess] = useState('');
    const [onError, setOnError] = useState('');
    const userProfile: any = useRecoilValue(userProfileState);
    const [showSelectUserModal, setShowSelectUserModal] = useState(false);
    
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const [followUpDate, setFollowUpDate] = useState<Date>(tomorrow);

    const compareWith = (o1: any, o2: any) => {
        if (Array.isArray(o2)) {
            return o2.indexOf(o1) !== -1;
        }
        return o1 === o2;
    };

    function setValues(data: any) {
        if (data) {
            formik.setValues({
                edit: true,
                date: data.date,
                type: data.type,
                source: data.source,
                contactName: data.contactName,
                contactPhoneNumber: data.contactPhoneNumber,
                location: data.location,
                response: data.response,
                followUpDate: data.followUpDate,
                status: data.status,
                account: data.account,
                details: data.details,
                servicedBy: data.servicer?.farmUserId || data.servicedBy,
                comment: data.comment,
            });
        }
        setLead(data);
    }

    useEffect(() => {
        let mounted = true;
        const id: any = (location.state as any)?.leadId ?? null;
        if (id) {
            getLead(id)
                .then(user => {
                    if (mounted) {
                        setValues(user);
                    }
                });
        } else {
            formik.setFieldValue('contactName', (location.state as any)?.contactName ?? '');
            formik.setFieldValue('contactPhoneNumber', (location.state as any)?.contactPhoneNumber ?? '');
            formik.setFieldValue('location', (location.state as any)?.location ?? '');
            formik.setFieldValue('servicedBy', (location.state as any)?.location ?? userProfile.farmUserId);
            formik.setFieldValue('comment', (location.state as any)?.comment ?? '')
            formik.setFieldValue('status', (location.state as any)?.status ?? 'open')
            formik.setFieldValue('followUpDate', (location.state as any)?.followUpDate ?? followUpDate.toISOString())
        }
        return () => { mounted = false };
    }, [location]);

    const formik = useFormik({
        initialValues: {
            edit: false,
            date: new Date().toISOString(),
            type: '',
            source: '',
            contactName: '',
            contactPhoneNumber: '',
            location: '',
            response: '',
            followUpDate: '',
            status: 'Open',
            account: 'lead',
            details: '',
            servicedBy: '',
            comment: ''
        },
        validationSchema: Yup.object({
            // date: Yup.string().trim().required('Required'),
            // type: Yup.string().trim().required('Required'),
            // source: Yup.string().trim().required('Required'),
            // contactName: Yup.string().trim().required('Required'),
            // contactPhoneNumber: Yup.string().trim().required('Required'),
            // location: Yup.string().trim().required('Required'),
            // response: Yup.string().trim().required('Required'),
            // status: Yup.string().trim().required('Required'),
            servicedBy: Yup.string().trim().required('Required'),
        }),
        onSubmit: values => {
            setShowConfirmation(true);
        },
    });

    const [leadTypes, setLeadTypes] = useState([] as any[]);
    const [sources, setSources] = useState([] as any[]);
    const [responseTypes, setResponseTypes] = useState([] as any[]);
    const [statuses, setStatuses] = useState([] as any[]);

    useEffect(() => {
        let mounted = true;
        getLeadConfig()
            .then(response => {
                if (mounted) {
                    setLeadTypes(response.type);
                    setSources(response.source);
                    setResponseTypes(response.response);
                    setStatuses(response.status);
                }
            })
        return () => { mounted = false };
    }, [])


    function setServicedById(item: any) {
        formik.setFieldValue('servicedBy', item.farmUserId);
        setShowSelectUserModal(false);
    }

    const saveLead = async (values: any) => {
        try {
            delete values.edit;
            delete values.id;
            delete values.servicer;
            values.servicedBy =  values.servicedBy || userProfile.username; 
            const data = Object.fromEntries(Object.entries(values).filter(([_, v]) => !!v));
            if (!lead) {
                const response = await addLead(data).then(handleErrors);
                setOnSuccess('Lead details added');
            } else {
                delete (lead as any).servicer;
                const updatedObj = Object.assign({}, lead, data);
                const response = await updateLead(updatedObj).then(handleErrors);
                setOnSuccess('Lead details updated');
            }
        } catch (err: any) {
            setOnError(err);
        }
    };


    const onContactNumberChange = (value: any) => {
        if(value!== undefined && !isValidPhoneNumber(value)){
            formik.setErrors({contactPhoneNumber: "Enter valid phone number"});
        }
        else{
            formik.setFieldValue('contactPhoneNumber', value);
        }
    }



    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <IonList>

                    <IonItem>
                        <IonLabel position="stacked">Contact Name</IonLabel>
                        <IonInput id="contactName" name="contactName" value={formik.values.contactName} placeholder="Contact Name" onIonChange={formik.handleChange}></IonInput>
                    </IonItem>
                    {formik.touched.contactName && formik.errors.contactName ? (<div className="errorMsg">{formik.errors.contactName}</div>) : null}

                    <IonItem>
                        <IonLabel position="stacked">Phone Number</IonLabel>
                        <PhoneInput placeholder="Enter phone number" defaultCountry="IN" value={formik.values.contactPhoneNumber as E164Number} 
                                // style={{ width: '93%', padding: '20px', fontSize: 18, paddingTop: '15px', margin: '3px', marginBottom: 11, marginTop: 10 }} 
                                onChange={(value) => onContactNumberChange(value)}
                        />
                    </IonItem>
                    {formik.touched.contactPhoneNumber && formik.errors.contactPhoneNumber ? (<div className="errorMsg">{formik.errors.contactPhoneNumber}</div>) : null}

                    <IonItem>
                        <IonLabel position="stacked">Follow-up Date</IonLabel>
                        <IonDatetime id="followUpDate" name="followUpDate" displayFormat="DD/MM/YYYY" placeholder="Follow-up Date" value={formik.values.followUpDate} onIonChange={formik.handleChange}></IonDatetime>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Location</IonLabel>
                        <IonInput id="location" name="location" value={formik.values.location} placeholder="Location" onIonChange={formik.handleChange}></IonInput>
                    </IonItem>
                    {formik.touched.location && formik.errors.location ? (<div className="errorMsg">{formik.errors.location}</div>) : null}

                    <IonItem>
                        <IonLabel position="stacked">Comment</IonLabel>
                        <IonTextarea id="comment" name="comment" value={formik.values.comment} placeholder="Comment" onIonChange={formik.handleChange}></IonTextarea>
                    </IonItem>
                    {formik.touched.comment && formik.errors.comment ? (<div className="errorMsg">{formik.errors.comment}</div>) : null}


                    <IonItem>
                        <IonLabel position="stacked">Status</IonLabel>
                        <IonSelect id="status" name="status" value={formik.values.status} placeholder="Select Status" onIonChange={formik.handleChange}>
                            {statuses && statuses.map(item => {
                                return <IonSelectOption key={item.id} value={item.id}>{item.value}</IonSelectOption>;
                            })}
                        </IonSelect>
                    </IonItem>
                    {formik.touched.status && formik.errors.status ? (<div className="errorMsg">{formik.errors.status}</div>) : null}



                    {/*    <IonItem>
                        <IonLabel position="stacked">Date</IonLabel>
                        <IonDatetime id="date" name="date" displayFormat="DD/MM/YYYY" placeholder="Date" value={formik.values.date} onIonChange={formik.handleChange}></IonDatetime>
                    </IonItem>
                    {formik.touched.date && formik.errors.date ? (<div className="errorMsg">{formik.errors.date}</div>) : null}

                                      <IonItem>
                        <IonLabel position="stacked">Type</IonLabel>
                        <IonSelect id="type" name="type" compareWith={compareWith} value={formik.values.type} placeholder="Select Type" onIonChange={formik.handleChange}>
                            {leadTypes && leadTypes.map(item => {
                                return <IonSelectOption key={item.id} value={item.id}>{item.value}</IonSelectOption>;
                            })}
                        </IonSelect>
                    </IonItem>
                    {formik.touched.type && formik.errors.type ? (<div className="errorMsg">{formik.errors.type}</div>) : null}

                    <IonItem>
                        <IonLabel position="stacked">Source</IonLabel>
                        <IonSelect id="source" name="source" value={formik.values.source} compareWith={compareWith} placeholder="Select Source" onIonChange={formik.handleChange}>
                            {sources && sources.map(item => {
                                return <IonSelectOption key={item.id} value={item.id}>{item.value}</IonSelectOption>;
                            })}
                        </IonSelect>
                    </IonItem>
                    {formik.touched.source && formik.errors.source ? (<div className="errorMsg">{formik.errors.source}</div>) : null}


                    <IonItem>
                        <IonLabel position="stacked">Response</IonLabel>
                        <IonSelect id="response" name="response" value={formik.values.response} compareWith={compareWith} placeholder="Select Response" onIonChange={formik.handleChange}>
                            {responseTypes && responseTypes.map(item => {
                                return <IonSelectOption key={item.id} value={item.id}>{item.value}</IonSelectOption>;
                            })}
                        </IonSelect>
                    </IonItem>
                    {formik.touched.response && formik.errors.response ? (<div className="errorMsg">{formik.errors.response}</div>) : null}


                    <IonItem>
                        <IonLabel position="stacked">Details</IonLabel>
                        <IonTextarea id="details" name="details" value={formik.values.details} placeholder="Lead Details" onIonChange={formik.handleChange}></IonTextarea>
                    </IonItem>*/}

                    <IonItem>
                        <IonLabel position="stacked">Serviced By</IonLabel>
                        <IonInput readonly id="servicedBy" name="servicedBy" value={formik.values.servicedBy} placeholder="Select Servicer" onClick={e => {setShowSelectUserModal(true);}}></IonInput>
                    </IonItem>
                    {formik.touched.servicedBy && formik.errors.servicedBy ? (<div className="errorMsg">{formik.errors.servicedBy}</div>) : null}

                </IonList>

                <IonButton expand="block" type="submit">{(!lead ? 'Create' : 'Update') + ' Lead'}</IonButton>
            </form>
            <IonAlert
                isOpen={showConfirmation}
                onDidDismiss={() => setShowConfirmation(false)}
                header={'Confirm!'}
                message={'Please make sure that you have verified all the lead details. Save Lead?'}
                buttons={[
                    {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary'
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            saveLead(formik.values);
                        }
                    }
                ]}
            />

            {showSelectUserModal && <IonModal isOpen={showSelectUserModal} >
            <UserList
                    selectedValue={formik.values.servicedBy}
                    onClickHandler={setServicedById}
                    onClose={setShowSelectUserModal}
                />
            </IonModal>}

            <IonToast
                isOpen={!!onSuccess}
                onDidDismiss={() => setOnSuccess('')}
                message={onSuccess}
                duration={2000}
                color="success"
            />

            <IonToast
                isOpen={!!onError}
                onDidDismiss={() => setOnError('')}
                message={onError}
                duration={2000}
                color="danger"
            />

        </>
    );
};

export default CreateLead;