import { IonRefresher, IonRefresherContent, IonInfiniteScroll, IonInfiniteScrollContent, IonIcon, IonModal
} from '@ionic/react';
import { chevronDownCircleOutline, funnelOutline } from 'ionicons/icons';
import { doReload } from '../hooks/common';
import { useEffect, useState } from 'react';
import useDebounce from "../hooks/useDebounce";

import { useLocation } from 'react-router';
import {getSalesActivities} from '../services/sales.service';
import CustomSearchBox from './CustomSearchBox/CustomSearchBox';

import SalesActivities from './SalesActivities';
import SalesActivityFilter from './SalesActivityFilter';




const SalesActivityCardList:React.FC = () => {
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
    const [salesActivities, setSalesActivities] = useState([] as any[]);
    
    const [searchText, setSearchText] = useState('');
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filterData, setFilterData] = useState<any>();
    const [filteredActivityIds, setFilteredActivityIds] = useState<string[]>([]);
    const [skip, setSkip] = useState<number>(20);
    const location = useLocation();
    const debounceSearchText = useDebounce(searchText, 1000);

    useEffect(() => {
            setSkip(20);
            setDisableInfiniteScroll(false);
            fetchActivities(debounceSearchText,filteredActivityIds );
            
    }, [debounceSearchText, filteredActivityIds]);
    
    



    useEffect(() => {
        if ((location?.state as any)?.reload) {
            fetchActivities();
            (location?.state as any).reload = false;
        }
    })
   
    const fetchActivities = (text: string = '', activityIds: string[] = []) => {
        let mounted = true;
        getSalesActivities(text, activityIds)
            .then(items => {
                if (mounted) {
                    setSalesActivities(items);
                }
            });
        return () => { mounted = false };
    }

    
    

    async function fetchNext($event: CustomEvent<void>) {
        setSkip(skip + 20);
        getSalesActivities(debounceSearchText, filteredActivityIds)
            .then(items => {
                if (items?.length > 0) { 
                    setSalesActivities([...salesActivities,...items]);
                    setDisableInfiniteScroll(items.length < 20);
                } else {
                    setDisableInfiniteScroll(true);
                }
                ($event.target as HTMLIonInfiniteScrollElement).complete();
            });
      
    }


    return (
        <>
        <div className="searchBarContainer">
            <div className="sbContainer">
                <CustomSearchBox setSearchText={setSearchText} />
            </div>
            <div className="iconContainer">
                <IonIcon
                    icon={funnelOutline}
                    slot="end"
                    onClick={(e) => setShowFilterModal(true)}
                ></IonIcon>
            </div>
        </div>
        <IonRefresher slot="fixed" onIonRefresh={doReload}>
            <IonRefresherContent
                pullingIcon={chevronDownCircleOutline}
                pullingText="Pull to refresh"
                refreshingSpinner="circles"
                refreshingText="Refreshing...">
            </IonRefresherContent>
        </IonRefresher>
        <SalesActivities salesActivity={salesActivities} />
        <IonInfiniteScroll threshold="100px"
            disabled={disableInfiniteScroll}
            onIonInfinite={(e: CustomEvent<void>) => fetchNext(e)}>
            <IonInfiniteScrollContent
                loadingText="Loading more...">
            </IonInfiniteScrollContent>
        </IonInfiniteScroll>

        <IonModal isOpen = {showFilterModal} cssClass="my-custom-class">
            <SalesActivityFilter 
                filter={filterData}
                filterChangeHandler={setFilterData}
                onDoneHandler={setFilteredActivityIds}
                onCloseHandler={setShowFilterModal}
            ></SalesActivityFilter>
        </IonModal>
        </>
    )
}

export default SalesActivityCardList; 

