import { getData } from "./common.service";

export async function getCropDiseases(cropId: string) {
  const url = `/crops/${cropId}/diseases`
  return getData(url, {
    basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    sendTokenInHeader: true,
  }).then(res => res.json());
};

export async function getCropPests(cropId: string) {
  const url = `/crops/${cropId}/pests`
  return getData(url, {
    basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    sendTokenInHeader: true,
  }).then(res => res.json());
};

export async function getNewCropId(oldId: string) {
  const filterQuery = {
      where: {
          oldId
      }
  }
  const url = '/id-mappers?filter=' + JSON.stringify(filterQuery);

  return getData(url, {
      basePath: process.env.REACT_APP_CORE_API_BASEPATH,
      sendTokenInHeader: true
  }).then(res => res.json());
}