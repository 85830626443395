import {
    IonContent, IonHeader, IonPage,
    IonTitle, IonToolbar, IonButtons, IonMenuButton,
    IonLabel, IonItem, IonModal, IonButton, IonBadge, IonList, IonIcon
} from '@ionic/react';
import { useEffect, useState } from 'react';
import CustomSearchBox from '../components/CustomSearchBox/CustomSearchBox';
import LoadingSkeleton from '../components/LoadingSkeleton';
import Plots from '../components/Plots';
import useDebounce from '../hooks/useDebounce';
import { GeolocationService } from '../services/geolocation.service';
import { filterPlots, getExpiredPlots } from '../services/plot.service';
import { funnelOutline } from 'ionicons/icons';
import PlotFilter from '../components/PlotFilter';

const ExpiredPlots: React.FC = () => {
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [showDistrictModal, setShowDistrictModal] = useState(false);
    const [districtCounts, setDistrictCounts] = useState({} as any);

    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filterData, setFilterData] = useState<any>();
    const [filteredPlotIds, setFilteredPlotIds] = useState<string[]>([]);
    const [plots, setPlots] = useState([] as any);
    
    const debounceSearchText = useDebounce(searchText, 1000);
    
    useEffect(() => {
        let mounted = true;
        setLoading(true);
        const geoService = new GeolocationService();
        geoService.getCurrentPosition()
            .then(geolocation => {
                setExpiredPlots(mounted, geolocation);
            }).catch(err => {
                setExpiredPlots(mounted, null);
            });

        return () => { mounted = false };
    }, [filteredPlotIds]);

    function setExpiredPlots(mounted: boolean, geolocation: any) {
        getExpiredPlots(geolocation, filteredPlotIds)
            .then(items => {
                if (items.error) {
                    setPlots([]);
                    setDistrictCounts({});
                    setLoading(false);
                }
                else if (mounted) {
                    setPlots(items.plots);
                    setDistrictCounts(items.districtWiseCount);
                    setLoading(false);
                }
            });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Expired Plots</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonButton expand="block" onClick={() => setShowDistrictModal(true)}>View District-wise count</IonButton>
                            
                <div className="searchBarContainer">
                    <div className="sbContainer">
                        <CustomSearchBox setSearchText={setSearchText} />
                    </div>
                    <div className="iconContainer">
                        <IonIcon
                            icon={funnelOutline}
                            slot="end"
                            onClick={(e) => setShowFilterModal(true)}
                        ></IonIcon>
                    </div>
                </div>

                {loading && <LoadingSkeleton />}
                {!loading && (plots?.length > 0 ? <Plots plots={filterPlots(plots, debounceSearchText)} /> : <IonItem><IonLabel>No plots found</IonLabel></IonItem>)}

                <IonModal isOpen={showDistrictModal}>
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="end">
                                <IonButton onClick={e => setShowDistrictModal(false)}>
                                    Close
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <IonList>
                            {Object.keys(districtCounts).length && Object.keys(districtCounts)
                            .sort((a,b) => a.localeCompare(b))
                            .map(k =>
                                <IonItem>
                                    <IonLabel>{k}</IonLabel>
                                    <IonBadge slot="end">{districtCounts[k]}</IonBadge>
                                </IonItem>
                            )}
                        </IonList>
                    </IonContent>
                </IonModal>
                
                <IonModal isOpen={showFilterModal} cssClass="my-custom-class">
                    <PlotFilter
                        filter={filterData}
                        filterChangeHandler={setFilterData}
                        onDoneHandler={setFilteredPlotIds}
                        onCloseHandler={setShowFilterModal}
                        hideFilters={{
                            crop: true,
                            tag: true
                        }}
                    ></PlotFilter>
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default ExpiredPlots;
