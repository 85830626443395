import React from 'react';
import { IonActionSheet } from '@ionic/react';
import { close, mapOutline, navigate } from 'ionicons/icons';

interface IProps {
    lat: number,
    lng: number,
    isOpen: boolean;
    withinAppHandler: Function;
    onDismiss: Function;
}

export const MapViewSelector: React.FC<IProps> = (props) => {

    return (
        <IonActionSheet
            isOpen={props.isOpen}
            onDidDismiss={() => props.onDismiss(false)}
            cssClass='my-custom-class'
            buttons={[{
                text: 'View within App',
                icon: mapOutline,
                handler: () => props.withinAppHandler(props.lat, props.lng)
            }, {
                text: 'Open Google Maps',
                icon: navigate,
                handler: () => {
                    window.open("https://www.google.com/maps/search/?api=1&query=" + props.lat + "," + props.lng)
                }
            }, {
                text: 'Cancel',
                icon: close,
                role: 'cancel',
                handler: () => {
                    console.log('Cancel clicked');
                }
            }]}
        >
        </IonActionSheet>
    );
}

export default MapViewSelector;