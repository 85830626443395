import React from 'react';
import moment from 'moment';
import './TimeSeriesChart.css';

import {
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

interface IProps {
    chartData: {
        time: number,
        value: number,
        anomalies?: any
    }[],
    additionalData?: {
        time: number,
        value: number,
        anomalies?: any
    }[],
    format: string
};

const CustomDot = (props: any) => {
    const { cx, cy, stroke, payload, value } = props;

    if (payload.anomalies?.length > 0) {
        return (
            <circle
                cx={cx}
                cy={cy}
                r={3}
                stroke='red'
                style={{ opacity: "0.9" }}
                strokeWidth={1}
                fill={"red"} />
        );
    }
    return (
        <circle
            cx={cx}
            cy={cy}
            r={1}
            stroke='#8884d8'
            style={{ opacity: "0.9" }}
            strokeWidth={1}
            fill={"#8884d8"} />
    );
};

const CustomTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active && payload[0]?.payload) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`Timestamp : ${new Intl.DateTimeFormat('default', {
                    year: '2-digit', month: 'numeric', day: 'numeric',
                    hour: 'numeric', minute: 'numeric', second: 'numeric'
                }).format(new Date(payload[0]?.payload?.time))}`}</p>
                <p className="label">{`Value : ${payload[0]?.payload?.value}`}</p>
                {
                    payload[0]?.payload?.anomalies?.length &&
                    <p className="label">{`Anomalies : ${payload[0]?.payload?.anomalies.map((item: any) => item.anomalyKey)}`} </p>
                }
            </div>
        );
    }
    return null;
};

const TimeSeriesChart: React.FC<IProps> = (props) => (
    <ResponsiveContainer width="95%" height={200}>
        <LineChart
            margin={{
                top: 20,
                right: 10,
                left: -15,
                bottom: 5,
            }}>
            <XAxis xAxisId={"mainXaxis"} dataKey='time' tickFormatter={(unixTime) => moment(unixTime).format(props.format === 'date' ? 'DD/MMM' : 'DD/MM HH:mm')} />
            <XAxis xAxisId={"additionalXaxis"} dataKey="time" hide={true} />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Line xAxisId={"mainXaxis"} data={props.chartData} type="monotone" dataKey="value" name='Value' stroke="#8884d8" dot={<CustomDot />} />
            {props.additionalData &&
                <Line xAxisId={"additionalXaxis"} data={props.additionalData} type="monotone" dataKey="value" name='Value' stroke="#808080" dot={{ fill: "#808080", strokeWidth: 1, r: 1, style: { opacity: 0.9 } }} />
            }
        </LineChart>
    </ResponsiveContainer>
);

export default TimeSeriesChart;