import { RefresherEventDetail } from '@ionic/core';
import { SyntheticEvent } from 'react';
import { Camera, CameraResultType } from '@capacitor/camera';
import moment from 'moment';
import { generateFromEmail} from "unique-username-generator";

export function doReload(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
        window.location.reload();
        event.detail.complete();
    }, 100);
}

export function makeCall(event: SyntheticEvent, phoneNumber: string) {
    event.stopPropagation();
    window.open(`tel:${phoneNumber}`);
}

export function deleteObjectPropertyByValue(Obj: any, val: any) {
    for (let key in Obj) {
        if (Obj[key] === val) {
            delete Obj[key];
        }
    }
    return Obj;
};

export const takePicture = async () => {
    try {
        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Base64
        });
        var imageUrl = `data:image/png;base64, ${image.base64String}`;
        return imageUrl;
    } catch (error) {
        console.log(error);
    }
};

export async function dataUrlToFile(dataUrl: string, fileName?: string): Promise<File> {
    fileName = moment().toISOString();
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
}

export async function generateUniqueId(name: string, searchFunction: any, setId: any){
    let id = name.replace(/\s/g, '').replace(/-/g, '').toLowerCase();
    
    try{
        if(name!==""){
            id = generateFromEmail(id)
            const response = await searchFunction(id);
            if(response==null){
                setId(null);
                return;
            }
            if(!response.error){
                var found = true;
                while(found){
                    id = generateFromEmail(id, 3);
                    const response = await searchFunction(id);
                    if(response.error) found = false;
                }
                
            }
        }
    }
    catch(err){
    }
    setId(id);
}