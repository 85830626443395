import {
    IonContent, IonHeader, IonPage,
    IonTitle, IonToolbar, IonButtons, IonMenuButton,
    IonSearchbar, IonRefresher, IonRefresherContent, IonInfiniteScroll, IonInfiniteScrollContent, IonModal, IonItem, IonIcon
} from '@ionic/react';
import { chevronDownCircleOutline, downloadOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import VoucherExport from '../components/VoucherExport';
import Vouchers from '../components/Vouchers';
import { doReload } from '../hooks/common';
import { getVoucherList } from '../services/payment.service';
import useDebounce from "../hooks/useDebounce";
import CustomSearchBox from '../components/CustomSearchBox/CustomSearchBox';

const VoucherList: React.FC = () => {
    const [searchText, setSearchText] = useState('');
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
    const [skip, setSkip] = useState<number>(20);
    const [showExportModal, setShowExportModal] = useState(false);
    const [vouchers, setVouchers] = useState([] as any[]);
    const debounceSearchText = useDebounce(searchText, 1000);
    
    useEffect(() => {
        let mounted = true;
        setSkip(20);
        setDisableInfiniteScroll(false);
        getVoucherList(debounceSearchText)
            .then(items => {
                if (mounted) {
                    setVouchers(items);
                }
            });
        return () => { mounted = false };
    }, [debounceSearchText]);

    async function fetchNext($event: CustomEvent<void>) {
        setSkip(skip + 20);
        getVoucherList(debounceSearchText, skip)
            .then(items => {
                if (items?.length > 0) {
                    setVouchers([...vouchers, ...items]);
                    setDisableInfiniteScroll(items.length < 20);
                } else {
                    setDisableInfiniteScroll(true);
                }
                ($event.target as HTMLIonInfiniteScrollElement).complete();
            });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Vouchers</IonTitle>
                </IonToolbar>
                <IonItem lines="none">
                <CustomSearchBox setSearchText={setSearchText} />
                    <IonIcon icon={downloadOutline} slot="end" onClick={e => setShowExportModal(true)}></IonIcon>
                </IonItem>
            </IonHeader>
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={doReload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>
                <Vouchers vouchers={vouchers} />
                <IonInfiniteScroll threshold="100px"
                    disabled={disableInfiniteScroll}
                    onIonInfinite={(e: CustomEvent<void>) => fetchNext(e)}>
                    <IonInfiniteScrollContent
                        loadingText="Loading more...">
                    </IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonContent>

            <IonModal isOpen={showExportModal} cssClass='my-custom-class'>
                <VoucherExport
                    onCloseHandler={setShowExportModal}>
                </VoucherExport>
            </IonModal>
        </IonPage>
    );
};

export default VoucherList;
