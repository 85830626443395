import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonMenuButton, IonBackButton } from '@ionic/react';
import CreateSalesActivity from '../components/CreateSalesActivity';
const SalesActivity: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Add Sales Activity</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Add Sales Activity</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <CreateSalesActivity />
            </IonContent>
        </IonPage>
    );
};

export default SalesActivity;
