import { IonCard, IonCol, IonItem, IonLabel, IonModal, IonRow, IonThumbnail } from '@ionic/react';
import ImageViewer from './ImageViewer';
import { useState } from 'react';

interface IProps {
    salesActivity: any;
}



const SalesActivityCard: React.FC<IProps> = (props) => {

    const [selectedImage, setSelectedImage] = useState('');
    const [showImageModal, setShowImageModal] = useState(false);

    
    return (
        <>
            <IonCard>
                <IonItem>
                    <IonLabel>
                        {props.salesActivity.salesActivityType.description}
                    </IonLabel>
                </IonItem>

                <IonItem lines='none'>
                    <IonLabel>
                        {new Intl.DateTimeFormat('en-GB', {
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric',
                        }).format(new Date(props.salesActivity.date))}
                    </IonLabel>
                </IonItem>

                {props.salesActivity.location && props.salesActivity.location.region.length > 0 && (
                <IonItem lines='none'>
                    <IonLabel>
                    <h2>Region: {props.salesActivity.location.region}</h2>        
                    </IonLabel>
                </IonItem>
                )}

                {props.salesActivity.userid && props.salesActivity.userid.length > 0 && (
                <IonItem lines='none'>
                    <IonLabel>
                    <h2>User Id: {props.salesActivity.userid}</h2>        
                    </IonLabel>
                </IonItem>
                )}

                {props.salesActivity.contact && props.salesActivity.contact.length > 0 && (
                <IonItem lines='none'>
                    <IonLabel>
                    <h2>Contact Number: {props.salesActivity.contact}</h2>        
                    </IonLabel>
                </IonItem>
                )}

                {props.salesActivity.servicedBy && props.salesActivity.servicedBy.length > 0 && (
                    <IonItem lines='none'>
                        <IonLabel>
                        <h2>Serviced By: {props.salesActivity.servicedBy}</h2>        
                        </IonLabel>
                    </IonItem>
                )}

                {props.salesActivity.comment && props.salesActivity.comment.length > 0 && (
                <IonItem lines='none'>
                    <IonLabel class="ion-text-wrap">
                        <h2>Comment: {props.salesActivity.comment}</h2>
                    </IonLabel>
                </IonItem>
                )}

                <IonRow>
                    {props.salesActivity.attachments?.thumbnails?.map((item: any, index: number) =>
                        <IonCol key={index}>
                            <IonThumbnail>
                                <img src={item} alt='activityImage' onClick={e => {
                                    setSelectedImage(props.salesActivity.attachments?.images[index]);
                                    setShowImageModal(true);
                                }} />
                            </IonThumbnail>
                        </IonCol>
                    )}
                </IonRow>

                <IonModal isOpen={showImageModal} cssClass='my-custom-class'>
                    <ImageViewer imageUrl={selectedImage} onDeleteHandler={()=>{}} onCloseHandler={setShowImageModal} />
                </IonModal>

            </IonCard>
        </>
    )
};

export default SalesActivityCard;