import React from 'react';
import { IonCard, IonIcon, IonItem, IonLabel } from '@ionic/react';
import useNavigation from '../hooks/useNavigation';
import { handLeft, thumbsDown, thumbsUp, thumbsUpOutline, ellipse } from 'ionicons/icons';
import './LeadCard.css';
import { makeCall } from '../hooks/common';


interface IProps {
    lead: any;
}

const LeadCard: React.FC<IProps> = (props) => {
    const navigateTo = useNavigation();

    return (
        <>
            <IonCard onClick={() => navigateTo('/tabs/lead', { leadId: props.lead.id })}>
                <IonItem style={{'backgroundColor': 'red'}}>
                    {(props.lead.followUpDate !== undefined) && <IonLabel >
                        <h2>Follow-up: 
                            {new Intl.DateTimeFormat('en-GB', {
                                month: 'short',
                                day: '2-digit',
                                year: 'numeric',
                            }).format(new Date(props.lead.followUpDate))} 
                        </h2>
                    </IonLabel>}
                    <IonIcon icon={ellipse} color={props.lead.status === 'converted' ? "success" : props.lead.status === 'rejected' ? 'danger' : 'warning'}></IonIcon>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Name: {props.lead.contactName}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Number: <a onClick={e => makeCall(e, props.lead.contactPhoneNumber)}>{props.lead.contactPhoneNumber}</a></h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Serviced By: {props.lead.servicer?.name || props.lead.servicedBy}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Comments: {props.lead.comment}</h2>
                    </IonLabel>
                </IonItem>
            </IonCard>
        </>
    );
};

export default LeadCard;
