import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IonAlert, IonButton, IonInput, IonItem, IonLabel, IonList, IonToast, IonIcon, IonGrid, IonRow, IonCol, IonThumbnail, IonModal, IonDatetime, IonSelect, IonSelectOption } from '@ionic/react';
import { handleErrors } from '../services/common.service';
import { useLocation } from 'react-router-dom';
import useNavigation from '../hooks/useNavigation';
import { addLead, getLead, getLeadConfig, updateLead } from '../services/lead.service';
import { useRecoilValue } from 'recoil';
import { userProfileState } from '../services/state.service';
import { addCircle } from 'ionicons/icons';
import { dataUrlToFile, takePicture } from '../hooks/common';
import { uploadFile } from '../services/image.service';
import ImageViewer from './ImageViewer';


const CreateMeeting = () => {
    const navigateTo = useNavigation();
    const location = useLocation();
    const [meeting, setMeeting] = useState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [onSuccess, setOnSuccess] = useState('');
    const [onError, setOnError] = useState('');
    const userProfile: any = useRecoilValue(userProfileState);
    const [selectedImage, setSelectedImage] = useState('');
    const [showImageModal, setShowImageModal] = useState(false);

    const compareWith = (o1: any, o2: any) => {
        if (Array.isArray(o2)) {
            return o2.indexOf(o1) !== -1;
        }
        return o1 === o2;
    };

    async function uploadImage() {
        try {
            const base64Image = await takePicture();
            if (base64Image) {
                const imageFile = await dataUrlToFile(base64Image);
                const uploadResponse = await uploadFile('meetings', 'support',imageFile);
                const responseBody = await uploadResponse.json();
                formik.setFieldValue('photos', [...formik.values.photos, responseBody.images[0]]);
            }
        } catch (err) {
            console.log(err);
            setOnError('Error occured while uploading image');
        }
    }

    function deleteImage(url: string) {
        const imageIndex = formik.values.photos.findIndex(item => item === url);
        if (imageIndex > -1) {
            formik.values.photos.splice(imageIndex, 1);
        }
        formik.setFieldValue('photos', [...formik.values.photos]);
    }

    function setValues(data: any) {
        if (data) {
            formik.setValues({
                edit: true,
                date: data.date,
                contactName: data.contactName,
                contactPhoneNumber: data.contactPhoneNumber,
                location: data.location,
                taluka: data.taluka,
                district: data.district,
                state: data.state,
                attendance: data.attendance,
                soldDevices: data.soldDevices,
                leads: data.leads,
                account: data.account,
                photos: data.photos,
                details: data.details,
                servicedBy: data.servicer?.name || data.servicedBy
            });
        }
        setMeeting(data);
    }

    useEffect(() => {
        let mounted = true;
        const id: any = (location.state as any)?.meetingId ?? null;
        if (id) {
            getLead(id)
                .then(user => {
                    if (mounted) {
                        setValues(user);
                    }
                });
        } else {
            formik.setFieldValue('contactName', (location.state as any)?.contactName ?? '');
            formik.setFieldValue('contactPhoneNumber', (location.state as any)?.contactPhoneNumber ?? '');
            formik.setFieldValue('location', (location.state as any)?.location ?? '');
            formik.setFieldValue('servicedBy', userProfile.name);
        }
        return () => { mounted = false };
    }, [location]);

    const formik = useFormik({
        initialValues: {
            edit: false,
            date: new Date().toISOString(),
            contactName: '',
            contactPhoneNumber: '',
            location: '',
            taluka: '',
            district: '',
            state: '',
            attendance: 0,
            soldDevices: 0,
            leads: 0,
            account: 'sales_meeting',
            photos: [],
            details: '',
            servicedBy: ''
        },
        validationSchema: Yup.object({
            servicedBy: Yup.string().trim().required('Required'),
        }),
        onSubmit: values => {
            setShowConfirmation(true);
        },
    });

    const [statuses, setStatuses] = useState([] as any[]);

    useEffect(() => {
        let mounted = true;
        getLeadConfig()
            .then(response => {
                if (mounted) {
                    setStatuses(response.status.filter((i: any) => ['open', 'closed'].includes(i.id)));
                }
            })
        return () => { mounted = false };
    }, [])

    const saveMeeting = async (values: any) => {
        try {
            delete values.edit;
            delete values.id;
            delete values.servicer;
            values.servicedBy = userProfile.username || values.servicedBy;
            const data = Object.fromEntries(Object.entries(values).filter(([_, v]) => !!v));
            if (!meeting) {
                const response = await addLead(data).then(handleErrors);
                setOnSuccess('Meeting details added');
            } else {
                delete (meeting as any).servicer;
                const updatedObj = Object.assign({}, meeting, data);
                const response = await updateLead(updatedObj).then(handleErrors);
                setOnSuccess('Meeting details updated');
            }
        } catch (err: any) {
            setOnError(err);
        }
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <IonList>

                    <IonItem>
                        <IonLabel position="stacked">Meeting Name</IonLabel>
                        <IonInput id="contactName" name="contactName" value={formik.values.contactName} placeholder="Meeting Name" onIonChange={formik.handleChange}></IonInput>
                    </IonItem>
                    {formik.touched.contactName && formik.errors.contactName ? (<div className="errorMsg">{formik.errors.contactName}</div>) : null}

                    {/* <IonItem>
                        <IonLabel position="stacked">Phone Number</IonLabel>
                        <IonInput id="contactPhoneNumber" name="contactPhoneNumber" value={formik.values.contactPhoneNumber} placeholder="Phone Number" onIonChange={formik.handleChange}></IonInput>
                    </IonItem>
                    {formik.touched.contactPhoneNumber && formik.errors.contactPhoneNumber ? (<div className="errorMsg">{formik.errors.contactPhoneNumber}</div>) : null} */}

                    <IonItem>
                        <IonLabel position="stacked">Location/Village</IonLabel>
                        <IonInput id="location" name="location" value={formik.values.location} placeholder="Location/Village" onIonChange={formik.handleChange}></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Taluka</IonLabel>
                        <IonInput id="taluka" name="taluka" value={formik.values.taluka} placeholder="Taluka" onIonChange={formik.handleChange}></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">District</IonLabel>
                        <IonInput id="district" name="district" value={formik.values.district} placeholder="District" onIonChange={formik.handleChange}></IonInput>
                    </IonItem>

                    {/* <IonItem>
                        <IonLabel position="stacked">State</IonLabel>
                        <IonInput id="state" name="state" value={formik.values.state} placeholder="State" onIonChange={formik.handleChange}></IonInput>
                    </IonItem> */}

                    <IonItem>
                        <IonLabel position="stacked">No. of persons present</IonLabel>
                        <IonInput id="attendance" name="attendance" type="number" value={formik.values.attendance} placeholder="Attendance" onIonChange={formik.handleChange}></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">No. of Devices sold</IonLabel>
                        <IonInput id="soldDevices" name="soldDevices" type="number" value={formik.values.soldDevices} placeholder="Sold devices" onIonChange={formik.handleChange}></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">No. of Hot Leads</IonLabel>
                        <IonInput id="leads" name="leads" type="number" value={formik.values.leads} placeholder="Hot Leads" onIonChange={formik.handleChange}></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Date</IonLabel>
                        <IonDatetime id="followUpDate" name="followUpDate" displayFormat="DD/MM/YYYY" placeholder="Follow-up Date" value={formik.values.date} onIonChange={formik.handleChange}></IonDatetime>
                    </IonItem>


                    <IonItem lines='none'>
                        <IonLabel>Images</IonLabel>
                        <IonIcon icon={addCircle} slot="end" onClick={e => uploadImage()} />
                    </IonItem>
                    <IonItem lines='none'>
                        <IonGrid>
                            <IonRow>
                                {formik.values.photos?.map((item: any, index: number) =>
                                    <IonCol key={index}>
                                        <IonThumbnail>
                                            <img src={item} onClick={e => {
                                                setSelectedImage(formik.values.photos[index]);
                                                setShowImageModal(true);
                                            }} />
                                        </IonThumbnail>
                                    </IonCol>
                                )}
                            </IonRow>
                        </IonGrid>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Serviced By</IonLabel>
                        <IonInput readonly disabled id="servicedBy" name="servicedBy" value={formik.values.servicedBy} placeholder="Select Servicer"></IonInput>
                    </IonItem>
                    {formik.touched.servicedBy && formik.errors.servicedBy ? (<div className="errorMsg">{formik.errors.servicedBy}</div>) : null}

                </IonList>

                <IonButton expand="block" type="submit">{(!meeting ? 'Create' : 'Update') + ' Meeting'}</IonButton>
            </form>
            <IonAlert
                isOpen={showConfirmation}
                onDidDismiss={() => setShowConfirmation(false)}
                header={'Confirm!'}
                message={'Please make sure that you have verified all the meeting details. Save Meeting?'}
                buttons={[
                    {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary'
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            saveMeeting(formik.values);
                        }
                    }
                ]}
            />

            <IonModal isOpen={showImageModal} cssClass='my-custom-class'>
                <ImageViewer imageUrl={selectedImage} onDeleteHandler={deleteImage} onCloseHandler={setShowImageModal} />
            </IonModal>

            <IonToast
                isOpen={!!onSuccess}
                onDidDismiss={() => setOnSuccess('')}
                message={onSuccess}
                duration={2000}
                color="success"
            />

            <IonToast
                isOpen={!!onError}
                onDidDismiss={() => setOnError('')}
                message={onError}
                duration={2000}
                color="danger"
            />

        </>
    );
};

export default CreateMeeting;