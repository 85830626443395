import React from 'react';
import { IonCard, IonItem, IonLabel, IonIcon } from '@ionic/react';
import useNavigation from '../hooks/useNavigation';
import './SalesPlanCard.css';
import { addCircle } from 'ionicons/icons';


interface IProps {
    salesPlan: any;
}

const SalesPlanCard: React.FC<IProps> = (props) => {
    const navigateTo = useNavigation();

    return (
        <>
            <IonCard>
                <IonItem>
                    <IonLabel>
                        {new Intl.DateTimeFormat('en-GB', {
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric',
                        }).format(new Date(props.salesPlan.created_date))}
                    </IonLabel>
                    <IonIcon icon={addCircle} onClick={() => navigateTo('/tabs/salesActivity', { planId: props.salesPlan.id })}></IonIcon>

                </IonItem>
                <div onClick={() => navigateTo('/tabs/salesPlan', { salesPlanId: props.salesPlan.id })}>
                    <IonItem lines='none'>
                        <IonLabel>
                            <h2>Month: {new Intl.DateTimeFormat('en-GB', {
                                month: 'short',
                                year: 'numeric',
                            }).format(new Date(props.salesPlan.created_date))}</h2>
                        </IonLabel>
                    </IonItem>


                    {props.salesPlan.region && props.salesPlan.region.length > 0 && (
                        <IonItem lines='none'>
                            <IonLabel>
                                <h2>Region: {props.salesPlan.region}</h2>
                            </IonLabel>
                        </IonItem>
                    )}
                    {props.salesPlan.village && props.salesPlan.village.length > 0 && (

                        <IonItem lines='none'>
                            <IonLabel>
                                <h2>Village: {props.salesPlan.village}</h2>
                            </IonLabel>
                        </IonItem>
                    )}
                    {props.salesPlan.subDistrict && props.salesPlan.subDistrict.length > 0 && (

                        <IonItem lines='none'>
                            <IonLabel>
                                <h2>Sub District: {props.salesPlan.subDistrict}</h2>
                            </IonLabel>
                        </IonItem>
                    )}
                    {props.salesPlan.district && props.salesPlan.district.length > 0 && (

                        <IonItem lines='none'>
                            <IonLabel>
                                <h2>District: {props.salesPlan.district}</h2>
                            </IonLabel>
                        </IonItem>
                    )}
                    {props.salesPlan.state && props.salesPlan.state.length > 0 && (
                        <IonItem lines='none'>
                            <IonLabel>
                                <h2>State: {props.salesPlan.state}</h2>
                            </IonLabel>
                        </IonItem>
                    )}
                </div>
            </IonCard>
        </>
    );
};

export default SalesPlanCard;
