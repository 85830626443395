import React from 'react';
import SalesPlanCard from './SalesPlanCard';

interface IProps {
    salesPlans: any[];
}

const SalesPlans: React.FC<IProps> = (props) => {
    return (
        <>
            {props.salesPlans && props.salesPlans.map((plan, index) => <SalesPlanCard salesPlan={plan} key={index} />)}
        </>
    );
};

export default SalesPlans;