import { postData } from "./common.service";


export async function saveToken(data: any) {
    return postData('/fcm-tokens', {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    });
}

