import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonMenuButton, IonBackButton } from '@ionic/react';
import CreateMovement from '../components/CreateMovement';

const AddMovement: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Add Inventory</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Add Inventory</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <CreateMovement />
            </IonContent>
        </IonPage>
        
    );
};

export default AddMovement;
