import React from 'react';
import { IonCard, IonItem, IonLabel } from '@ionic/react';
import useNavigation from '../hooks/useNavigation';

interface IProps {
    formula: any;
}

const FormulaCard: React.FC<IProps> = (props) => {
    const navigateTo = useNavigation();

    return (
        <>
            <IonCard onClick={() => navigateTo('/tabs/formula', { formulaId: props.formula.id })}>
                <IonItem>
                    <IonLabel>
                        <h2>{props.formula.name}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Formula: {props.formula.expression}</h2>
                    </IonLabel>
                </IonItem>
            </IonCard>
        </>
    );
};

export default FormulaCard;
