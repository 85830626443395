import { useFormik } from 'formik';
import { IonAlert, IonButton, IonInput, IonItem, IonLabel, IonList, IonModal, IonToast } from '@ionic/react';
import { useState } from 'react';
import {handleErrors } from '../services/common.service';
import UserList from '../pages/UserList';
import SelectItem from './SelectItem';
import { completeMovement, getInventoryItemsPaginated, postInventoryMovement } from '../services/device.service';
import { useRecoilValue } from 'recoil';
import { userProfileState } from '../services/state.service';
import useNavigation from '../hooks/useNavigation';
const CreateMovement = () => {

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showSelectUserModal, setShowSelectUserModal] = useState(false);
    const [showSelectInventoryLocationModal, setShowSelectInventoryLocationModal] = useState(false);
    const [showSelectInventoryItemModal, setShowSelectInventoryItemModal] = useState(false);
    const [showSelectMovementTypeModal ,setShowSelectMovementTypeModal] = useState(false);
    const [onSuccess, setOnSuccess] = useState('');
    const [onError, setOnError] = useState('');
    const userProfile: any = useRecoilValue(userProfileState);
    const navigateTo = useNavigation();
    

    const defaultToLocation = {
        name: 'FYLLO-HQ', 
        id: '65b75a0a0926a680c97fb717'
    }
    const defaultMovement = {
        name: 'Internal transfer',
        id: 'INTERNAL_TRANSFER'
    }
    const formik = useFormik({
        initialValues: {
            edit: false,
            farmUserId: userProfile.farmUserId,
            toLocation: defaultToLocation, 
            deviceName: {name: '' as string, id: '' as string}, 
            movementType: defaultMovement,
            quantity: '',
            comment: ''
        },
        onSubmit: values => {
            setShowConfirmation(true);
        },
    });

    const movementTypeOptions = [{id: 'ORDER_FULFILLMENT', name:'Order fulfillment'},
        {id: 'INTERNAL_TRANSFER', name:'Internal transfer'},
        {id: 'INSTALLATION', name:'Installation'}]

    function setFarmUserId(item: any) {
        formik.setFieldValue('farmUserId', item.farmUserId);
        setShowSelectUserModal(false);
    }

    async function setFromInventoryItemId(item: any) {
        formik.setFieldValue('deviceName',item);
        setShowSelectInventoryItemModal(false);
    }
    async function setFromMovementType(item:any) {
        formik.setFieldValue('movementType', item);
        setShowSelectMovementTypeModal(false);        
    }


  

    const saveMovement = async (values: any) => {
        console.log(values);
        const finalData = {
            quantity: Number(values.quantity),
            comments: values.comment,
            userId: values.farmUserId,
            inventoryItemId: values.deviceName.id,
            fromInventoryLocationId: '',
            toInventoryLocationId: values.toLocation.id,
            type: values.movementType.id,
        }

        try{
            const response = await postInventoryMovement(finalData).then(handleErrors);
            if(response.id){
                const res = await completeMovement(response.id).then(handleErrors);
                if(res.status==="COMPLETED"){
                    setOnSuccess('Movement details added');
                }
            }
            
        } catch (err:any) {
            setOnError(err);
        }
    };

    
    
    return(
        <>
            <form onSubmit={formik.handleSubmit}>
                <IonList>
                    <IonItem>
                        <IonLabel position="stacked">User Id</IonLabel>
                        <IonInput readonly id="farmUserId" name="farmUserId" value={formik.values.farmUserId} placeholder="Select User Id" onClick={e => setShowSelectUserModal(true)}></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">To Location</IonLabel>
                        <IonInput readonly disabled id="inventoryLocationId" name="inventoryLocationId" value={formik.values.toLocation?.name} placeholder="To Inventory Location" onClick={e => setShowSelectInventoryLocationModal(true)}></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Device Name</IonLabel>
                        <IonInput readonly id="inventoryItemId" name="inventoryItemId" value={formik.values.deviceName?.name} placeholder="Device Name" onClick={e => setShowSelectInventoryItemModal(true)}></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Movement Type</IonLabel>
                        <IonInput readonly disabled id="movementType" name="movementType" value={formik.values.movementType?.name} placeholder="Type of Movement" onClick={e => setShowSelectMovementTypeModal(true)}></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Quantity</IonLabel>
                        <IonInput id="quantity" name="quantity"  value={formik.values.quantity} placeholder="Quantity" onIonChange={formik.handleChange}></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Comment</IonLabel>
                        <IonInput id="comment" name="comment"  value={formik.values.comment} placeholder="Comment" onIonChange={formik.handleChange}></IonInput>
                    </IonItem>
                </IonList>

                <IonButton expand="block" type="submit">
                    Add Movement
                </IonButton>
            </form>
        
            {showSelectUserModal && <IonModal isOpen={showSelectUserModal} >
            <UserList
                    selectedValue={formik.values.farmUserId}
                    onClickHandler={setFarmUserId}
                    onClose={setShowSelectUserModal}
                />
            </IonModal>}

            {showSelectInventoryItemModal && <IonModal isOpen={showSelectInventoryItemModal} >
                <SelectItem
                    label='Select Item'
                    selectedValue={formik.values.deviceName!.name}
                    labelKey='name'
                    valueKey='id'
                    setItem={setFromInventoryItemId}
                    onClose={setShowSelectInventoryItemModal}
                    getPaginatedItems={getInventoryItemsPaginated}
                />
            </IonModal>}

            {showSelectMovementTypeModal && <IonModal isOpen={showSelectMovementTypeModal} >
                <SelectItem
                    label='Select Item'
                    selectedValue={formik.values.movementType!.name}
                    labelKey='name'
                    valueKey='id'
                    setItem={setFromMovementType}
                    onClose={setShowSelectMovementTypeModal}
                    items={movementTypeOptions}
                />
            </IonModal>}

            <IonAlert
                isOpen={showConfirmation}
                onDidDismiss={() => setShowConfirmation(false)}
                header={'Confirm!'}
                message={'Please make sure that you have verified all the details. Save this movement?'}
                buttons={[
                    {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary'
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            saveMovement(formik.values);
                        }
                    }
                ]}
            />

            <IonToast
                isOpen={!!onSuccess}
                onDidDismiss={() => setOnSuccess('')}
                message={onSuccess}
                duration={2000}
                color="success"
            />

            <IonToast
                isOpen={!!onError}
                onDidDismiss={() => setOnError('')}
                message={onError}
                duration={2000}
                color="danger"
            />


        </>
    )
}
export default CreateMovement;