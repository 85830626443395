import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonMenuButton, IonBackButton } from '@ionic/react';
import CreateInteraction from '../components/CreateInteraction';

const Interaction: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Conversation</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Conversation</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <CreateInteraction />
            </IonContent>
        </IonPage>
    );
};

export default Interaction;
