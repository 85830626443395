import { getData } from './common.service';  

export const API_RESPONSE_LIMIT = 100;  

export async function getOrganizations(skip = 0, additionalFilters = {}) {
    const qs = {
        offset: skip,
        limit: API_RESPONSE_LIMIT,
        order: 'name DESC',
         
        fields: {
            id: true,
            name: true
        }
    };

    return getData('/organizations?filter=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
      .catch(error => {
        console.error('Error fetching organizations:', error);
      });
}
