import React from 'react';
import ReferralCard from './ReferralCard';

interface IProps {
    referrals: any[];
}

const Referrals: React.FC<IProps> = (props) => {
    return (
        <>
            {props.referrals && props.referrals.map((referral, index) => <ReferralCard referral={referral} key={index} />)}
        </>
    );
};

export default Referrals;