import React, { useEffect, useRef, useState } from "react";
import "./CreateLeadOrder.css";
import {
  ActionSheetButton,
  IonActionSheet,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonModal,
  IonSearchbar,
  IonText,
  IonTextarea,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { addOrder, updateOrder } from "../services/order.service";
import { userProfileState } from "../services/state.service";
import { useRecoilValue } from "recoil";
import { findPhoneNumbersInText } from "libphonenumber-js";
import { useLocation } from "react-router";
import useNavigation from "../hooks/useNavigation";
import {  getUsersWithRolesSupport} from "../services/user.service";


// Define LeadStages as an enum
export enum LeadStages {
  NewLead = "newLead",
  FieldVisitScheduled = "fieldVisitScheduled",
  ReferenceCheck = "referenceCheck",
  Negotiation = "negotiation",
  Sold = "soldOut",
  NotNeeded = "notNeeded",
  Installing = "installing",
  Later = "later",
}

interface Agent {
  farmUserId: string;
  username:string;
  mobile:string;
}

// Map LeadStages enum values to labels
export const LeadStagesLabels: Record<LeadStages, string> = {
  [LeadStages.NewLead]: "New Lead",
  [LeadStages.FieldVisitScheduled]: "Visit Scheduled",
  [LeadStages.ReferenceCheck]: "Reference check",
  [LeadStages.Negotiation]: "Negotiation",
  [LeadStages.Sold]: "Sold",
  [LeadStages.NotNeeded]: "Not needed",
  [LeadStages.Installing]: "Installing",
  [LeadStages.Later]: "Later",
};

const CreateLeadOrder: React.FC = () => {
  const [showLeadStageActionSheet, setShowLeadStageActionSheet] =
    useState<boolean>(false);

  const location = useLocation();
  const navigateTo = useNavigation();

  const leadStage = useRef<LeadStages>(LeadStages.NewLead);
  const [isNewLead, setIsNewLead] = useState<boolean>(false);
  
  const orderDetailRef = useRef<any>();
  const userProfile: any = useRecoilValue(userProfileState);
  const orderRef = useRef<any>();

  const [onSuccess, setOnSuccess] = useState("");
  const [onError, setOnError] = useState("");

  const [agentsList, setAgentsList] = useState<Agent[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAgentUserModal, setShowAgentUserModal] = useState(false);

  const [assignedDetails, setAssignedDetails] = useState({
    agentId: "",
    agentName: "",
  });

  const [loading, setLoading] = useState(false);

  const getAssignedAgent = (assignedTo: string): Agent[] => {
    return agentsList.filter((item) => String(item.farmUserId) === assignedTo);
  };

 
 
  useEffect(() => {
    let isMounted = true;

    getUsersWithRolesSupport().then((res) => {
      if (isMounted) {
        setAgentsList(res);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const { state } = location;
    if (state && (state as any).orderId !== undefined) {
        assignedDetails.agentId=userProfile?.farmUserId
        assignedDetails.agentName=userProfile?.name

    }
}, [location]);
 

  useEffect(() => {
    if (!!location?.state) {
      const { order } = location.state as any; 
      if (order) {
        const { detail, isNewLead: newLead, stage, assignedTo } = order;
       
        const agent = getAssignedAgent(assignedTo) as Agent[];

        if (agent.length > 0) {
          setAssignedDetails({
            agentId: agent[0]?.farmUserId,
            agentName: `${agent[0]?.username}`,
          });
        }

        setIsNewLead(newLead);
        leadStage.current = stage;

        orderRef.current = order;
        if (orderDetailRef.current) {
          (orderDetailRef.current as any).value = detail;
        }
      }
    }
  }, [location, agentsList]);

  /*
    Event handlers
  */
  const openLeadStageActionSheet = () => {
    setShowLeadStageActionSheet(true);
  };

  const handleNewLead = (
    event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setIsNewLead(true);
  };

  const handleExistingLead = (
    event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setIsNewLead(false);
  };

  const closeLeadStageActionSheet = () => {
    setShowLeadStageActionSheet(false);
  };

  const handleStageChange = (newStageId: LeadStages) => {
    leadStage.current = newStageId;
  };

  /*
    API Calls
  */
  const saveOrder = async () => {
    setLoading(true);

    setTimeout(async () => {
      const detail = (orderDetailRef.current as any)?.value ?? "";
      const user = userProfile.farmUserId;

      const orderMobiles = extractPhoneNumbers(detail);
      const stage = leadStage.current;
      if (!stage) {
        alert("Please select stage");
        setLoading(false);
        return;
      }

      if (orderMobiles.length === 0) {
        alert("No mobile detected");
        setLoading(false);
        return;
      }

      if (orderMobiles.length > 1) {
        alert("Multiple mobiles detected, selecting 1st number by default");
        setLoading(false);
        return;
      }

      // Create payload after delay and only if `assignDetails.agentId` is present
      if (assignedDetails.agentId) {
        const payload: any = {
          detail,
          user,
          mobile: orderMobiles[0],
          stage,
          isNewLead: isNewLead,
          assignedTo: String(assignedDetails.agentId),
        };

        // If updating an existing order
        if (!!orderRef.current && !!orderRef.current.id) {
          payload.id = orderRef.current.id;
          updateOrder(payload).then(handleSaveResponse);
        } else {
          // If adding a new order
          addOrder(payload).then(handleSaveResponse);
        }
      } else {
        alert("Agent ID is missing");
      }

      // Reset loading after the process
      setLoading(false);
    }, 1000);
  };

  const handleSaveResponse = (res: any) => {
    if (!!res.ok || !!res.id) {
      setOnSuccess("Saved successfully");
      setTimeout(() => {
        navigateTo("/orders", { refreshList: true });
      }, 500);
    } else {
      setOnSuccess("Failed to save");
    }
  };

  return (
    <div className="container">
      {/* text area */}
      <div>
        <IonTextarea
          rows={8}
          autofocus
          ref={orderDetailRef}
          className="text-area"
          placeholder="Enter Details"
          defaultValue={(orderDetailRef.current as any)?.value ?? ""}
        />
        <IonToolbar className="ion-toolbar">
          <IonButton
            fill={!!isNewLead ? "solid" : "outline"}
            onClick={handleNewLead}
            expand="block"
            className="stage-button"
            mode="ios"
          >
            New User
          </IonButton>
          <IonButton
            fill={!isNewLead ? "solid" : "outline"}
            onClick={handleExistingLead}
            expand="block"
            className="stage-button"
            mode="ios"
          >
            Existing User
          </IonButton>
        </IonToolbar>
        {!!leadStage.current && (
          <IonItem>
            <IonLabel>Stage</IonLabel>
            <IonText
              className="selected-stage"
              onClick={openLeadStageActionSheet}
            >
              {LeadStagesLabels[leadStage.current]}
            </IonText>
          </IonItem>
        )}
      </div>

      {showAgentUserModal && (
        <IonModal isOpen={showAgentUserModal}>
          <IonHeader>
            <IonToolbar>
              {showAgentUserModal ? (
                <IonButtons slot="end">
                  <IonButton onClick={(e) => setShowAgentUserModal(false)}>
                    Close
                  </IonButton>
                </IonButtons>
              ) : (
                <IonButtons slot="start">
                  <IonMenuButton />
                </IonButtons>
              )}
              <IonTitle>Assigned To</IonTitle>
            </IonToolbar>
            <IonToolbar>
              <IonSearchbar
                placeholder="Search by name or mobile..."
                value={searchTerm}
                onIonChange={(e) => setSearchTerm(e.detail.value!)}
              ></IonSearchbar>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            {agentsList
              ?.filter((agent: any) =>
                searchTerm
                  ? `${agent.username} ${agent.mobile}`
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  : true
              )
              .map((agent: any) => (
                <IonCard
                  key={agent.id}
                  onClick={() => {
                    setAssignedDetails({
                      agentId: agent?.farmUserId,
                      agentName: `${agent?.username}`,
                    });
                    setShowAgentUserModal(false);
                  }}
                >
                  <IonItem lines="none">
                    <IonLabel>
                      <h2>Name: {`${agent?.username} `}</h2>
                    </IonLabel>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>
                      <h2>
                        Mobile:{" "}
                        <button
                          style={{
                            background: "none",
                            border: "none",
                            padding: 0,
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={(e) => makeCall(e, agent?.mobile)}
                        >
                          {agent?.mobile || "No Mobile Number"}
                        </button>
                      </h2>
                    </IonLabel>
                  </IonItem>
                </IonCard>
              ))}
          </IonContent>
        </IonModal>
      )}

      <IonItem>
        <IonLabel position="stacked">Assigned To</IonLabel>
        <IonInput
          readonly
          id="ownerId"
          name="ownerId"
          value={
             !assignedDetails.agentId && agentsList.length === 0
                ? "loading..."  
                : assignedDetails.agentName  
          }
          placeholder="Select Agent"  
          onClick={() => setShowAgentUserModal(true)}
        ></IonInput>
      </IonItem>

      <IonButton
        fill="solid"
        color="success"
        onClick={saveOrder}
        disabled={loading}
        expand="block"
        mode="ios"
      >
        {/* {loading ? "Saving..." : "Save"} */}
        {/* {!orderRef.current ? "Saving..." : "Update"} */}
        {loading ? "Saving..." : (orderRef.current ? "Update" : "Save")}

      </IonButton>

      <LeadStagesActionSheet
        selected={leadStage.current}
        isOpen={showLeadStageActionSheet}
        onClose={closeLeadStageActionSheet}
        onChange={handleStageChange}
      />

      <IonToast
        isOpen={!!onSuccess}
        onDidDismiss={() => setOnSuccess("")}
        message={onSuccess}
        duration={2000}
        color="success"
      />

      <IonToast
        isOpen={!!onError}
        onDidDismiss={() => setOnError("")}
        message={onError}
        duration={2000}
        color="danger"
      />
    </div>
  );
};

export default CreateLeadOrder;

type ActionSheetProps = {
  isOpen: boolean;
  selected?: string;
  onClose: () => void;
  onChange: (id: LeadStages) => void;
};

export const LeadStagesActionSheet: React.FC<ActionSheetProps> = (props) => {
  const { isOpen, selected, onClose, onChange } = props;

  // Map LeadStages enum to ActionSheetButton
  const buttons = Object.entries(LeadStagesLabels).map(([key, value]) => {
    const stage = key as LeadStages;
    const disabled = [LeadStages.Installing, LeadStages.Later].includes(stage);
    return {
      text: value,
      role: key === selected ? "selected" : "default",
      handler() {
        if (!disabled) {
          onChange(stage);
        }
      },
      cssClass: disabled ? "disabled-button" : "",
    } as ActionSheetButton;
  });

  return (
    <IonActionSheet
      isOpen={isOpen}
      onDidDismiss={onClose}
      buttons={[
        ...buttons,
        {
          text: "Cancel",
          role: "cancel",
          handler() {
            onClose();
          },
        },
      ]}
    />
  );
};

const extractPhoneNumbers = (text: string): string[] => {
  const phoneNumbers = findPhoneNumbersInText(text, {
    defaultCountry: "IN",
  }).map((response) => {
    const { number } = response;
    return number.number;
  });

  return phoneNumbers;
};

// Define makeCall function
const makeCall = (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  phoneNumber: string | undefined
) => {
  e.preventDefault();
  if (phoneNumber) {
    window.open(`tel:${phoneNumber}`, "_self"); // Opens the phone dialer
  } else {
    console.error("Phone number is not available");
  }
};
