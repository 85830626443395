import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonBackButton,
  IonFooter,
  IonButton,
  IonItem,
  IonTextarea,
  IonIcon,
  IonModal,
  IonCard,
  IonToast,
  IonAlert,
} from "@ionic/react";
import CreateTicket from "../components/CreateTicket";
import { useLocation } from "react-router";
import NewTicket from "./NewTicket";
import { useFormik } from "formik";
import * as Yup from "yup";
import { zammadProfileState } from "../services/state.service";
import { useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import {
  createTicket,
  createUser,
  getTicket,
  getTicketById,
  getZammadUserByMobile,
  postTicket,
} from "../services/zammad.service";
import { images } from "ionicons/icons";
import { send } from "ionicons/icons";
import { takePicture } from "../hooks/common";
import useNavigation from "../hooks/useNavigation";
import { getUser } from "../services/user.service";

const Ticket: React.FC = () => {
  const zammadProfile: any = useRecoilValue(zammadProfileState);
  const location = useLocation();
  const newTicket: any = (location.state as any)?.newTicket ?? false;
  const id: any = (location.state as any)?.ticketId ?? null;
  const isUpdate = (location.state as any)?.updateTicket ?? false;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [chat, setChat] = useState<any[]>([]);
  const [imagePreview, setIsImagePreview] = useState<boolean>(false);
  const [onSuccess, setOnSuccess] = useState("");
  const [onError, setOnError] = useState("");
  const [ticketId, setTicketId] = useState<any>();
  const navigateTo = useNavigation();
  const [loadingState, setLoadingState] = useState(false);

  const formik = useFormik({
    initialValues: {
      customerUserId: "",
      customerId: "",
      customerMobile: "",
      farmId: "",
      plotId: "",
      title: "",
      priority: 1,
      ownerId: "",
      ownerName: "",
      body: "",
      message: "",
      img: "",
    },
    validationSchema: Yup.object({
      customerUserId: Yup.string().trim().required("Required"),
      customerId: Yup.string().trim().required("Required"),
      customerMobile: Yup.string().trim().required("Required"),
      title: Yup.string().trim().required("Required"),
    }),
    onSubmit: (values) => {},
  });

  useEffect(() => {
    if (zammadProfile) {
      formik.setFieldValue("ownerId", zammadProfile?.id);
      formik.setFieldValue(
        "ownerName",
        `${zammadProfile?.firstname} ${zammadProfile?.lastname}`
      );
    }
  }, [zammadProfile]);

  useEffect(() => {
    let mounted = true;
    if (isUpdate) {
      getTicketById(id).then((res) => {
        const ticketToUpdate = res?.assets?.Ticket[id];
        formik.setFieldValue("customerId", ticketToUpdate?.customer_id);
        formik.setFieldValue("priority", ticketToUpdate?.priority_id);
        formik.setFieldValue("ownerId", ticketToUpdate?.owner_id);
        formik.setFieldValue("title", ticketToUpdate?.title);
      });
    }
    return () => {
      mounted = false;
    };
  }, [location]);

  const sendMessage = () => {
    if (!!formik.values.message.length && !formik.values.img) {
      const body = {
        article: {
          body: formik.values.message,
          created_at: new Date(),
          sender: "Agent",
        },
      };
      setChat([...chat, body.article]);
      postMessage(body);
    } else {
      setOnError("Type a message or attach an image");
    }
  };

  async function uploadImage() {
    try {
      const base64Image = await takePicture();
      formik.setFieldValue("img", base64Image);
      if (!!base64Image) {
        setIsImagePreview(true);
      }
    } catch (err) {
      console.log(err);
      setOnError("Error occured while uploading image");
    }
  }

  useEffect(() => {
    const id: any = (location.state as any)?.ticketId ?? null;
    setTicketId(id);
    if (id && !isUpdate) {
      fetchTicket(id);
    }
  }, [location]);

  const fetchTicket = (tickeId: number) => {
    setChat([]);
    getTicket(tickeId).then((chat: any[]) => {
      setChat(chat);
    });
  };

  const postMessage = (body: any) => {
    postTicket(ticketId, body)
      .then((res) => {
        if (!res.id) {
          setOnError("Failed to send message");
          setChat((prevChat) =>
            prevChat.filter((item) => item !== body.article)
          );
        }
      })
      .catch((error) => {
        setOnError("Error sending message");
        setChat((prevChat) => prevChat.filter((item) => item !== body.article));
      });
    formik.setFieldValue("img", null);
    formik.setFieldValue("message", "");
    setIsImagePreview(false);
  };

  const closeTicket = async () => {
    try {
      setLoadingState(true);

      const body = { state: "closed" };
      const res = await postTicket(ticketId, body);

      if (res?.id) {
        setOnSuccess("Ticket updated");
        navigateTo("/tickets", { updated: true });
      } else {
        setOnError("Failed to update ticket");
      }
    } catch (error) {
      setOnError("Error updating ticket");
    } finally {
      setLoadingState(false);
    }
  };

  const sendImage = () => {
    const body = {
      article: {
        body: `<img src="${formik.values.img}" style="height: 100px; width: 100px;">`,
        attachments: [
          {
            filename: "ticket" + ticketId + "attachement.png",
            data: formik.values.img
              .replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
              .trim(),
            content_type: "text/html",
            "mime-type": "image",
          },
        ],
        created_at: new Date(),
        sender: "Agent",
      },
    };
    setChat([...chat, body.article]);
    postMessage(body);
  };

  const updateTicket = async () => {
    setLoadingState(true);

    if (!formik?.values?.ownerId || !formik?.values?.priority || !ticketId) {
      setOnError("Owner and priority not found");
      setLoadingState(false);
      return;
    }

    const payload = {
      owner_id: formik?.values.ownerId,
      priority_id: formik?.values.priority,
    };

    try {
      const res = await postTicket(ticketId, payload);

      if (res.id) {
        setOnSuccess("Ticket updated");
        navigateTo("/tickets", { updated: true });
      } else {
        setOnError("Failed to update ticket");
      }
    } catch (error) {
      setOnError("Error updating ticket");
    } finally {
      setLoadingState(false);
    }
  };

  const createNewTicket = async () => {
    const {
      title,
      customerUserId,
      customerMobile,
      ownerId,
      customerId,
      farmId,
      plotId,
    } = formik.values;

    if (
      title &&
      customerUserId &&
      customerMobile &&
      ownerId &&
      plotId &&
      customerId
    ) {
      const articleBody = `
      <div>${formik?.values?.body}</div>
      `;

      const payload = {
        title: formik?.values?.title,
        group_id: 1,
        customer_id: formik.values.customerId || formik.values?.ownerId,
        owner_id: formik.values?.ownerId,
        priority_id: formik.values.priority,
        state_id: 2,
        article: {
          content_type: "text/html",
          body: articleBody,
          type: "note",
          internal: false,
          sender: "Agent",
        },
      };
      setLoadingState(true);

      try {
        await getZammadUserByMobile(formik?.values?.customerMobile).then(
          async (res) => {
            if (res.length === 0) {
              let newUser: any;
              await getUser(formik.values.customerUserId).then((res) => {
                const [firstName, ...lastName] = (res.name ?? "").split(" ");
                newUser = {
                  firstname: firstName,
                  lastname: lastName.join(" "),
                  mobile: formik.values.customerMobile,
                  roles: ["Customer"],
                };
              });

              await createUser(newUser).then((res) => {
                payload.customer_id = res.id;
              });
            }
          }
        );

        const res = await createTicket(
          formik?.values?.plotId,
          payload,
          zammadProfile?.id
        );

        if (res?.id) {
          setOnSuccess("Ticket created");
          navigateTo("/tickets", { updated: true });
        } else {
          setOnError("Failed to create ticket");
        }
      } catch (error) {
        setOnError("Error creating ticket");
      } finally {
        setLoadingState(false);
      }
    } else {
      setOnError("Title, agent, customer, and plot are required.");
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Ticket</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {(newTicket || isUpdate) && (
          <NewTicket formik={formik} isUpdate={isUpdate} />
        )}
        <CreateTicket isUpdate={isUpdate} chat={chat} />
      </IonContent>
      <IonFooter>
        {!newTicket && !isUpdate && (
          <IonItem
            lines="none"
            style={{ display: "flex", alignItems: "center" }}
          >
            <IonTextarea
              value={formik.values.message}
              onIonChange={(e) =>
                formik.setFieldValue("message", e.detail.value!)
              }
              style={{
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "10px",
                resize: "vertical",
                fontSize: "16px",
                boxSizing: "border-box",
              }}
              placeholder="Type your message here..."
            ></IonTextarea>

            <IonItem lines="none" disabled={!!formik.values.message.length}>
              <IonIcon
                icon={images}
                slot="end"
                onClick={(e) => uploadImage()}
              />
            </IonItem>

            <IonButton
              onClick={sendMessage}
              fill="clear"
              style={{ marginLeft: "8px" }}
            >
              <IonIcon icon={send} slot="icon-only" />
            </IonButton>
          </IonItem>
        )}
        <IonButton
          expand="block"
          onClick={() => setShowConfirmation(true)}
          type="submit"
          disabled={loadingState} // Disable the button when loading
        >
          {loadingState
            ? isUpdate
              ? "Updating..."  
              : !newTicket
              ? "Closing..."  
              : "Creating..."  
            : isUpdate
            ? "Update Ticket"  
            : !newTicket
            ? "Close Ticket"
            : "Create Ticket"}
        </IonButton>
      </IonFooter>

      <IonToast
        isOpen={!!onSuccess}
        onDidDismiss={() => setOnSuccess("")}
        message={onSuccess}
        duration={2000}
        color="success"
      />

      <IonToast
        isOpen={!!onError}
        onDidDismiss={() => setOnError("")}
        message={onError}
        duration={2000}
        color="danger"
      />

      <IonAlert
        isOpen={showConfirmation}
        onDidDismiss={() => setShowConfirmation(false)}
        header={"Confirm!"}
        message={
          isUpdate
            ? "Do you want to update this ticket?"
            : !newTicket
            ? "Do you want to close this ticket?"
            : "Do you want to create a new ticket?"
        }
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Yes",
            handler: () => {
              isUpdate
                ? updateTicket()
                : !newTicket
                ? closeTicket()
                : createNewTicket();
            },
          },
        ]}
      />

      <IonModal isOpen={imagePreview} cssClass="my-custom-class">
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsImagePreview(false)}>
                  Close
                </IonButton>
              </IonButtons>
              <IonTitle>Image Viewer</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen>
            <IonItem lines="none">
              <IonCard>
                <img src={formik.values.img} alt="attachement" />
              </IonCard>
            </IonItem>
            <IonButton expand="block" color="primary" onClick={sendImage}>
              Send
            </IonButton>
            <IonButton
              expand="block"
              color="danger"
              onClick={() => {
                formik.setFieldValue("img", null);
                setIsImagePreview(false);
              }}
            >
              Delete
            </IonButton>
          </IonContent>
        </IonPage>
      </IonModal>
    </IonPage>
  );
};

export default Ticket;
