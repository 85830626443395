import { atom, selector } from "recoil";
import { recoilPersist } from "recoil-persist";
import { getDeviceTypes, getPlaces, getPlotTags, getPlotTypes, getSoilChars, getSupportedCrops } from "./plot.service";

const { persistAtom } = recoilPersist();

export const openTicketsState = atom({
    key: 'openTicketsState',
    default: null
});

export const userProfileState = atom({
    key: 'userProfileState',
    default: null
});

export const zammadProfileState = atom({
    key: 'zammadProfileState',
    default: null,
    effects_UNSTABLE: [persistAtom],
});

export const allUsersState = atom({
    key: 'allUsersState',
    default: []
});

export const soilTypesState = selector({
    key: 'soilTypesState',
    get: async ({ get }) => {
        try {
            const response = await getSoilChars();
            return response;
        } catch (err: any) {
            throw new Error(err);
        }
    },
});

export const plotTypesState = selector({
    key: 'plotTypesState',
    get: async ({ get }) => {
        try {
            const response = await getPlotTypes();
            return response;
        } catch (err: any) {
            throw new Error(err);
        }
    },
});

export const cropTypesState = selector({
    key: 'cropTypesState',
    get: async ({ get }) => {
        try {
            const response = await getSupportedCrops();
            return response;
        } catch (err: any) {
            throw new Error(err);
        }
    },
});

export const deviceTypesState = selector({
    key: 'deviceTypesState',
    get: async ({ get }) => {
        try {
            const response = await getDeviceTypes();
            return response;
        } catch (err: any) {
            throw new Error(err);
        }
    },
});

export const placesState = selector({
    key: 'placesState',
    get: async ({ get }) => {
        try {
            const response = await getPlaces();
            return response;
        } catch (err: any) {
            throw new Error(err);
        }
    },
});

export const plotTagsState = selector({
    key: 'plotTagsState',
    get: async ({ get }) => {
        try {
            const response = await getPlotTags();
            return response.map((i: any) => { return { id: i, name: i } });
        } catch (err: any) {
            throw new Error(err);
        }
    },
});