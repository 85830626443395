import {
    IonContent, IonHeader, IonPage,
    IonTitle, IonToolbar, IonButtons, IonMenuButton,
    IonSegment, IonSegmentButton, IonLabel
} from '@ionic/react';
import { useState } from 'react';
import InactivePlots from '../components/InactivePlots';
import OutlierPlots from '../components/OutlierPlots';

enum ListType {
    inactive = 'inactive',
    outlier = 'outlier'
};

const FaultyPlots: React.FC = () => {
    const [listType, setListType] = useState(ListType.inactive);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Faulty Plots</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonSegment value={listType} onIonChange={e => setListType(e.detail.value as ListType)}>
                    <IonSegmentButton value={ListType.inactive}>
                        <IonLabel>Inactive</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value={ListType.outlier}>
                        <IonLabel>Outlier</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
                {listType === ListType.inactive && <InactivePlots />}
                {listType === ListType.outlier && <OutlierPlots />}
            </IonContent>
        </IonPage>
    );
};

export default FaultyPlots;
