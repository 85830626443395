import { IonIcon, IonToast } from '@ionic/react';
import React, { useState } from 'react'
import { dataUrlToFile, takePicture } from '../hooks/common';
import { uploadFile } from '../services/image.service';

type Props = {
	primaryPath: string;
	secondaryPath: string;
	onSuccess?: (response: any) => void;
	icon: string;
}

const UploadImage: React.FC<Props> = ({
	primaryPath,
	secondaryPath,
	icon,
	onSuccess
}) => {

	const [onError, setOnError] = useState<string>("");

	async function uploadImage() {
		try {
			const base64Image = await takePicture();
			if (base64Image) {
				const imageFile = await dataUrlToFile(base64Image);
				const uploadResponse = await uploadFile(primaryPath, secondaryPath, imageFile);
				const responseBody = await uploadResponse.json();
				if(onSuccess) onSuccess(responseBody);
			}
		} catch (err) {
			console.log(err);
			setOnError('Error occured while uploading image');
		}
	}

	return (
		<div>
			<IonIcon icon={icon} slot="end" onClick={e => uploadImage()} />

            <IonToast
                isOpen={!!onError}
                onDidDismiss={() => setOnError('')}
                message={onError}
                duration={2000}
                color="danger"
            />
		</div>
	)
}

export default UploadImage