import React from 'react';
import { IonCard, IonItem, IonLabel } from '@ionic/react';
import useNavigation from '../hooks/useNavigation';
import { makeCall } from '../hooks/common';

interface IProps {
    referral: any;
}

const ReferralCard: React.FC<IProps> = (props) => {
    const navigateTo = useNavigation();

    return (
        <>
            <IonCard onClick={() => navigateTo('/tabs/referral', { referralId: props.referral.id })}>
                <IonItem>
                    <IonLabel>
                        <h2>{props.referral.name}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Phone Number: <a onClick={e => makeCall(e, props.referral.mobile)}>{props.referral.mobile}</a></h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Referred By: {props.referral?.Referrer?.name || props.referral?.referredBy}</h2>
                    </IonLabel>
                </IonItem>
            </IonCard>
        </>
    );
};

export default ReferralCard;
