import { IonSkeletonText } from '@ionic/react';

const LoadingSkeleton: React.FC = () => {

    return (
        <>
            {[...Array(5)].map((item, index) => (
                <div className="ion-padding custom-skeleton" key={index}>
                    <IonSkeletonText animated style={{ width: '60%' }} />
                    <IonSkeletonText animated />
                    <IonSkeletonText animated style={{ width: '88%' }} />
                    <IonSkeletonText animated style={{ width: '70%' }} />
                    <IonSkeletonText animated style={{ width: '60%' }} />
                </div>
            ))}
        </>
    );
};

export default LoadingSkeleton;
