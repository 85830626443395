import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonSearchbar,
  IonFab,
  IonFabButton,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonToggle,
  IonModal,
  IonInput,
  IonButton,
} from "@ionic/react";
import {
  add,
  chevronDownCircleOutline,
  funnelOutline,
  search,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import PlotFilter from "../components/PlotFilter";
import Plots from "../components/Plots";
import { doReload } from "../hooks/common";
import useNavigation from "../hooks/useNavigation";
import { GeolocationService } from "../services/geolocation.service";
import {
  getNearestPlots,
  getPlots,
  getAiFilteredPlot,
} from "../services/plot.service";
// debounce hook
import useDebounce from "../hooks/useDebounce";
//css
import "./PlotList.css";
//custom search box
import CustomSearchBox from "../components/CustomSearchBox/CustomSearchBox";
import { useLocation } from "react-router";
import TestDevice from "./TestDevice";

interface ContainerProps {
  readOnlyView: boolean;
}

const PlotList: React.FC<ContainerProps> = (props) => {
  const [searchText, setSearchText] = useState("");
  const [filterData, setFilterData] = useState<any>();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filteredPlotIds, setFilteredPlotIds] = useState<string[]>([]);
  const [disableInfiniteScroll, setDisableInfiniteScroll] =
    useState<boolean>(false);
  const [showNearestPlots, setShowNearestPlots] = useState<boolean>(false);
  const [cachedPlots, setCachedPlots] = useState([] as any[]);
  const [skip, setSkip] = useState<number>(20);
  const [aisearchText, setAiSearchText] = useState("");
  const [showAiSearch, setShowAiSearch] = useState<boolean>(false);
  const navigateTo = useNavigation();
  const location = useLocation();

  const [plots, setPlots] = useState([] as any[]);
  const debounceSearchText = useDebounce(searchText, 1000);

  useEffect(() => {
    let mounted = true;
    setSkip(20);
    setDisableInfiniteScroll(false);
    fetchPlots(mounted);
    return () => {
      mounted = false;
    };
  }, [debounceSearchText, filteredPlotIds]);

  useEffect(() => {
    if ((location.state as any)?.refreshList || false) {
      fetchPlots(true);
    }
  }, [location]);

  function fetchPlots(mounted: boolean) {
    getPlots(debounceSearchText, filteredPlotIds).then((items) => {
      if (mounted) {
        setPlots(items);
      }
    });
  }
  async function fetchNext($event: CustomEvent<void>) {
    setSkip(skip + 20);
    getPlots(debounceSearchText, filteredPlotIds, skip).then((items) => {
      if (items?.length > 0) {
        setPlots([...plots, ...items]);
        setDisableInfiniteScroll(items.length < 20);
      } else {
        setDisableInfiniteScroll(true);
      }
      ($event.target as HTMLIonInfiniteScrollElement).complete();
    });
  }

  function fetchNearestPlots() {
    const geoService = new GeolocationService();
    geoService
      .getCurrentPosition()
      .then((geolocation) => {
        setNearestPlots(geolocation?.coords);
      })
      .catch((err) => {
        setNearestPlots(null);
      });
  }

  function setNearestPlots(geolocation: any) {
    getNearestPlots(geolocation).then((items) => {
      if (items.error) {
        setPlots([]);
      } else {
        setPlots(items);
      }
    });
  }

  function sortPlotsByLocation(e: CustomEvent) {
    const checked = e.detail.checked;
    setShowNearestPlots(checked);
    if (checked) {
      setCachedPlots(plots.slice());
      fetchNearestPlots();
    } else {
      setPlots(cachedPlots);
      setCachedPlots([]);
    }
  }

  function performSearch() {
    setDisableInfiniteScroll(showAiSearch)
    getAiFilteredPlot(aisearchText).then((items) => {
      if (items?.length > 0) {
        const plotIds = items?.map((plot: any) => plot?.payload?.plot_id);
        getPlots("", plotIds).then((plots) => {
          setPlots(plots);
        });
      } else {
        setPlots([]);
      }
    });
  }

  useEffect(() => {
    let mounted = true;
    if (!showAiSearch) {
      setSkip(20);
      setDisableInfiniteScroll(showAiSearch)
      fetchPlots(mounted);
    } 
    return () => {
      mounted = false;
    };
  }, [showAiSearch]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Plots</IonTitle>
        </IonToolbar>

        <IonItem>
          <IonLabel>Try AI Search</IonLabel>
          <IonToggle
            id="aiSearch"
            name="aiSearchToggle"
            checked={showAiSearch}
            onIonChange={() => {
              setSearchText("");
              setAiSearchText("");
              setShowAiSearch(!showAiSearch);

            }}
          />
        </IonItem>
        <IonItem lines="none">
          <div className="searchBarContainer">
            <div className="sbContainer">
              <CustomSearchBox
              searchText={showAiSearch ? aisearchText : searchText}
                setSearchText={showAiSearch ? setAiSearchText : setSearchText}
              />
              {showAiSearch && (
              <IonButton size="small" onClick={performSearch}>
              <IonIcon
                icon={search}
                slot="icon-only"
                size="small"
              ></IonIcon>
              </IonButton>
              )}
            </div>
            {!showAiSearch && (
            <div className="iconContainer">
              <IonIcon
                icon={funnelOutline}
                slot="end"
                onClick={() => setShowFilterModal(true)
                }
              ></IonIcon>
            </div>
            )}
          </div>
        </IonItem>
        {!props.readOnlyView && (
          <IonItem>
            <IonLabel>Show Plots near me</IonLabel>
            <IonToggle
              id="nearestPlotsToggle"
              name="nearestPlotsToggle"
              checked={showNearestPlots}
              onIonChange={sortPlotsByLocation}
            />
          </IonItem>
        )}
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doReload}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing..."
          ></IonRefresherContent>
        </IonRefresher>
        <Plots plots={plots} />
        <IonInfiniteScroll
          threshold="100px"
          disabled={disableInfiniteScroll}
          onIonInfinite={(e: CustomEvent<void>) => fetchNext(e)}
        >
          <IonInfiniteScrollContent loadingText="Loading more..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        {!props.readOnlyView && (
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton>
              <IonIcon
                icon={add}
                onClick={() => navigateTo("/tabs/plot", { plotId: null })}
              />
            </IonFabButton>
          </IonFab>
        )}
      </IonContent>

      <IonModal isOpen={showFilterModal} cssClass="my-custom-class">
        <PlotFilter
          filter={filterData}
          filterChangeHandler={setFilterData}
          onDoneHandler={setFilteredPlotIds}
          onCloseHandler={setShowFilterModal}
        ></PlotFilter>
      </IonModal>
    </IonPage>
  );
};

export default PlotList;
