import { getData, postData } from "./common.service";

export async function getInteractions(text: string = '', showAutoGenerated: boolean, skip = 0) {
    const qs: any = {
        where: {
            and: [
                { autoGenerated: showAutoGenerated },
                {
                    or:
                        [
                            { farmUserId: { like: `.*${text}.*`, options: 'i' } },
                            { supportUserId: { like: `.*${text}.*`, options: 'i' } }
                        ]
                }
            ]
        },
        include: [{
            relation: 'farmUser',
            scope: {
                fields: ['farmUserId', 'name', 'mobile'],
            }
        },
        {
            relation: 'farm',
            scope: {
                fields: ['farmId', 'name'],
            }
        },
        {
            relation: 'plot',
            scope: {
                fields: ['plotId', 'name'],
            }
        }],
        order: 'lastInteractionDate DESC',
        limit: 20,
        skip: skip
    };
    if (showAutoGenerated) {
        qs.where.and.push({ ignored: false });
    }
    return getData('/interactions?filter=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getInteraction(interactionId: string) {
    return getData(`/interactions/${interactionId}`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getInteractionConfig() {
    return getData('/interactions/config', {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function addInteraction(data: any) {
    return postData('/interactions', {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    })
}

export async function updateInteraction(interactionId: string, data: any) {
    return postData(`/interactions/${interactionId}`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: data,
        usePatchMethod: true
    })
}

export async function addConversation(interactionId: string, data: any) {
    return postData(`/interactions/${interactionId}/conversation`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    })
}