import { getData } from "./common.service";

export async function getReferrals(text: string = '', skip = 0) {
    const qs = {
        where: {
            or:
                [
                    { name: { like: `.*${text}.*`, options: 'i' } },
                    { mobile: { like: `.*${text}.*`, options: 'i' } },
                    { referredBy: { like: `.*${text}.*`, options: 'i' } },
                ]
        },
        include: [
            "Referrer"
        ],
        limit: 20,
        skip: skip,
        order: 'created_date DESC'
    };
    return getData('/user-referrals?filter=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getReferral(referralId: string) {
    return getData(`/user-referrals/${referralId}`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}