import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IonAlert, IonButton, IonCard, IonChip, IonCol, IonDatetime, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonPicker, IonRow, IonSelect, IonSelectOption, IonTextarea, IonToast } from '@ionic/react';
import { getFarmIdsOfUser, getFarmUserIds, getPlotIdsOfFarm, getFarmUserIdsPaginated } from '../services/farm.service';
import { useLocation } from 'react-router-dom';
import { handleErrors } from '../services/common.service';
import SelectItem from './SelectItem';
import { addConversation, addInteraction, getInteraction, getInteractionConfig, updateInteraction } from '../services/interaction.service';
import { useRecoilValue } from 'recoil';
import { userProfileState } from '../services/state.service';
import { deleteObjectPropertyByValue } from '../hooks/common';
import useNavigation from '../hooks/useNavigation';
import UserList from '../pages/UserList';

const FarmPicker = {
    name: "FarmId",
    options: [] as any[]
};

const setFarmPicker = (farmIds: any[]) => {
    FarmPicker.options = [];
    farmIds.forEach(item => {
        FarmPicker.options.push({
            text: item.farmId,
            value: item.farmId
        })
    });
};

const PlotPicker = {
    name: "PlotId",
    options: [] as any[]
};

const setPlotPicker = (plotIds: any[]) => {
    PlotPicker.options = [];
    plotIds.forEach(item => {
        PlotPicker.options.push({
            text: item.plotId,
            value: item.plotId
        })
    });
};

const CreateInteraction = () => {
    const navigateTo = useNavigation();
    const location = useLocation();
    const userProfile: any = useRecoilValue(userProfileState);
    const [interaction, setInteraction] = useState();
    const [previousConversations, setPreviousConversations] = useState([] as any[]);

    const formik = useFormik({
        initialValues: {
            edit: false,
            farmUserId: '',
            supportUserId: '',
            farmId: '',
            plotId: '',
            lastInteractionDate: '',
            autoGenerated: false,
            reason: '',
            ignored: false,
            conversation: {
                fyllo: '',
                farmer: '',
                mode: '',
                topics: [] as string[],
                ratings: {
                    irrigation: 0,
                    diseasePest: 0,
                    fertigation: 0,
                    weather: 0
                },
                date: ''
            }
        },
        validationSchema: Yup.object({
            farmUserId: Yup.string().trim().required('Required'),
            supportUserId: Yup.string().trim().required('Required'),
            conversation: Yup.object().shape({
                date: Yup.string().trim().required('Required'),
                mode: Yup.string().trim().required('Required'),
                topics: Yup.array().min(1, 'Minimum 1 topic is required')
            })
        }),
        onSubmit: values => {
            setShowConfirmation(true);
        },
    });

    function setValues(data: any) {
        if (data) {
            formik.setValues({
                edit: true,
                farmUserId: data.farmUserId,
                farmId: data.farmId || '',
                plotId: data.plotId || '',
                supportUserId: data.supportUser?.name || data.supportUserId || userProfile.name,
                lastInteractionDate: data.lastInteractionDate || '',
                autoGenerated: data.autoGenerated || false,
                reason: data.reason || '',
                ignored: data.ignored || false,
                conversation: {
                    fyllo: '',
                    farmer: '',
                    mode: '',
                    topics: [] as string[],
                    ratings: {
                        irrigation: 0,
                        diseasePest: 0,
                        fertigation: 0,
                        weather: 0
                    },
                    date: data.autoGenerated ? data.lastInteractionDate : ''
                }
            });
            const sortedConversations = data?.conversations?.sort((a: any, b: any) => {
                return new Date(b.date).getTime() - new Date(a.date).getTime()
            }) || [];
            setPreviousConversations(sortedConversations);
        }
        setInteraction(data);
    }

    const [farmPickerIsOpen, setFarmPickerIsOpen] = useState(false);
    useEffect(() => {
        let mounted = true;
        if (formik.values.farmUserId) {
            getFarmIdsOfUser(formik.values.farmUserId)
                .then(items => {
                    if (mounted) {
                        setFarmPicker(items)
                    }
                })
        }
        return () => { mounted = false };
    }, [formik.values.farmUserId])

    const [plotPickerIsOpen, setPlotPickerIsOpen] = useState(false);
    useEffect(() => {
        let mounted = true;
        if (formik.values.farmId) {
            getPlotIdsOfFarm(formik.values.farmId)
                .then(items => {
                    if (mounted) {
                        setPlotPicker(items)
                    }
                })
        }
        return () => { mounted = false };
    }, [formik.values.farmId])

    useEffect(() => {
        let mounted = true;
        const id: any = (location.state as any)?.interactionId ?? null;
        if (id) {
            getInteraction(id)
                .then(data => {
                    if (mounted) {
                        setValues(data);
                    }
                });
        } else {
            formik.setFieldValue('supportUserId', userProfile.name);
        }
        return () => { mounted = false };
    }, [location]);

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [onSuccess, setOnSuccess] = useState('');
    const [onError, setOnError] = useState('');

    const [showTopicsModal, setShowTopicsModal] = useState(false);

    function addTopic(item: any) {
        formik.values.conversation.topics.push(item.topicId);
        setShowTopicsModal(false);
    }

    function removeTopic(itemIndex: number) {
        formik.values.conversation.topics.splice(itemIndex, 1);
        formik.setFieldValue('conversation.topics', [...formik.values.conversation.topics]);
    }

    function updateRating(key: string, value: number) {
        formik.setFieldValue(`conversation.ratings[${key}]`, (formik.values.conversation.ratings as any)[key] + value);
    }

    const [topics, setTopics] = useState([] as any[]);
    useEffect(() => {
        let mounted = true;
        getInteractionConfig()
            .then(items => {
                if (mounted) {
                    setTopics(items.map((item: string) => {
                        return {
                            'topicId': item
                        }
                    }));
                }
            })
        return () => { mounted = false };
    }, []);

    const [showSelectUserModal, setShowSelectUserModal] = useState(false);

    function setFarmUserId(item: any) {
        formik.setFieldValue('farmUserId', item.farmUserId);
        setShowSelectUserModal(false);
    }

    const saveInteraction = async (values: any) => {
        try {
            delete values.edit;
            delete values.id;
            values.supportUserId = userProfile.username || values.supportUserId;
            if (!interaction) {
                const data = {
                    farmUserId: values.farmUserId,
                    supportUserId: values.supportUserId,
                    farmId: values.farmId,
                    plotId: values.plotId,
                    lastInteractionDate: values.conversation.date,
                    autoGenerated: false,
                    conversations: [
                        {
                            date: values.conversation.date,
                            mode: values.conversation.mode,
                            topics: values.conversation.topics,
                            fyllo: values.conversation.fyllo,
                            farmer: values.conversation.farmer,
                            ratings: deleteObjectPropertyByValue(values.conversation.ratings, 0)
                        }
                    ]
                };
                const response = await addInteraction(data).then(handleErrors);
                setOnSuccess('Interaction details added');
            } else {
                const data: any = {
                    date: values.conversation.date,
                    mode: values.conversation.mode,
                    topics: values.conversation.topics,
                    fyllo: values.conversation.fyllo,
                    farmer: values.conversation.farmer,
                    ratings: deleteObjectPropertyByValue(values.conversation.ratings, 0)
                };
                const updatedInteractionData: any = {
                    lastInteractionDate: values.conversation.date,
                    supportUserId: values.supportUserId
                };
                if (values.autoGenerated) {
                    updatedInteractionData.autoGenerated = false;
                    data.reason = (interaction as any).reason;
                }
                const response = await addConversation((interaction as any).id, data).then(handleErrors);
                const updatedInteraction = await updateInteraction((interaction as any).id, updatedInteractionData).then(handleErrors);
                setOnSuccess('Interaction details updated');
            }
            setTimeout(() => {
                navigateTo('/interactions', {});
                window.location.reload();
            }, 1000);
        } catch (err: any) {
            setOnError(err);
        }
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <IonList>
                    <IonItem>
                        <IonLabel position="stacked">Farm User Id</IonLabel>
                        <IonInput readonly id="farmUserId" name="farmUserId" value={formik.values.farmUserId} placeholder="Select Farm User Id" onClick={e => setShowSelectUserModal(true)}></IonInput>
                    </IonItem>
                    {formik.touched.farmUserId && formik.errors.farmUserId ? (<div className="errorMsg">{formik.errors.farmUserId}</div>) : null}

                    <IonItem>
                        <IonLabel position="stacked">Farm Id</IonLabel>
                        <IonInput readonly disabled={!formik.values.farmUserId} id="farmId" name="farmId" value={formik.values.farmId} placeholder="Select Farm Id" onClick={e => setFarmPickerIsOpen(true)}></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Plot Id</IonLabel>
                        <IonInput readonly disabled={!formik.values.farmId} id="plotId" name="plotId" value={formik.values.plotId} placeholder="Select Plot Id" onClick={e => setPlotPickerIsOpen(true)}></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Date</IonLabel>
                        <IonDatetime id="conversation.date" name="conversation.date" displayFormat="DD/MM/YYYY" placeholder="Select Date" value={formik.values.conversation.date} onIonChange={formik.handleChange}></IonDatetime>
                    </IonItem>
                    {formik.touched.conversation && formik.touched.conversation.date && formik.errors.conversation && (formik.errors.conversation as any).date ? (<div className="errorMsg">{(formik.errors.conversation as any).date}</div>) : null}

                    {/* <IonItem>
                        <IonLabel position="stacked">Mode</IonLabel>
                        <IonSelect id="conversation.mode" name="conversation.mode" value={formik.values.conversation.mode} placeholder="Select Mode" onIonChange={formik.handleChange}>
                            <IonSelectOption value="phone">Phone Call</IonSelectOption>
                            <IonSelectOption value="video">Video Call</IonSelectOption>
                            <IonSelectOption value="field">Field Visit</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    {formik.touched.conversation && formik.touched.conversation.mode && formik.errors.conversation && (formik.errors.conversation as any).mode ? (<div className="errorMsg">{(formik.errors.conversation as any).mode}</div>) : null} */}

                    {/* <IonItem lines='none'>
                        <IonLabel>Topic(s)</IonLabel>
                        <IonButton fill="clear" size="small" slot="end" onClick={e => setShowTopicsModal(true)}><IonIcon slot="icon-only" icon={addCircle} /></IonButton>
                    </IonItem>
                    <IonItem lines='none'>
                        <IonGrid>
                            <IonRow>
                                {formik.values.conversation.topics?.length > 0 && formik.values.conversation.topics.map((item, index) =>
                                    <IonCol key={index}>
                                        <IonChip outline={true} color="primary">
                                            <IonLabel>{item}</IonLabel>
                                            <IonIcon icon={closeCircle} onClick={e => removeTopic(index)} />
                                        </IonChip>
                                    </IonCol>
                                )}
                                {formik.values.conversation.topics?.length === 0 &&
                                    <IonCol><IonLabel class="secondary-text">No topics assigned</IonLabel></IonCol>}
                            </IonRow>
                        </IonGrid>
                    </IonItem>
                    {formik.touched.conversation && formik.touched.conversation.topics && formik.errors.conversation && (formik.errors.conversation as any).topics ? (<div className="errorMsg">{(formik.errors.conversation as any).topics}</div>) : null} */}

                    <IonItem>
                        <IonLabel position="stacked">Farmer</IonLabel>
                        <IonTextarea id="conversation.farmer" name="conversation.farmer" value={formik.values.conversation.farmer} placeholder="Farmer's feedback/questions" onIonChange={formik.handleChange}></IonTextarea>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Fyllo</IonLabel>
                        <IonTextarea id="conversation.fyllo" name="conversation.fyllo" value={formik.values.conversation.fyllo} placeholder="Fyllo's suggestions/response" onIonChange={formik.handleChange}></IonTextarea>
                    </IonItem>

                    {/* <IonItem lines="none">
                        <IonLabel>Ratings</IonLabel>
                    </IonItem>

                    <IonItem lines="none">
                        <IonGrid fixed={true} class="ion-no-padding">
                            {Object.keys(formik.values.conversation.ratings).map((key, index) => {
                                return <React.Fragment key={index}>
                                    <IonRow class="ion-no-padding">
                                        <IonCol size="6" class="ion-no-padding">
                                            <IonLabel position="stacked">{key.toUpperCase()}</IonLabel>
                                        </IonCol>
                                        <IonCol size="2" class="ion-no-padding ion-text-center">
                                            <IonButton fill="clear" size="small" disabled={(formik.values.conversation.ratings as any)[key] <= 0} onClick={e => updateRating(key, -1)}><IonIcon slot="icon-only" icon={removeCircle} /></IonButton>
                                        </IonCol>
                                        <IonCol size="2" class="ion-no-padding ion-text-center">
                                            <IonButton fill="clear" size="small">{(formik.values.conversation.ratings as any)[key]}</IonButton>
                                        </IonCol>
                                        <IonCol size="2" class="ion-no-padding ion-text-center">
                                            <IonButton fill="clear" size="small" disabled={(formik.values.conversation.ratings as any)[key] >= 5} onClick={e => updateRating(key, 1)}><IonIcon slot="icon-only" icon={addCircle} /></IonButton>
                                        </IonCol>
                                    </IonRow>
                                </React.Fragment>
                            })}
                        </IonGrid>
                    </IonItem> */}

                    <IonItem>
                        <IonLabel position="stacked">Fyllo User Id</IonLabel>
                        <IonInput readonly id="supportUserId" name="supportUserId" value={formik.values.supportUserId} placeholder="Select Support User Id"></IonInput>
                    </IonItem>
                    {formik.touched.supportUserId && formik.errors.supportUserId ? (<div className="errorMsg">{formik.errors.supportUserId}</div>) : null}

                </IonList>

                <IonButton expand="block" type="submit">Add Conversation</IonButton>

                {previousConversations.length > 0 && <IonItem lines="none">
                    <IonLabel>Previous Conversation(s)</IonLabel>
                </IonItem>}

                {previousConversations.length > 0 && previousConversations.map((item, index) => {
                    return <IonCard key={index}>
                        <IonItem>
                            <IonLabel>
                                {new Intl.DateTimeFormat('en-GB', {
                                    month: 'short',
                                    day: '2-digit',
                                    year: 'numeric',
                                }).format(new Date(item.date))}
                            </IonLabel>
                        </IonItem>

                        <IonItem lines='none'>
                            <IonGrid>
                                <IonRow>
                                    {item.topics?.length > 0 && item.topics.map((item: string, index: number) =>
                                        <IonCol key={index}>
                                            <IonChip outline={true} color="primary">
                                                <IonLabel>{item}</IonLabel>
                                            </IonChip>
                                        </IonCol>
                                    )}
                                    {item.topics?.length === 0 &&
                                        <IonCol><IonLabel class="secondary-text">No topics assigned</IonLabel></IonCol>}
                                </IonRow>
                            </IonGrid>
                        </IonItem>

                        {item.reason && <IonItem lines='none'>
                            <IonLabel position="stacked">
                                Reason
                            </IonLabel>
                            {item.reason}
                        </IonItem>
                        }

                        <IonItem>
                            <IonLabel position="stacked">Farmer</IonLabel>
                            <IonTextarea readonly id="farmer" name="farmer" value={item.farmer} placeholder="Farmer's feedback/questions"></IonTextarea>
                        </IonItem>

                        <IonItem>
                            <IonLabel position="stacked">Fyllo</IonLabel>
                            <IonTextarea readonly id="fyllo" name="fyllo" value={item.fyllo} placeholder="Fyllo's suggestions/response"></IonTextarea>
                        </IonItem>
                    </IonCard>
                })}
            </form>

            <IonPicker
                isOpen={farmPickerIsOpen}
                columns={[FarmPicker]}
                buttons={[
                    {
                        text: "Cancel",
                        role: "cancel",
                        handler: value => {
                            setFarmPickerIsOpen(false);
                        }
                    },
                    {
                        text: "Confirm",
                        handler: value => {
                            formik.values.farmId = value.FarmId.value;
                            formik.values.plotId = '';
                            setFarmPickerIsOpen(false);
                        }
                    }
                ]}
            ></IonPicker>

            <IonPicker
                isOpen={plotPickerIsOpen}
                columns={[PlotPicker]}
                buttons={[
                    {
                        text: "Cancel",
                        role: "cancel",
                        handler: value => {
                            setPlotPickerIsOpen(false);
                        }
                    },
                    {
                        text: "Confirm",
                        handler: value => {
                            formik.values.plotId = value.PlotId.value;
                            setPlotPickerIsOpen(false);
                        }
                    }
                ]}
            ></IonPicker>

            {showSelectUserModal && <IonModal isOpen={showSelectUserModal} >
            <UserList
                    selectedValue={formik.values.farmUserId}
                    onClickHandler={setFarmUserId}
                    onClose={setShowSelectUserModal}
                />
            </IonModal>}

            {showTopicsModal && <IonModal isOpen={showTopicsModal} >
                <SelectItem
                    label='Select Topic'
                    items={topics && topics.filter(item => item && !formik.values.conversation.topics?.includes(item.topicId))}
                    labelKey='topicId'
                    valueKey='topicId'
                    setItem={addTopic}
                    selectedValue=''
                    onClose={setShowTopicsModal} />
            </IonModal>}

            <IonAlert
                isOpen={showConfirmation}
                onDidDismiss={() => setShowConfirmation(false)}
                header={'Confirm!'}
                message={'Please make sure that you have verified all the details. Add Conversation?'}
                buttons={[
                    {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary'
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            saveInteraction(formik.values);
                        }
                    }
                ]}
            />

            <IonToast
                isOpen={!!onSuccess}
                onDidDismiss={() => setOnSuccess('')}
                message={onSuccess}
                duration={2000}
                color="success"
            />

            <IonToast
                isOpen={!!onError}
                onDidDismiss={() => setOnError('')}
                message={onError}
                duration={2000}
                color="danger"
            />
        </>
    );
};

export default CreateInteraction;