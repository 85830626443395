import React from 'react';
import useNavigation from '../hooks/useNavigation';
import PlotCard from './PlotCard';

interface IProps {
    plots: any[];
    headerIconOnClickHandler?: Function;
}

const Plots: React.FC<IProps> = (props) => {
    const navigateTo = useNavigation();

    function showSensorData(event: Event, plot: any) {
        event.stopPropagation();
        navigateTo('/sensor-data', { plot: plot, sinceHours: 24 });
    }

    return (
        <>
            {props.plots && props.plots.map((plot, index) => <PlotCard headerIconOnClickHandler={props.headerIconOnClickHandler || showSensorData} plot={plot} key={index} />)}
        </>
    );
};

export default Plots;