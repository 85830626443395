import React from 'react';
import FarmCard from './FarmCard';

interface IProps {
    farms: any[];
}

const Farms: React.FC<IProps> = (props) => {
    return (
        <>
            {props.farms && props.farms.map((farm, index) => <FarmCard farm={farm} key={index} />)}
        </>
    );
};

export default Farms;