import moment from "moment";
import { deleteData, getData, postData } from "./common.service"

export async function getPlans() {
    return getData('/plans', {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getRecharges(planId: string) {
    return getData(`/plans/${planId}/recharges`, {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getPlotPlan(plotId: string) {
    return getData(`/plot-plans/${plotId}`, {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function updatePlotPlan(plotPlanData: any, currentPlotPlan: any) {
    const url = currentPlotPlan ? `/plot-plans/${currentPlotPlan.plotId}` : '/plot-plans';
    return postData(url, {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: plotPlanData,
        usePutMethod: currentPlotPlan ? true : false
    })
}

export async function createOrder(userId: string, items: any[]) {
    return postData(`/payments/user/${userId}`, {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: {
            items: items
        }
    })
}

export async function authorizePayment(paymentId: string) {
    return getData(`/payments/${paymentId}/authorize`, {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true
    })
}

export async function getVouchers(plotId: string) {
    const qs = {
        where: {
            plotId: plotId
        },
        include: ['recharge'],
        order: 'createdAt DESC',
        limit: 5
    };
    return getData('/vouchers?filter=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function deleteVoucher(voucherId: string) {
    return deleteData(`/vouchers/${voucherId}`, {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true
    })
}

export async function getPendingPayments(skip = 0) {
    const qs = {
        where: {
            status: "INITIATED",
            createdAt: { gt: moment().subtract(1, 'day') }
        },
        order: 'createdAt DESC',
        limit: 20,
        skip: skip
    };
    return getData('/payments?filter=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true
    }).then(data => data.json())
}

export async function getVoucherList(text: string = '', skip = 0) {
    const qs: any = {
        where: {
            or:
                [
                    { plotId: { like: `.*${text}.*`, options: 'i' } },
                    { userId: { like: `.*${text}.*`, options: 'i' } },
                    { status: { like: `.*${text}.*`, options: 'i' } }
                ]
        },
        include: ['recharge'],
        order: 'createdAt DESC',
        limit: 20,
        skip: skip
    };  
    return getData('/vouchers?filter=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true
    }).then(data => data.json())
}

export async function exportVoucherList(startDate: string,endDate: string) {
    const qs: any = {
        where: {
            and:
                [
                    { createdAt: { gte: new Date(startDate).setHours(0, 0, 0, 0) } },
                    { createdAt: { lte: new Date(endDate).setHours(0, 0, 0, 0) } },
                ]
        },
        include: ['recharge','payment'],
        order: 'createdAt DESC',
    };  
    return getData('/vouchers?filter=' + JSON.stringify(qs) + `&restype=csv`, {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true
    }).then(data => data.text())
}
