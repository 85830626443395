import React from 'react';
import { IonCard, IonIcon, IonItem, IonLabel } from '@ionic/react';
import useNavigation from '../hooks/useNavigation';
import { handLeft, thumbsDown, thumbsUp, thumbsUpOutline } from 'ionicons/icons';
import { makeCall } from '../hooks/common';

interface IProps {
    meeting: any;
}

const MeetingCard: React.FC<IProps> = (props) => {
    const navigateTo = useNavigation();

    return (
        <>
            <IonCard onClick={() => navigateTo('/tabs/meeting', { meetingId: props.meeting.id })}>
                <IonItem>
                    <IonLabel>
                        {new Intl.DateTimeFormat('en-GB', {
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric',
                        }).format(new Date(props.meeting.date))}
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Meeting Name: {props.meeting.contactName}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Location: {props.meeting.location} {props.meeting.taluka} {props.meeting.district}</h2>
                    </IonLabel>
                </IonItem>
                {props.meeting.followUpDate && <IonItem lines='none'>
                    <IonLabel>
                        <h2>Follow-up Date: {new Intl.DateTimeFormat('en-GB', {
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric',
                        }).format(new Date(props.meeting.followUpDate))}</h2>
                    </IonLabel>
                </IonItem>}
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Serviced By: {props.meeting.servicer?.name || props.meeting.servicedBy}</h2>
                    </IonLabel>
                </IonItem>
            </IonCard>
        </>
    );
};

export default MeetingCard;
