import { IonButton, IonLoading, IonToast } from '@ionic/react';
import {
    Autocomplete,
    DrawingManager,
    GoogleMap,
    InfoWindow,
    Marker,
    Polygon,
    useJsApiLoader,
} from '@react-google-maps/api';
import { clone, uniqueId } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import './GoogleMap.css'

const googleMapLibraries: any[] = ['places', 'drawing', 'geometry'];
const mapKey = process.env.REACT_APP_GOOGLE_API_KEY;
const GOOGLE_SHAPE_OPTIONS = {
    fillColor: "white",
    fillOpacity: 0.5,
    strokeWeight: 0,
    clickable: true,
    zIndex: 1,
};

type Coordinates = [number, number];

type TLocation = {
    lat: number;
    lng: number;
};

type CoordinatesArray = Array<Array<Coordinates>>;

const defaultZoom = 18;
type Props = {
    lat?: number;
    lng?: number;
    zoom?: number;
    plotId?: string;
    onPlotsLocationChange: Function;
    onPlotsGeoBoundaryChange: Function;
    geoBoundary?: Array<[number, number]>;
}

const Map: React.FC<Props> = (props) => {

    const { onPlotsLocationChange, onPlotsGeoBoundaryChange, geoBoundary } = props;

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: mapKey!,
        libraries: googleMapLibraries,
        nonce: 'google-map-script'
    });

    const mapRef = useRef<any>(null);
    let map = mapRef.current;
    const [center, setCenter] = useState({ lat: 21.7679, lng: 78.8718 });
    const [movedCenter, setMovedCenter] = useState<TLocation | null>(null);
    const [zoom, setZoom] = useState(defaultZoom);

    const [autoComplete, setAutoComplete] = useState<any>();

    // maps
    const drawingManagerRef: any = useRef(null);
    const selectedShapes: any = useRef();

    const [selectedShape, setSelectedShape] = useState<any>({}); // has every shape drawn
    const [currentShape, setCurrentShape] = useState<any>(); // used for deleting the shape
    const [shapePath, setShapePath] = useState<CoordinatesArray>([]); // [[ [lng0, lat0], [lng1, lat1], [lng2, lat2], ... , [lng0, lat0] ]]

    // for info model (selected region/shapes)
    const [showoptionsInfoWindow, setShowoptionsInfoWindow] = useState<string>('');
    const [optionsInfoWindowLocation, setOpionsModalLocation] = useState<TLocation>();

    const [isMarkerCovered, setIsMarkerCovered] = useState<boolean>();

    // markers info window
    const [showMarkersInfoWindow, setShowMarkersInfoWindow] = useState<boolean>(false);
    const [isMarkerMoveable, setIsMarkerMoveable] = useState<boolean>(true);

    // toast alerts
    const [onSuccess, setOnSuccess] = useState<string>('');
    const [onError, setOnError] = useState<string>('');

    // loader for location updation
    const [updatingLocation, setUpdatingLocation] = useState<boolean>(false);

    useEffect(() => {
        const lat = (props)?.lat;
        const lng = (props)?.lng;
        const zoom = (props)?.zoom;

        const isLatLngUndefined = lat === undefined || lng === undefined;
        const isLatLngZero = lat === 0 && lng === 0;
        if (!(isLatLngUndefined || isLatLngZero)) {
            setCenter({ lat, lng });
        }

        if (zoom !== undefined) {
            setZoom(zoom);
        }
    }, []);

    useEffect(() => {
        selectedShapes.current = selectedShape
        if (!!selectedShapes.current) {
            let insideMarkers: boolean = false;

            // just one shape in this case
            for (const shape in selectedShapes.current) {
                const marker = {
                    location: center
                }
                const currentInsideMarkers: any = isMarkerInsideShape(marker, shape)
                insideMarkers = currentInsideMarkers;
            }
            setIsMarkerCovered(insideMarkers);
        }
    }, [selectedShape]);


    /** 
     * ------------------------
     * Map related functions
     * ------------------------
     * */ 

    const onLoad = (map: any) => {
        mapRef.current = map;
        mapRef.current.setMapTypeId('hybrid');
    };

    const onUnmount = useCallback(() => {
        mapRef.current = null;
    }, []);

    /**
     * @function updates position of moved marker
     * is marker isn't moveable, does nothing
     * */ 
    const handleMapClick = (event: any) => {
        if (isMarkerMoveable) {
            const { latLng } = event;
            const clickedLat = Number(latLng.lat()).toFixed(6);
            const clickedLng = Number(latLng.lng()).toFixed(6);
            setMovedCenter({ lat: Number(clickedLat), lng: Number(clickedLng) });
        }
    }

    /**
     * ------------------------
     * Drawing Manager related functions
     * ------------------------
     * */ 

    const onShapeComplete = (shape: any, shapeObject: any, shapeId: string) => {
        clearSelection();
        deleteAllShapes();
        setIsMarkerMoveable(false);
        setCurrentShape(shapeObject);
        if (shape === 'polygon') {
            onPolygonComplete(shapeObject, shapeId)
            return;
        }
    };

    const onPolygonComplete = (shape: any, shapeId: string) => {
        const updatedShape = { ...selectedShapes.current }

        const shapePath = shape.getPath().getArray().map((path: any) => ([path.lng(), path.lat()]))
        shapePath.push(shapePath[0])
        const arrayShapePaths = [shapePath]

        setShapePath(arrayShapePaths)
        updatedShape[shapeId] = clone(shape);
        updatedShape[shapeId].type = 'polygon'
        setSelectedShape(updatedShape);
    }

    /**
     * @function checks if marker is inside drawn shape
     * uses google-maps methods to check if marker is inside drawn polygon
     * */ 
    const isMarkerInsideShape = (marker: any, shapeId: string) => {
        if (!selectedShapes.current) {
            return true;
        }

        const polygon = selectedShapes.current[shapeId]

        return window.google.maps.geometry.poly.containsLocation(
            marker.location,
            polygon
        );
    };

    /**
     * @function clears current shape
     * */ 
    const clearSelection = () => {
        if (currentShape) {
            currentShape.setEditable(false);
            setCurrentShape(null)
        }
    }

    /**
     * @function deletes provided shape
     * @param shapeId to delete
     * */ 
    const deleteSelectedShape = (currentShapeId: string) => {
        if (currentShape) {
            setShowoptionsInfoWindow('')
            const filtered = Object.keys(selectedShapes.current).filter((shapeId) => currentShapeId !== shapeId)

            const newSelectedShape: any = {}
            for (const shapeId in selectedShapes.current) {
                if (filtered.includes(shapeId)) {
                    newSelectedShape[shapeId] = selectedShapes.current[shapeId]
                }
            }
            setSelectedShape(newSelectedShape)

            currentShape.setMap(null);
        }
    }

    /**
     * @function deletes all drawn shapes
     * */ 
    const deleteAllShapes = () => {
        if (!!selectedShapes.current) {
            for (const shapeId in selectedShapes.current) {
                deleteSelectedShape(shapeId)
            }
        }
    }

    /**
     * ------------------------
     * InfoWindow related functions
     * ------------------------
     * */

    /**
     * @function opens info window for marker
     * */
    const handleMarkerClick = () => {
        setShowMarkersInfoWindow(true);
    }

    /**
     * @function opens info window for drawn shape
     * @param shapeId
     * calculates position for info window
     *  - iterates over the shape path
     *  - maintains array of latlngs for the path
     *  - avg of latlngs is position of info window
     * */ 
    const openOptionsWindow = (shapeId: string) => {
        const shapeObject = selectedShapes.current[shapeId]
        let center: TLocation;
        if (shapeObject.type === 'circle') {
            center = shapeObject.getCenter()
        } else {
            /* getting the center of a polygon by 
                    using the getPath method
                    then calculating the average of all the latitudes and longitudes of the polygon.
            */
            const path = shapeObject.getPath();
            const latlng = [];

            for (var i = 0; i < path.length; i++) {
                latlng.push({ lat: path.getAt(i).lat(), lng: path.getAt(i).lng() });
            }

            const avgLat = latlng.reduce((a, b) => a + b.lat, 0) / latlng.length;
            const avgLng = latlng.reduce((a, b) => a + b.lng, 0) / latlng.length;

            center = { lat: avgLat, lng: avgLng };
        }

        setOpionsModalLocation(center);
        setShowoptionsInfoWindow(shapeId)
    }

    const closeOptionsInfoWindow = () => {
        setShowoptionsInfoWindow('');
    }

    const closeMarkersInfoWindow = () => {
        setShowMarkersInfoWindow(false);
    }

    /**
     * ------------------------
     * AutoComplete(Search box) related functions
     * ------------------------
     * */

    const onAutocompleteLoad = async (autocomplete: any) => {
        setAutoComplete(autocomplete);
    }

    const onPlaceChanged = async () => {
        if (autoComplete) {
            const place = await autoComplete.getPlace();
            if (place) {
                const a = place.geometry.viewport.getNorthEast();
                const b = place.geometry.viewport.getSouthWest();
                const bounds = new window.google.maps.LatLngBounds(b, a);
                map.fitBounds(bounds);
            }
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    }

    /**
     * Service functions
     * business logic is provided by the parent component
     * */ 

    /**
     * @function adds plot's geo-boundary
     * */ 
    const addGeoBoundary = async () => {
        let proceed = !!isMarkerCovered;

        if (!isMarkerCovered) {
            proceed = window.confirm('No marker inside drawn region, do you want to continue?');
        }

        if (!proceed) {
            deleteAllShapes();
            return;
        }

        try {
            setUpdatingLocation(true);
            setIsMarkerMoveable(false);

            const payload = {
                geoBoundary: {
                    type: "Polygon",
                    coordinates: shapePath
                }
            }

            await onPlotsGeoBoundaryChange(payload); // business logic
            setOnSuccess('Geo-Boundary updated successfully');
        } catch (error) {
            setOnError('Failed to add Geo-Boundary, please try again!!');
        } finally {
            setUpdatingLocation(false);
        }
    }

    /**
     * @function updates plot's location
     * */
    const updateMarkerPosition = async () => {
        if (!isMarkerMoveable || !movedCenter) return;

        try {
            setUpdatingLocation(true);
            setIsMarkerMoveable(false);
            const payload = {
                location: movedCenter
            };

            await onPlotsLocationChange(payload); // business logic
            setCenter(movedCenter);
            setOnSuccess('Location updated successfully');
        } catch (error) {
            setOnError('Failed to update location, please try again!!');
        } finally {
            setUpdatingLocation(false);
        }
    }

    const cancelMarkerPositionUpdate = () => {
        setIsMarkerMoveable(false);
        setMovedCenter(null);
    }

    const moveMarker = () => {
        setIsMarkerMoveable(true);
        closeMarkersInfoWindow();
    }

    return (
        <div style={{ height: '100%', zIndex: 10, position: 'relative' }}>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        zIndex: 11
                    }}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    zoom={zoom}
                    center={center}
                    onClick={handleMapClick}
                >
                    <Marker draggable={isMarkerMoveable} onDragEnd={handleMapClick} onClick={handleMarkerClick} position={movedCenter ? movedCenter : center} />

                    {
                        (!!geoBoundary && geoBoundary.length > 2)&& <Polygon
                            path={geoBoundary.map((coordinate: any) => ({ lat: coordinate[1], lng: coordinate[0] }))}
                            options={{
                                strokeColor: 'orange',
                                strokeOpacity: 0.4,
                                strokeWeight: 0.5,
                                fillColor: 'orange',
                                fillOpacity: 0.6
                            }}
                        />
                    }

                    {/**
                     * Location Search box
                     */}
                    <Autocomplete
                        onLoad={onAutocompleteLoad}
                        onPlaceChanged={onPlaceChanged}
                    >
                        <div id='google-maps-autocomplete-container'>
                            <input
                                type="text"
                                placeholder="Search"
                                id='google-maps-autocomplete-input'
                            />
                        </div>
                    </Autocomplete>

                    {/** 
                     * @DrawingManager
                     * */}
                    <DrawingManager
                        ref={drawingManagerRef}
                        onLoad={(manager) => {
                            drawingManagerRef.current = manager
                            manager.addListener('click', () => { clearSelection() })
                        }}
                        onOverlayComplete={() => { drawingManagerRef.current.setDrawingMode(null) }}
                        onPolygonComplete={(polygon) => {
                            const shapeId = uniqueId('polygon-')
                            onShapeComplete('polygon', polygon, shapeId)
                            polygon.addListener('click', () => {
                                setCurrentShape(polygon)
                                openOptionsWindow(shapeId)
                            });
                        }}
                        options={{
                            drawingControl: true,
                            drawingControlOptions: {
                                position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
                                drawingModes: [
                                    window.google.maps.drawing.OverlayType.POLYGON,
                                ],
                            },
                            circleOptions: GOOGLE_SHAPE_OPTIONS,
                            polygonOptions: GOOGLE_SHAPE_OPTIONS,
                        }}
                    />

                    {/**
                     * @InfoWindow for drawn shape
                     * */}
                    {
                        (!!showoptionsInfoWindow && showoptionsInfoWindow.length > 0 && !isMarkerMoveable) && <InfoWindow 
                            position={optionsInfoWindowLocation}
                            onCloseClick={closeOptionsInfoWindow}
                        >
                            <>
                                <IonButton color='primary' expand='block' onClick={addGeoBoundary}> Add Geo Boundary </IonButton>
                                <IonButton color='danger' expand='block' onClick={deleteAllShapes}> Delete Shape </IonButton>
                            </>
                        </InfoWindow>
                    }


                    {/**
                     * @InfoWindow for marker
                     * */}
                    {
                        (!!showMarkersInfoWindow && !isMarkerMoveable) && <InfoWindow position={movedCenter ? movedCenter : center} options={{
                            pixelOffset: new window.google.maps.Size(0, -20),
                        }} onCloseClick={closeMarkersInfoWindow}>
                            <>
                                <IonButton color='primary' onClick={moveMarker}> Move Marker </IonButton>
                            </>
                        </InfoWindow>
                    }


                    {/**
                     * @button to save marker location
                     * */}
                    {
                        isMarkerMoveable && <div style={{
                            position: 'absolute',
                            bottom: 5
                        }} >
                            <IonButton size='small' color='danger' onClick={cancelMarkerPositionUpdate}> Cancel </IonButton>
                            <IonButton size='small' color='primary' onClick={updateMarkerPosition}> Update Location </IonButton>
                        </div>
                    }
                </GoogleMap>
            ) : (
                <div>loading...</div>
            )}

            <IonLoading isOpen={updatingLocation} />

            <IonToast
                isOpen={!!onSuccess}
                onDidDismiss={() => setOnSuccess('')}
                message={onSuccess}
                duration={2000}
                color="success"
            />

            <IonToast
                isOpen={!!onError}
                onDidDismiss={() => setOnError('')}
                message={onError}
                duration={2000}
                color="danger"
            />
        </div>
        
    );
};

export default Map;