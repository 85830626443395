import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonDatetime,
  IonButtons,
  IonToast,
} from "@ionic/react";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./PlotFilter";
import { exportVoucherList } from "../services/payment.service";

interface IProps {
  onCloseHandler: Function;
}

const VoucherExport: React.FC<IProps> = (props) => {
  const [data, setData] = useState<any>();
  const [onNoData, setOnNoData] = useState("");

  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
    },
    validationSchema: Yup.object({
        startDate: Yup.string()
        .trim()
        .required('Required'),
    endDate: Yup.string()
        .trim()
        .required('Required')
        .test('is-greater', 'End date cannot be before the start date', function(value) {
            const { startDate } = this.parent;
            if (!startDate || !value) return true;

            // Set start date to 00:00:00 and end date to 23:59:59 for proper comparison
            const start = new Date(startDate);
            start.setHours(0, 0, 0, 0);
            const end = new Date(value);
            end.setHours(23, 59, 59, 999);

            return end >= start;
        }),
    }),
    onSubmit: async values => {
        // Set start date to 00:00:00 and end date to 23:59:59
        const start = new Date(values.startDate);
        start.setHours(0, 0, 0, 0); // Set to midnight
    
        const end = new Date(values.endDate);
        end.setHours(23, 59, 59, 999); // Set to end of the day
    
        if (end < start) {
            alert('End date cannot be earlier than the start date');
            return;
        }
        const data = await exportVoucherList(start.toISOString(), end.toISOString());
        if (data) {
            setData(data);
        } else {
            setOnNoData('No data found');
        }
    },
    
  });

  function onClose() {
    props.onCloseHandler(false);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={(e) => onClose()}>Close</IonButton>
          </IonButtons>
          <IonTitle>Export</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <form onSubmit={formik.handleSubmit}>
          <IonList>
            <IonItem>
              <IonLabel position="stacked">Start Date</IonLabel>
              <IonDatetime
                id="startDate"
                name="startDate"
                displayFormat="DD/MM/YYYY"
                placeholder="Start Date"
                value={formik.values.startDate}
                onIonChange={formik.handleChange}
              ></IonDatetime>
            </IonItem>
            {formik.touched.startDate && formik.errors.startDate ? (
              <div className="errorMsg">{formik.errors.startDate}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">End Date</IonLabel>
              <IonDatetime
                id="endDate"
                name="endDate"
                displayFormat="DD/MM/YYYY"
                placeholder="End Date"
                value={formik.values.endDate}
                onIonChange={formik.handleChange}
              ></IonDatetime>
            </IonItem>
            {formik.touched.endDate && formik.errors.endDate ? (
              <div className="errorMsg">{formik.errors.endDate}</div>
            ) : null}
          </IonList>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton type="submit" className="shiftRight">
                  Done
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton disabled={!data} download="vouchers.csv" href={data}>
                  Download
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>

        <IonToast
          isOpen={!!onNoData}
          onDidDismiss={() => setOnNoData("")}
          message={onNoData}
          duration={2000}
          color="danger"
        />
      </IonContent>
    </IonPage>
  );
};

export default VoucherExport;
