
import { getData } from './common.service';
import { Geolocation } from '@capacitor/geolocation';

export class GeolocationService {
    async getCurrentPosition() {
        try {
            const coordinates = await Geolocation.getCurrentPosition();
            return coordinates;
        } catch (err) {
            console.log(err);
            return;
        }
    }

}

export async function getReverseGecodingAddress(latitude: number, longitude: number) {
    return getData(`?lat=${latitude}&lon=${longitude}&zoom=14&format=jsonv2`, {
        basePath: process.env.REACT_APP_OPENSTREETMAP_BASEPATH
    }).then(data => data.json())
}

export async function getElevation(latitude: number, longitude: number) {
    return getData(`/lookup?locations=${latitude},${longitude}`, {
        basePath: process.env.REACT_APP_OPENELEVATION_BASEPATH
    }).then(data => data.json())
}