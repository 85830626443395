import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonMenuButton, IonBackButton } from '@ionic/react';
import CreateMaintenance from '../components/CreateMaintenance';

const Maintenance: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Maintenance</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Maintenance</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <CreateMaintenance />
            </IonContent>
        </IonPage>
    );
};

export default Maintenance;
