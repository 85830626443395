import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IonAlert, IonButton, IonDatetime, IonInput, IonItem, IonLabel, IonList, IonSelect, IonSelectOption, IonToast, IonTextarea, IonModal, IonPicker, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react';
import { handleErrors } from '../services/common.service';
import { useLocation } from 'react-router-dom';
import useNavigation from '../hooks/useNavigation';
import { addMaintenance, getMaintenance, getMaintenanceConfig, updateMaintenance } from '../services/maintenance.service';
import { getFarmIdsOfUser, getPlotIdsOfFarm } from '../services/farm.service';
import { getSensors } from '../services/plot.service';
import { useRecoilValue } from 'recoil';
import { userProfileState } from '../services/state.service';
import React from 'react';
import './CreateMaintenance.css';
import { addCircle, removeCircle } from 'ionicons/icons';
import UserList from '../pages/UserList';

const FarmPicker = {
    name: "FarmId",
    options: [] as any[]
};

const setFarmPicker = (farmIds: any[]) => {
    FarmPicker.options = [];
    farmIds.forEach(item => {
        FarmPicker.options.push({
            text: item.farmId,
            value: item.farmId
        })
    });
};

const PlotPicker = {
    name: "PlotId",
    options: [] as any[]
};

const setPlotPicker = (plotIds: any[]) => {
    PlotPicker.options = [];
    plotIds.forEach(item => {
        PlotPicker.options.push({
            text: item.plotId,
            value: item.plotId
        })
    });
};

const CreateMaintenance = () => {
    const navigateTo = useNavigation();
    const location = useLocation();
    const [maintenance, setMaintenance] = useState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showSelectServicerModal, setShowSelectServicerModal] = useState(false);
    const [onSuccess, setOnSuccess] = useState('');
    const [onError, setOnError] = useState<string>('');
    const userProfile: any = useRecoilValue(userProfileState);

    const compareWith = (o1: any, o2: any) => {
        if (Array.isArray(o2)) {
            return o2.indexOf(o1) !== -1;
        }
        return o1 === o2;
    };

    function setValues(data: any) {
        if (data) {
            formik.setValues({
                edit: true,
                date: data.date,
                done: data.done,
                farmUserId: data.farmUserId,
                farmId: data.farmId,
                plotId: data.plotId,
                details: data.details,
                servicedBy: data.servicedBy,
                tasks: data.tasks
            });
        }
        setMaintenance(data);
    }

    useEffect(() => {
        let mounted = true;
        const id: any = (location.state as any)?.maintenanceId ?? null;
        if (id) {
            getMaintenance(id)
                .then(user => {
                    if (mounted) {
                        setValues(user);
                    }
                });
        } else {
            formik.setFieldValue('servicedBy', userProfile.username);
        }
        return () => { mounted = false };
    }, []);

    const formik = useFormik({
        initialValues: {
            edit: false,
            date: '',
            done: false,
            farmUserId: '',
            farmId: '',
            plotId: '',
            details: '',
            servicedBy: '',
            tasks: [{
                maintenanceType: '',
                sensor: ''
            }]
        },
        validationSchema: Yup.object({
            date: Yup.string().trim().required('Required'),
            farmUserId: Yup.string().trim().required('Required'),
            farmId: Yup.string().trim().required('Required'),
            plotId: Yup.string().trim().required('Required'),
            servicedBy: Yup.string().trim().required('Required'),
            tasks: Yup.array().of(
                Yup.object().shape({
                    maintenanceType: Yup.string().trim().required('Required')
                }))
        }),
        onSubmit: values => {
            setShowConfirmation(true);
        },
    });

    const [showSelectUserModal, setShowSelectUserModal] = useState(false);

    function setFarmUserId(item: any) {
        formik.setFieldValue('farmUserId', item.farmUserId);
        formik.setFieldValue('farmId', '');
        formik.setFieldValue('plotId', '');
        setShowSelectUserModal(false);
    }

    function setServicerId(item: any) {
        formik.setFieldValue('servicedBy', item.farmUserId);
        setShowSelectServicerModal(false);
    }

    const [farmPickerIsOpen, setFarmPickerIsOpen] = useState(false);
    useEffect(() => {
        let mounted = true;
        if (formik.values.farmUserId) {
            getFarmIdsOfUser(formik.values.farmUserId)
                .then(items => {
                    if (mounted) {
                        setFarmPicker(items)
                    }
                })
        }
        return () => { mounted = false };
    }, [formik.values.farmUserId])

    const [plotPickerIsOpen, setPlotPickerIsOpen] = useState(false);
    useEffect(() => {
        let mounted = true;
        if (formik.values.farmId) {
            getPlotIdsOfFarm(formik.values.farmId)
                .then(items => {
                    if (mounted) {
                        setPlotPicker(items)
                    }
                })
        }
        return () => { mounted = false };
    }, [formik.values.farmId])

    const [maintenanceTypes, setMaintenanceTypes] = useState([] as any[]);
    useEffect(() => {
        let mounted = true;
        getMaintenanceConfig()
            .then(response => {
                if (mounted) {
                    setMaintenanceTypes(response.maintenanceType);
                }
            })
        return () => { mounted = false };
    }, [])

    const [sensorList, setSensorList] = useState([] as string[]);
    useEffect(() => {
        let mounted = true;
        getSensors('WEATHER_UNIT')
            .then(items => {
                if (mounted) {
                    setSensorList(items);
                }
            })
        return () => { mounted = false };
    }, [])

    const addTask = () => {
        formik.values.tasks.push({
            maintenanceType: '',
            sensor: ''
        });
        formik.setFieldValue(`tasks`, formik.values.tasks);
    }

    const removeTask = (index: number) => {
        formik.values.tasks.splice(index, 1);
        formik.setFieldValue(`tasks`, formik.values.tasks);
    }

    const saveMaintenance = async (values: any) => {
        try {
            delete values.edit;
            delete values.id;
            if (!maintenance) {
                const response = await addMaintenance(values).then(handleErrors);
                setOnSuccess('Maintenance details added');
            } else {
                const updatedObj = Object.assign({}, maintenance, values);
                const response = await updateMaintenance(updatedObj).then(handleErrors);
                setOnSuccess('Maintenance details updated');
            }
        } catch (err) {
            setOnError('Some error occured');
        }
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <IonList>
                    <IonItem>
                        <IonLabel position="stacked">User Id</IonLabel>
                        <IonInput readonly id="farmUserId" name="farmUserId" value={formik.values.farmUserId} placeholder="Select User Id" onClick={e => setShowSelectUserModal(true)}></IonInput>
                    </IonItem>
                    {formik.touched.farmUserId && formik.errors.farmUserId ? (<div className="errorMsg">{formik.errors.farmUserId}</div>) : null}

                    <IonItem>
                        <IonLabel position="stacked">Farm Id</IonLabel>
                        <IonInput readonly disabled={!formik.values.farmUserId} id="farmId" name="farmId" value={formik.values.farmId} placeholder="Select Farm Id" onClick={e => setFarmPickerIsOpen(true)}></IonInput>
                    </IonItem>
                    {formik.touched.farmId && formik.errors.farmId ? (<div className="errorMsg">{formik.errors.farmId}</div>) : null}

                    <IonItem>
                        <IonLabel position="stacked">Plot Id</IonLabel>
                        <IonInput readonly disabled={!formik.values.farmId} id="plotId" name="plotId" value={formik.values.plotId} placeholder="Plot Id" onClick={e => setPlotPickerIsOpen(true)}></IonInput>
                    </IonItem>
                    {formik.touched.plotId && formik.errors.plotId ? (<div className="errorMsg">{formik.errors.plotId}</div>) : null}

                    <IonItem>
                        <IonLabel position="stacked">Date</IonLabel>
                        <IonDatetime id="date" name="date" displayFormat="DD/MM/YYYY" placeholder="Date" value={formik.values.date} onIonChange={formik.handleChange}></IonDatetime>
                    </IonItem>
                    {formik.touched.date && formik.errors.date ? (<div className="errorMsg">{formik.errors.date}</div>) : null}

                    <IonItem>
                        <IonLabel position="stacked">Done</IonLabel>
                        <IonSelect id="done" name="done" compareWith={compareWith} value={formik.values.done} placeholder="Done" onIonChange={formik.handleChange}>
                            <IonSelectOption key="false" value={false}>No</IonSelectOption>
                            <IonSelectOption key="true" value={true}>Yes</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    {formik.touched.done && formik.errors.done ? (<div className="errorMsg">{formik.errors.done}</div>) : null}

                    <IonItem lines="none">
                        <IonLabel >Task(s)</IonLabel>
                        <IonIcon className="add-task-color" icon={addCircle} slot="end" onClick={e => addTask()} />
                    </IonItem>
                    <IonItem lines="none">
                        <IonLabel position="floating"></IonLabel>
                        <IonGrid fixed={true} class="ion-no-padding">
                            {formik.values.tasks.map((item, index) => {
                                return <React.Fragment key={index}>
                                    <IonRow class="ion-no-padding">
                                        <IonCol size="5" class="ion-no-padding">
                                            <IonSelect className="custom-select" id={`tasks[${index}].maintenanceType`} name={`tasks[${index}].maintenanceType`} compareWith={compareWith} value={formik.values.tasks[index].maintenanceType} placeholder="Select Type" onIonChange={formik.handleChange}>
                                                {maintenanceTypes && maintenanceTypes.map(item => {
                                                    return <IonSelectOption key={item.id} value={item.id}>{item.value}</IonSelectOption>;
                                                })}
                                            </IonSelect>
                                        </IonCol>
                                        <IonCol size="5" class="ion-no-padding">
                                            <IonSelect className="custom-select" id={`tasks[${index}].sensor`} name={`tasks[${index}].sensor`} value={formik.values.tasks[index].sensor} compareWith={compareWith} placeholder="Select Sensor" onIonChange={formik.handleChange}>
                                                {sensorList && sensorList.map(item => {
                                                    return <IonSelectOption key={item} value={item}>{item}</IonSelectOption>;
                                                })}
                                            </IonSelect>
                                        </IonCol>
                                        <IonCol size="2" class="ion-no-padding ion-text-center">
                                            <IonButton fill="clear" size="small" disabled={formik.values.tasks.length === 1} onClick={e => removeTask(index)}><IonIcon className="delete-task-color" slot="icon-only" icon={removeCircle} /></IonButton>
                                        </IonCol>
                                    </IonRow>
                                    {formik.touched.tasks && formik.touched.tasks[index] && formik.touched.tasks[index].maintenanceType && formik.errors.tasks && formik.errors.tasks[index] && (formik.errors.tasks[index] as any).maintenanceType ? (<div className="errorMsg">{(formik.errors.tasks[index] as any).maintenanceType}</div>) : null}
                                </React.Fragment>
                            })}
                        </IonGrid>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Details</IonLabel>
                        <IonTextarea id="details" name="details" value={formik.values.details} placeholder="Maintenance Details" onIonChange={formik.handleChange}></IonTextarea>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Serviced By</IonLabel>
                        <IonInput id="servicedBy" name="servicedBy" value={formik.values.servicedBy} placeholder="Select Servicer" onClick={e => setShowSelectServicerModal(true)}></IonInput>
                    </IonItem>
                    {formik.touched.servicedBy && formik.errors.servicedBy ? (<div className="errorMsg">{formik.errors.servicedBy}</div>) : null}

                </IonList>

                <IonButton expand="block" type="submit">{(!maintenance ? 'Create' : 'Update') + ' Maintenance'}</IonButton>
            </form>
            <IonAlert
                isOpen={showConfirmation}
                onDidDismiss={() => setShowConfirmation(false)}
                header={'Confirm!'}
                message={'Please make sure that you have verified all the maintenance details. Save Maintenance?'}
                buttons={[
                    {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary'
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            saveMaintenance(formik.values);
                        }
                    }
                ]}
            />

            <IonToast
                isOpen={!!onSuccess}
                onDidDismiss={() => setOnSuccess('')}
                message={onSuccess}
                duration={2000}
                color="success"
            />

            <IonToast
                isOpen={!!onError}
                onDidDismiss={() => setOnError('')}
                message={onError}
                duration={2000}
                color="danger"
            />

            {showSelectUserModal && <IonModal isOpen={showSelectUserModal} >
            <UserList
                    selectedValue={formik.values.farmUserId}
                    onClickHandler={setFarmUserId}
                    onClose={setShowSelectUserModal}
                />
            </IonModal>}

            {showSelectServicerModal && <IonModal isOpen={showSelectServicerModal} >
            <UserList
                    selectedValue={formik.values.servicedBy}
                    onClickHandler={setServicerId}
                    onClose={setShowSelectServicerModal}
                />
            </IonModal>}

            <IonPicker
                isOpen={farmPickerIsOpen}
                columns={[FarmPicker]}
                buttons={[
                    {
                        text: "Cancel",
                        role: "cancel",
                        handler: value => {
                            setFarmPickerIsOpen(false);
                        }
                    },
                    {
                        text: "Confirm",
                        handler: value => {
                            formik.values.farmId = value.FarmId.value;
                            formik.values.plotId = '';
                            setFarmPickerIsOpen(false);
                        }
                    }
                ]}
            ></IonPicker>

            <IonPicker
                isOpen={plotPickerIsOpen}
                columns={[PlotPicker]}
                buttons={[
                    {
                        text: "Cancel",
                        role: "cancel",
                        handler: value => {
                            setPlotPickerIsOpen(false);
                        }
                    },
                    {
                        text: "Confirm",
                        handler: value => {
                            formik.values.plotId = value.PlotId.value;
                            setPlotPickerIsOpen(false);
                        }
                    }
                ]}
            ></IonPicker>
        </>
    );
};

export default CreateMaintenance;