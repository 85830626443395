import React, { useEffect } from 'react';
import OrderCard from './OrderCards';
 


interface IProps {
    orders: any[];
    onOrderUpdate: () => void;
}

const Orders: React.FC<IProps> = (props) => {
    const { orders, onOrderUpdate } = props;
    return (
        <>
            {Array.isArray(orders) && (orders ?? []).map((order, index) => <OrderCard onOrderUpdate={onOrderUpdate} order={order} key={index} />)}
        </>
    );
};

export default Orders;