import { IonInput, IonItem, IonLabel, IonIcon, IonButton, IonDatetime, IonModal, IonButtons, IonContent, IonHeader, IonTitle, IonToolbar, IonTextarea } from "@ionic/react";
import { image, mapOutline, reload, chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { GeolocationService } from "../services/geolocation.service";
import UploadImage from "./UploadImage";
import ImageViewer from "./ImageViewer";
import Map from "../pages/GoogleMap";
import * as yup from 'yup';
import './ObservationReadingTemplate.css';
import { cloneDeep } from "lodash";
import moment from "moment";

type TObservation = Record<
	string,
	{
		reading: number;
		rating: number;
		attachments?: string[],
		thumbnails?: string[]
	} & ({
		[key: string]: {
			reading: number;
			rating: number;
			attachments?: string[]
			thumbnails?: string[]
		};
	}
	)
>;

type ObservationReadingTemplateProps = {
	plotId: string;
	cropId: string;
	diseasePestId: string;
	formik: any;
	name: number;
	columns: string[];
	subColumns: string[];
	readonly?: boolean;
	observation?: any;
	showSuccessToast?: (message: string) => void;
	showErrorToast?: (message: string) => void;
	submitReading?: () => void;
	resetForm?: () => void;
	scaleValues: number[][];
};

/**
 * @component Renders the reading fields of a disease or pest observation.
 */
const ObservationReadingTemplate: React.FC<ObservationReadingTemplateProps> = (
	props
) => {
	const {
		cropId,
		diseasePestId,
		formik,
		name,
		columns,
		subColumns,
		readonly,
		observation,
		showErrorToast,
		submitReading,
		resetForm,
		scaleValues
	} = props;

	//validation Schema
	const scalesLastIndex = scaleValues?.length - 1;
	const validationSchema = yup.object().shape({
		value: yup
			.number()
			.typeError("Reading must be a number")
			.min(scaleValues[0][1] == null ? Number.MIN_SAFE_INTEGER : scaleValues[0][1], `Reading must be greater than ${scaleValues[0][1]}`)
			.max(scaleValues[scalesLastIndex][2] == null ? Number.MAX_SAFE_INTEGER : scaleValues[scalesLastIndex][2], `Reading must be smaller than ${scaleValues[scalesLastIndex][2]}`)
	})

	const [selectedImage, setSelectedImage] = useState<string>();
	const [showImageModal, setShowImageModal] = useState<boolean>(false);
	const [showMapModal, setShowMapModal] = useState<boolean>(false);
	const [validationError, setValidationError] = useState(null);
	const [activeFieldKey, setActiveFieldKey] = useState<string>("");
	const [inputValues, setInputValues] = useState<{ [key: string]: number | string }>({});
	const [selectedColumn, setSelectedColumn] = useState<string>();
	const [selectedSubcolumn, setSelectedSubcolumn] = useState<string>();
	const emptyColumnsValue = {};
	for (const column of columns) {
		const colVal = { reading: -1, rating: -1, attachments: [] };

		if (subColumns.length > 0) {
			Object.assign(emptyColumnsValue, { [column]: {} });
			for (const subCol of subColumns) {
				Object.assign((emptyColumnsValue as any)[column], { [subCol]: colVal });
			}
		}
		else {
			Object.assign(emptyColumnsValue, { [column]: { ...colVal } });
		}
	}

	const [vegetationIndex, setVegetationIndex] = useState<number>(name);

	const defaultValue = () =>
		readonly
			? observation!
			: {
				sample: emptyColumnsValue,
				geoLocation: { type: "Point", coordinates: ["", ""] },
				rating: -1
			};

	const [reading, setReading] = useState<TObservation>(defaultValue());

	useEffect(() => {
		setInitialLatLng();
	}, []) // eslint-disable-line

	useEffect(() => {
		setVegetationIndex(name);
	}, [name]);

	useEffect(() => {
		const reading = hasNextPlant(vegetationIndex) ? cloneDeep(formik.values.vegetationReading[vegetationIndex - 1]) : defaultValue();
		setReading(reading);
	}, [vegetationIndex]); // eslint-disable-line

	const validateAndSaveReading = (
		e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
	) => {
		e.preventDefault();

		if (!validateReading()) {
			if (!!showErrorToast)
				showErrorToast("Invalid form values");
			return;
		}

		const vegetationReading = cloneDeep(formik.values.vegetationReading);

		if (hasNextPlant(vegetationIndex)) {
			vegetationReading[vegetationIndex - 1] = reading;
		} else {
			vegetationReading.push(reading);
		}

		formik.setFieldValue('vegetationReading', vegetationReading);

		setReading(defaultValue());

		if (!!submitReading) submitReading();
		setInitialLatLng();
		setInputValues({});
		setVegetationIndex(formik.values.vegetationReading.length + 1);
	};

	const checkValue = (value: number) => {
		if (value === -1) {
			if (showErrorToast) showErrorToast("Please fill all values");
			return false;
		}
		return true;
	};

	const validateReading = () => {
		const hasPreviousSprayDateButNotDetail = !!formik.values.previousSprayDate && !formik.values.previousSpray;
		const hasPreviousSprayDetailButNotDate = !!formik.values.previousSpray && !formik.values.previousSprayDate;

		if (hasPreviousSprayDateButNotDetail || hasPreviousSprayDetailButNotDate) {
			return false;
		}

		for (const row in reading.sample) {
			const { ...otherKeys } = reading.sample[row];
			if (Object.keys(otherKeys).length > 1) {
				for (const subCol in reading.sample[row]) {
					if (!checkValue(((reading.sample[row] as any)[subCol]).reading)) {
						return false;
					}
				}
			} else {
				if (!checkValue(reading.sample[row].reading)) {
					return false;
				}
			}
		}

		return true;
	};

	function setInitialLatLng() {
		const geoService = new GeolocationService();
		geoService.getCurrentPosition().then((geolocation) => {
			handleLatChange(geolocation?.coords?.latitude?.toFixed(6) ?? '');
			handleLngChange(geolocation?.coords?.longitude?.toFixed(6) ?? '');
		});
	}

	const renderColumn = (
		column: string,
		subColumn?: string,
	) => {
		const key = subColumn
			? `render-subColumn-${column}-${subColumn}`
			: `render-column-${column}`;

		const updateCurrentReading = (e: CustomEvent) => {
			e.preventDefault();
			let newValue = (e.detail.value ?? "").replace(/\D/g, '');

			if(isNaN(newValue)) {
				newValue = 0;
			}

			const newInputValues = { ...inputValues, [key]: newValue };
			setInputValues(newInputValues);
			validationSchema
				.validate({ value: newValue })
				.then(() => {
					// Validation successful
					setValidationError(null);
				})
				.catch((error) => {
					// Validation failed
					setValidationError(error.errors[0]);
				});

			if (subColumn !== undefined) {
				setReading(prevReading => ({
					...prevReading,
					sample: {
						...prevReading.sample,
						[column]: {
							...(prevReading.sample[column] || {}),
							[subColumn]: {
								...((prevReading.sample[column] as any)?.[subColumn] || {}),
								reading: Number(newValue)
							}
						}
					}
				}));
			} else {
				setReading(prevReading => ({
					...prevReading,
					sample: {
						...prevReading.sample,
						[column]: {
							...(prevReading.sample[column] || {}),
							reading: Number(newValue)
						}
					}
				}));
			}
		};

		const onUploadSuccess = (responseBody: any) => {
			const image = responseBody.images[0];
			const thumbnail = responseBody.thumbnails[0];

			if (subColumn !== undefined) {
				setReading((prevReading: any) => ({
					...prevReading,
					sample: {
						...prevReading.sample,
						[column]: {
							...(prevReading.sample[column] || {}),
							[subColumn]: {
								...prevReading.sample[column]?.[subColumn],
								attachments: [...(prevReading.sample[column]?.[subColumn]?.attachments ?? []), image],
								thumbnails: [...(prevReading.sample[column]?.[subColumn]?.thumbnails ?? []), thumbnail]
							}
						}
					}
				}));
			} else {
				setReading(prevReading => ({
					...prevReading,
					sample: {
						...prevReading.sample,
						[column]: {
							...(prevReading.sample[column] || {}),
							attachments: [...(prevReading.sample[column]?.attachments ?? []), image],
							thumbnails: [...(prevReading.sample[column]?.thumbnails ?? []), thumbnail]
						}
					}
				}));
			}


		}

		const onThumbnailClick = (index: number) => {
			setSelectedColumn(column);
			setSelectedSubcolumn(subColumn);
			setSelectedImage(attachments[index]);
			setShowImageModal(true);
		}

		const hasReadingData = !!reading && !!reading?.sample && !!reading?.sample[column];
		let readingCol = { attachments: [], thumbnails: [] };

		if (!!column && hasReadingData) {
			readingCol = subColumn ?
				(reading.sample[column] as any)[subColumn] :
				reading.sample[column];
		}

		const { thumbnails = [], attachments = [] } = readingCol ?? {};
		const value = !!subColumn ? (reading?.sample as any)?.[column]?.[subColumn]?.reading : reading.sample[column]?.reading;

		return (
			<div key={key} className="reading-field-container">
				<div className="reading-field">
					<span>
						<label htmlFor={`${key}-label`} className="column-label">
							{subColumn || column}
						</label>
						<IonInput
							value={value === -1 ? null : value}
							name={`${key}-input`}
							placeholder={`Enter reading`}
							onIonChange={updateCurrentReading}
							onIonFocus={() => setActiveFieldKey(key)}
							onIonBlur={() => setActiveFieldKey("")}
							required
						/>
						{validationError && key === activeFieldKey && <div className="error-msg">
							{validationError}
						</div>
						}
					</span>

					<span>
						<UploadImage
							primaryPath={"observations"}
							secondaryPath={`${cropId}_${diseasePestId}`}
							onSuccess={onUploadSuccess}
							icon={image}
						/>
					</span>
				</div>

				{
					!!thumbnails && thumbnails.length > 0 && <div className="reading-thumbnails-container">
						<Thumbnails thumbnails={thumbnails} onClick={onThumbnailClick} />
					</div>
				}
			</div>
		);
	};

	const handleLatChange = (value: string) => {
		if (!value) return;

		setReading(prevReading => {
			const updatedReading = { ...prevReading };
			(updatedReading.geoLocation.coordinates as any)[1] = value;
			return updatedReading;
		});

	};

	const handleLngChange = (value: string) => {
		if (!value) return;

		setReading(prevReading => {
			const updatedReading = { ...prevReading };
			(updatedReading.geoLocation.coordinates as any)[0] = value;
			return updatedReading;
		});
	};

	/**
	 * @functions to handle maps visibility 
	 */
	function openMap(): void {
		setShowMapModal(true);
	}

	function closeMap(): void {
		setShowMapModal(false);
	}

	async function onPlotsLocationChange(payload: any) {
		let { location } = payload;
		location.lng = location.lng.toFixed(6);
		location.lat = location.lat.toFixed(6);
		handleLatChange(location.lat);
		handleLngChange(location.lng);
		closeMap();
	}

	function deleteImage(url: string) {
		if (selectedColumn) {
			setReading(prevReading => {
				const selectedReading = selectedSubcolumn ? (prevReading.sample[selectedColumn!] as any)[selectedSubcolumn] : prevReading.sample[selectedColumn!];
				const updatedReading = { ...prevReading };
				const imageIndex = selectedReading.attachments.findIndex((item: string) => item === url);
				if (imageIndex > -1) {
					selectedReading.attachments.splice(imageIndex, 1);
					selectedReading.thumbnails.splice(imageIndex, 1);
				}
				if (selectedSubcolumn) {
					(updatedReading.sample[selectedColumn] as any)[selectedSubcolumn] = selectedReading;
				}
				else
					updatedReading.sample[selectedColumn] = selectedReading;
				return updatedReading;
			});
		}
	}

	const hasNextPlant = (vegetationIndex: number) => {
		const totalPlants = formik.values.vegetationReading.length + 1;
		return vegetationIndex < totalPlants;
	}

	const hasPrevPlant = (vegetationIndex: number) => {
		return vegetationIndex > 1;
	}

	const showNextPlant = () => {
		setVegetationIndex(vegetationIndex => {
			return vegetationIndex + (hasNextPlant(vegetationIndex) ? 1 : 0);
		})
	}

	const showPrevPlant = () => {
		setVegetationIndex(vegetationIndex => {
			return vegetationIndex - (hasPrevPlant(vegetationIndex) ? 1 : 0);
		})
	}

	const hasPreviousSprayDateButNotDetail = !!formik.values.previousSprayDate && !formik.values.previousSpray;
	const hasPreviousSprayDetailButNotDate = !!formik.values.previousSpray && !formik.values.previousSprayDate;

	return (
		<div>
			<div className="date-container" >
				<div>
					<label 
						htmlFor="previousSpray" 
						className="column-label"
					> 
						Spray Details
					</label>
					<IonItem>
						<IonTextarea
							name="previousSpray"
							placeholder="Enter details"
							onIonChange={formik.handleChange}
							value={formik.values.previousSpray}
						/>
					</IonItem>

					{hasPreviousSprayDateButNotDetail && <div className="errorMsg">Please add spray detail too</div>}
				</div>
				<div>
					<label 
						htmlFor="previousSprayDate" 
						className="column-label"
					>
						Spray Date
					</label>
					<IonDatetime
						name="previousSprayDate"
						placeholder="Spray date"
						displayFormat="DD/MM/YYYY"
						onIonChange={formik.handleChange}
						value={formik.values.previousSprayDate}
						max={moment().toISOString()}
					/>

					{hasPreviousSprayDetailButNotDate && <div className="errorMsg">Please add spray date too</div>}
				</div>
			</div>

			<div className="vegetation-label">
				<IonIcon
					icon={chevronBackOutline}
					style={{ color: hasPrevPlant(vegetationIndex) ? "inherit" : "silver" }}
					onClick={showPrevPlant}
				/>
				<label>Plant: {vegetationIndex}</label>
				<IonIcon
					icon={chevronForwardOutline}
					style={{ color: hasNextPlant(vegetationIndex) ? "inherit" : "silver" }}
					onClick={showNextPlant}
				/>
			</div>

			<form className="reading-container">
				<div style={{ margin: "15px 0px" }}>
					<label> Location </label>
					<LatLng
						onLatChange={(e: any) => handleLatChange(e.detail.value)}
						onLngChange={(e: any) => handleLngChange(e.detail.value)}
						setInitialLatLng={setInitialLatLng}
						lat={((reading?.geoLocation?.coordinates as any)?.[1] ?? '')}
						lng={((reading?.geoLocation?.coordinates as any)?.[0] ?? '')}
						openMap={openMap}
						showMapModal={showMapModal}
						onPlotsLocationChange={onPlotsLocationChange}
					/>
				</div>

				{columns.map((column, index) =>
					subColumns.length > 0 ? (
						<div
							key={`sub-column-container-${column}-${index}`}
							style={{ margin: "20px 0px" }}
						>
							<div className="vegetation-label">
								<label>{column}</label>
							</div>

							<div className="reading-container">
								{subColumns.map((subColumn) =>
									renderColumn(column, subColumn)
								)}
							</div>

						</div>
					) : (
						<div key={`column-container-${column}-${index}`} style={{ margin: "20px 0px" }}>
							{renderColumn(column)}
						</div >
					)
				)}

				<IonButton type="button" onClick={validateAndSaveReading}
					style={{
						opacity: validationError != null ? 0.5 : 1,
						pointerEvents: validationError != null ? 'none' : 'auto',
						transition: 'opacity 0.5s',
					}} >
					{hasNextPlant(vegetationIndex) ? "Update" : "Add"} Reading
				</IonButton>
				<IonButton type="button" color="danger" onClick={resetForm}>
					Reset
				</IonButton>
			</form>

			<IonModal isOpen={showImageModal} cssClass='my-custom-class'>
				<ImageViewer imageUrl={selectedImage!} onDeleteHandler={deleteImage} onCloseHandler={setShowImageModal} />
			</IonModal>
		</div>
	);
};

export default ObservationReadingTemplate;


const LatLng: React.FC<any> = ({
	onLatChange,
	onLngChange,
	setInitialLatLng,
	lat,
	lng,
	openMap,
	showMapModal,
	closeMap,
	onPlotsLocationChange
}) => (
	<div style={{ display: "flex" }}>
		<IonItem style={{ alignItems: "center" }}>
			<IonLabel position="stacked">Latitude</IonLabel>
			<IonInput
				id="location.lat"
				name="location.lat"
				inputmode="decimal"
				value={lat ?? null}
				placeholder="Latitude"
				onIonChange={onLatChange}
			></IonInput>
			<IonIcon
				icon={reload}
				slot="end"
				onClick={(e) => setInitialLatLng()}
			/>
		</IonItem>
		<IonItem style={{ alignItems: "center" }}>
			<IonLabel position="stacked">Longitude</IonLabel>
			<IonInput
				id="location.lng"
				name="location.lng"
				inputmode="decimal"
				value={lng ?? null}
				placeholder="Longitude"
				onIonChange={onLngChange}
			></IonInput>
			<IonIcon icon={mapOutline} slot='end' onClick={openMap} >Map</IonIcon>
		</IonItem>

		{/* map modal */}
		<IonModal isOpen={showMapModal}>
			<IonHeader>
				<IonToolbar>
					<IonTitle size='small' style={{ fontWeight: 'bold' }}>
						Plot Location Viewer
					</IonTitle>
					<IonButtons slot="end">
						<IonButton onClick={closeMap}>Close</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding">
				<Map
					lat={Number(lat) ?? 0}
					lng={Number(lng) ?? 0}
					onPlotsLocationChange={onPlotsLocationChange}
					onPlotsGeoBoundaryChange={() => { }}
					zoom={!!Number(lat) ? 18 : 6}
				/>
			</IonContent>
		</IonModal>
	</div>
)

const Thumbnails: React.FC<any> = ({ thumbnails, onClick }) => {
	return thumbnails.map((item: string, index: number) => (
		<img
			alt="thumbnail"
			key={item}
			src={item}
			onClick={() => onClick(index)}
		/>
	))
}