import React from 'react';
import FarmerProfileCard from './FarmerProfileCard';

interface IProps {
    profiles: any[];
}

const FarmerProfiles: React.FC<IProps> = (props) => {
    return (
        <>
            {props.profiles && props.profiles?.map((Profile, index) => <FarmerProfileCard profile={Profile} key={index} />)}
        </>
    )
};

export default FarmerProfiles;