import React from 'react';
import LeadCard from './LeadCard';

interface IProps {
    leads: any[];
}

const Leads: React.FC<IProps> = (props) => {
    return (
        <>
            {props.leads && props.leads.map((lead, index) => <LeadCard lead={lead} key={index} />)}
        </>
    );
};

export default Leads;