import { useHistory } from 'react-router-dom';

const useNavigation = () => {
    const history = useHistory();
    const navigateTo = async (path: string, data: any) => {
        history.push({
            pathname: path,
            state: data
        });
    };
    return navigateTo;
};
export default useNavigation;