import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonDatetime,
  IonAlert,
  IonLoading,
  IonGrid,
  IonToast,
} from "@ionic/react";
import {
  deleteScreenData,
  getScreenType,
  patchScreenData,
  postScreenData,
  getLocale,
} from "../services/dynamicForm.service";
import useNavigation from "../hooks/useNavigation";

interface Props {
  modalData?: any;
  screenTitle?: string;
  screenType?: string;
  isSubmit?: boolean;
  isDelete?: boolean;
  setIsSubmit?: any;
  setIsDelete?: any;
}

type ParameterType =
  | "boolean"
  | "date"
  | "string"
  | "number"
  | "multiSelect"
  | "singleSelect"
  | "expression";

type Option = {
  value?: string | number;
  id?: string;
};

interface Parameter {
  id: string;
  name?: string;
  description?: string;
  type: ParameterType;
  order: number;
  options?: Option[];
  expression?: string;
  required?: boolean;
  readonly?: boolean;
}

const DynamicForm: React.FC<Props> = ({
  modalData,
  screenTitle,
  screenType,
  isDelete,
  isSubmit,
  setIsDelete,
  setIsSubmit,
}: Props): JSX.Element => {
  const [screenField, setScreenField] = useState<any>();
  const [localeIds, setLocaleIds] = useState<string[]>([]);
  const [locales, setLocales] = useState<Record<string, string>>({});
  const [formValues, setFormValues] = useState<Record<string, any>>({});
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [onSuccess, setOnSuccess] = useState("");
  const [onError, setOnError] = useState("");

  const navigateTo = useNavigation();

  useEffect(() => {
    setShowLoading(true);
    getScreenType(screenType as string)
      .then((res) => {
        res?.fields?.sort((a: any, b: any) => a.order - b.order);
        setScreenField(res);
        setFormValues(
          res?.fields?.reduce((acc: any, field: any) => {
            acc[field?.id] = modalData?.profile?.[field?.id] || "";
            return acc;
          }, {})
        );
        const ids: string[] = [];
        res?.fields.forEach((param: any) => {
          ids.push(param.id);
          if (param?.options && Array.isArray(param?.options)) {
            param?.options?.forEach((option: any) => {
              if (option?.id) ids.push(option.id);
            });
          }
        });
        setLocaleIds(ids);
      })
      .finally(() => setShowLoading(false));
  }, [modalData]);

  useEffect(() => {
    if (localeIds.length > 0) {
      const filter = {
        where: {
          id: { inq: localeIds },
        },
      };
      getLocale(filter).then((res) => {
        transformLocale(res, "en");
      });
    }
  }, [localeIds]);

  const transformLocale = (array: any[], language: string) => {
    const result: Record<string, string> = {};

    array.forEach((item) => {
      if (item.id && item[language]) {
        result[item.id] = item[language];
      }
    });
    setLocales(result);
  };

  const handleChange = (event: any) => {
    const { name, value, type } = event.target;
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name]:
        type === "number"
          ? Number(value)
          : value === "true"
          ? true
          : value === "false"
          ? false
          : value,
    }));
  };

  const handleSubmit = () => {
    const mandatoryFields = screenField?.fields
      ?.filter((field: any) => field.required)
      ?.map((field: any) => field.id);
    const missingFields = mandatoryFields?.filter(
      (fieldId: any) => !formValues[fieldId] && formValues[fieldId] !== 0
    );
    if (missingFields.length > 0) {
      setShowAlert(true);
      return;
    }
    const filteredValues = Object.entries(formValues)
      .filter(
        ([key, value]) => value !== "" && value !== null && value !== undefined
      )
      .reduce((acc: any, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    setShowLoading(true);
    if (modalData?.id) {
      patchScreenData(screenField?.endPoint, {
        ...modalData,
        profile: filteredValues,
      })
        .then((res: any) => {
          if (res.error) setOnError(res.error);
          else {
            navigateTo("/farmer-profiles", {});
            resetFormValue();
            setOnSuccess("Data updated");
          }
        })
        .finally(() => setShowLoading(false));
    } else {
      postScreenData(screenField?.endPoint, { profile: filteredValues })
        .then((res: any) => {
          setShowLoading(false);
          if (res.id) {
            setOnSuccess("Data added");
            navigateTo("/farmer-profiles", {});
            resetFormValue();
          } else setOnError("Some error occured");
        })
        .finally(() => setShowLoading(false));
    }

    setIsSubmit(false);
  };

  const resetFormValue = () =>{
    setFormValues(
        screenField.fields.reduce((acc: any, field: any) => {
          acc[field?.id] = "";
          return acc;
        }, {})
      );
  }

  const handleDelete = () => {
    if (modalData?.id) {
      setShowLoading(true);
      deleteScreenData(screenField?.endPoint, modalData?.id).then(
        (res: any) => {
          if (res.error) setOnError("Some error occured");
          else {
            navigateTo("/farmer-profiles", {});
            resetFormValue();
            setOnSuccess("Data deleted");
          }
        }
      ).finally(() => setShowLoading(false));;
    } else setOnError("Some error occured");
    setIsDelete(false);
  };

  useEffect(() => {
    if (!!isDelete) setShowConfirmation(true);
    if (!!isSubmit) setShowConfirmation(true);
  }, [isDelete, isSubmit]);

  const renderField = (field: Parameter) => {
    const isMandatory = field?.required;

    const label = (
      <IonLabel position="stacked">
        {locales?.[field?.id] || field?.name || field?.id}{" "}
        {isMandatory && <span style={{ color: "red" }}>*</span>}
      </IonLabel>
    );

    switch (field.type) {
      case "boolean":
        return (
          <IonItem key={field?.id}>
            <IonLabel>{label}</IonLabel>
            <IonSelect
              name={field?.id}
              value={String(formValues[field?.id]) || false}
              onIonChange={handleChange}
              disabled={field?.readonly}
            >
              <IonSelectOption value="true">True</IonSelectOption>
              <IonSelectOption value="false">False</IonSelectOption>
            </IonSelect>
          </IonItem>
        );
      case "date":
        return (
          <IonItem key={field?.id}>
            <IonLabel>{label}</IonLabel>
            <IonDatetime
              name={field?.id}
              value={formValues[field?.id] || ""}
              onIonChange={handleChange}
              disabled={field?.readonly}
            />
          </IonItem>
        );
      case "string":
        return (
          <IonItem key={field?.id}>
            <IonLabel>{label}</IonLabel>
            <IonInput
              type="text"
              name={field?.id}
              value={formValues[field?.id] || ""}
              onIonInput={handleChange}
              disabled={field?.readonly}
            />
          </IonItem>
        );
      case "number":
        return (
          <IonItem key={field?.id}>
            <IonLabel>{label}</IonLabel>
            <IonInput
              name={field?.id}
              type="number"
              value={formValues[field?.id] || ""}
              onIonInput={handleChange}
              disabled={field?.readonly}
            ></IonInput>
          </IonItem>
        );
      case "multiSelect":
        return (
          <IonItem key={field?.id}>
            <IonLabel>{label}</IonLabel>
            <IonSelect
              multiple
              name={field?.id}
              value={formValues[field?.id] || []}
              onIonChange={handleChange}
              disabled={field?.readonly}
            >
              {field?.options?.map((option: Option) => (
                <IonSelectOption
                  key={option?.value || option?.id}
                  value={option?.value || option?.id}
                >
                  {locales?.[option.id!] || option?.value || option?.id}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        );
      case "singleSelect":
        return (
          <IonItem key={field?.id}>
            <IonLabel>{label}</IonLabel>
            <IonSelect
              name={field?.id}
              value={formValues[field?.id] || ""}
              onIonChange={handleChange}
              disabled={field?.readonly}
            >
              {field?.options?.map((option: Option) => (
                <IonSelectOption
                  key={option?.value || option?.id}
                  value={option?.value || option?.id}
                >
                  {locales?.[option.id!] || option?.value || option?.id}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        );
      case "expression":
        return (
          <IonItem key={field?.id}>
            <IonLabel>{label}</IonLabel>
            <IonInput
              type="text"
              name={field?.id}
              value={formValues[field?.id] || ""}
              onIonInput={handleChange}
              disabled={field?.readonly}
            />
          </IonItem>
        );
      default:
        return null;
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{screenTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {showLoading ? (
          <IonLoading isOpen={showLoading} message="Please wait..." />
        ) : (
          <>
            {screenField?.formId && (
              <IonGrid>
                {screenField?.fields?.map((field: Parameter) =>
                  renderField(field)
                )}
              </IonGrid>
            )}
          </>
        )}
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header="Missing Fields"
          message={`Please fill out the mandatory fields: ${screenField?.fields
            ?.filter((field: any) => field.required)
            .map((field: any) => locales?.[field.id] || field.name || field.id)
            .join(", ")}`}
          buttons={["OK"]}
        />
        <IonAlert
          isOpen={showConfirmation}
          onDidDismiss={() => setShowConfirmation(false)}
          header={"Confirm!"}
          message={
            !!isSubmit
              ? "Are you sure you want to save this data?"
              : "Do you want to delete this data?"
          }
          buttons={[
            {
              text: "No",
              role: "cancel",
              cssClass: "secondary",
            },
            {
              text: "Yes",
              handler: () => {
                !!isSubmit ? handleSubmit() : handleDelete();
              },
            },
          ]}
        />

        <IonToast
          isOpen={!!onSuccess}
          onDidDismiss={() => setOnSuccess("")}
          message={onSuccess}
          duration={2000}
          color="success"
        />

        <IonToast
          isOpen={!!onError}
          onDidDismiss={() => setOnError("")}
          message={onError}
          duration={2000}
          color="danger"
        />
      </IonContent>
    </IonPage>
  );
};

export default DynamicForm;
