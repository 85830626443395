import { getData, postData } from "./common.service";

export async function getZammadUserByMobile(mobile: string) {
    return getData(`/zammad?path=users/search?query=mobile:${mobile}`,{
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getZammadUser(id: number) {
    return getData(`/zammad?path=users/search?query=id:${id}`,{
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getTicketById(id: number) {
    return getData(`/zammad?path=tickets/search?query=id:${id}`,{
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getTickets(customerId: number, page:number) {
    const query = encodeURIComponent(`tickets/search?query=owner_id:${customerId}&sort_by=created_at&page=${page}&per_page=100`)
    return getData(`/zammad?path=${query}`,{
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getTicket(ticketId: number) {
    return getData(`/zammad?path=ticket_articles/by_ticket/${ticketId}?sort_by=created_at`,{
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function postTicket(ticketId: number, body: any) {
    return postData(`/zammad?path=tickets/${ticketId}`,{
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        usePutMethod: true,
        sendTokenInHeader: true,
        body: body,
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        }
    }).then(data => data.json())
}
export async function createTicket(plotId: string, body: any, zammadUserId: number) {
    return postData(`/zammad?path=tickets&obo=${zammadUserId}&plotId=${plotId}`,{
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        body: body,
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        }
    }).then(data => data.json())
}

export async function getUserListByRole(roleId: number, page: number = 0, per_page: number = 0) {
    let url = `users/search?query=role_ids:${roleId}`;
    if(!!page) {
        url += `&page=${page}`;
    }
    if(!!per_page) {
        url += `&per_page=${per_page}`;
    }

    const encodedUrl = `/zammad?path=${encodeURIComponent(url)}`;
    return getData(encodedUrl,{
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        }
    }).then(data => data.json())
}

export async function createUser(payload: any){
    return postData('/zammad?path=users', {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH, 
        sendTokenInHeader: true, 
        body: payload, 
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(data => data.json())
}

export async function getTicketsByState(stateId: number, page: number = 0, per_page: number = 0) {
    let url = `tickets/search?query=state_id:${stateId}`
    if(!!page) {
        url += `&page=${page}`;
    }
    if(!!per_page) {
        url += `&per_page=${per_page}`;
    }

    const encodedUrl = `/zammad?path=${encodeURIComponent(url)}`;
    return getData(encodedUrl,{
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        }
    }).then(data => data.json())
}