import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonMenuButton, IonList, IonItem, IonLabel, IonInput } from '@ionic/react';
import './Profile.css';

interface ComponentProps {
    userProfile: any
}

const Profile: React.FC<ComponentProps> = (props: ComponentProps) => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>User Profile</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">User Profile</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonList>
                    <IonItem>
                        <IonLabel position="stacked">User Name</IonLabel>
                        <IonInput readonly id="username" name="username" value={props.userProfile.username} ></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Name</IonLabel>
                        <IonInput readonly id="name" name="name" value={props.userProfile.name} ></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Email</IonLabel>
                        <IonInput readonly id="email" name="email" value={props.userProfile.email} ></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Mobile</IonLabel>
                        <IonInput readonly id="mobile" name="mobile" value={props.userProfile.mobile} ></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Gender</IonLabel>
                        <IonInput readonly id="gender" name="gender" value={props.userProfile.gender} ></IonInput>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Profile;
