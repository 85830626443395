import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonBackButton,
  IonRow,
  IonFooter,
  IonCol,
  IonButton,
} from "@ionic/react";
import DynamicForm from "../components/DynamicForm";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { getFarmerProfileById } from "../services/dynamicForm.service";

const FarmerProfile: React.FC = () => {
const location = useLocation();
const [modalData, setModalData] = useState<any>();
const [isSubmit, setIsSubmit] = useState<boolean>(false);
const [isDelete, setIsDelete] = useState<boolean>(false);
const id: any = (location.state as any)?.profileId ?? null;

useEffect(() => {
    if (!!id) {
      getFarmerProfileById(id).then((res)=>{
      setModalData(res);
      })
    }
  }, [location]);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Farmer Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Farmer Profile</IonTitle>
          </IonToolbar>
        </IonHeader>
        <DynamicForm screenTitle="Farmer Profile" screenType="farmerProfile" modalData={modalData} isDelete={isDelete} isSubmit={isSubmit} setIsSubmit={setIsSubmit} setIsDelete={setIsDelete} />
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonRow>
            <IonCol size="12">
              <IonButton
                expand="block"
                type="submit"
                onClick={()=>setIsSubmit(true)}
              >
                Submit
              </IonButton>
            </IonCol>
            {!!id && (
            <IonCol size="12">
              <IonButton
              type="submit"
                expand="block"
                color="danger"
                onClick={()=>setIsDelete(true)}
              >
                Delete
              </IonButton>
            </IonCol>
            )}
          </IonRow>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default FarmerProfile;
