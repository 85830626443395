import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import {
    Formik,
    Form as FormikForm,
    Field,
    ErrorMessage,
    useFormikContext
} from 'formik';
import { IonButton, IonCard, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonNote, IonSelect, IonSelectOption, IonTextarea } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { evaluateFormula, getFormula } from '../services/formula.service';

export function Form(props: any) {
    return (
        <Formik
            {...props}
        >
            <FormikForm className="needs-validation">
                {props.children}
            </FormikForm>
        </Formik>)
}

export function CustomInputTextField(props: any) {
    return (
        <IonInput {...props} onIonChange={props.onChange}></IonInput>
    )
}

export function CustomSelectField(props: any) {
    return (
        <IonSelect {...props} onIonChange={props.onChange}>
            <IonSelectOption key="" value="">None</IonSelectOption>
            {props.options?.map((option: any) => {
                return (
                    <IonSelectOption key={option.value} value={option.value}>{option.label || option.value}</IonSelectOption>
                )
            })}
        </IonSelect>
    )
}

export function TextField(props: any) {
    const { name, label, placeholder, required, ...rest } = props;
    return (
        <>
            <IonItem>
                {label && <IonLabel position="stacked">{label}</IonLabel>}
                <Field
                    className="form-control"
                    type="text"
                    name={name}
                    id={name}
                    as={CustomInputTextField}
                    placeholder={placeholder || ""}
                    {...rest}
                />
            </IonItem>
            {required && <ErrorMessage name={name} render={(msg: string) => <div className="errorMsg" style={{ color: 'red' }} >{msg}</div>} />}
        </>
    )
}

export function SelectField(props: any) {
    const { name, label, options, placeholder, required, ...rest } = props;
    return (
        <>
            <IonItem>
                {label && <IonLabel position="stacked">{label}</IonLabel>}
                <Field
                    name={name}
                    id={name}
                    as={CustomSelectField}
                    placeholder={placeholder || ""}
                    options={options || []}
                    {...rest}
                />
            </IonItem>
            {required && <ErrorMessage name={name} render={(msg: string) => <div className="errorMsg" style={{ color: 'red' }} >{msg}</div>} />}
        </>
    )
}

export function SubmitButton(props: any) {
    const { title, ...rest } = props;
    const { isSubmitting } = useFormikContext();

    return (
        <IonButton expand="block" type="submit" {...rest} disabled={isSubmitting}>{title}</IonButton>
    )
}

function getSchema(data: any) {
    return data.parameters.reduce((acc: any, item: any) => {
        const value = {
            [item.symbol]: {
                type: item.type || "text",
                label: `${item.name} (${item.symbol})`,
                required: item.required !== undefined ? item.required : true,
                placeholder: item.description,
                options: item.options
            }
        };
        acc = Object.assign({}, acc, value);
        return acc;
    }, {});
}

function EvaluateFormula() {
    const location = useLocation();
    const [formula, setFormula] = useState({} as any);
    const [formSchema, setFormSchema] = useState({});
    const [formData, setFormData] = useState({});
    const [validationSchema, setValidationSchema] = useState({});
    const [showResult, setShowResult] = useState(false);
    const [result, setResult] = useState<any>({});

    useEffect(() => {
        let mounted = true;
        const formulaId: any = (location.state as any)?.formulaId ?? null;
        if (formulaId) {
            getFormula(formulaId)
                .then(formula => {
                    if (mounted && !formula.error) {
                        setFormula(formula);
                        const schema = getSchema(formula);
                        setFormSchema(schema);
                        initForm(schema);
                    }
                });
        }
        return () => { mounted = false };
    }, []);

    const initForm = (formSchema: any) => {
        let _formData: any = {};
        let _validationSchema: any = {};

        for (var key of Object.keys(formSchema)) {
            _formData[key] = "";

            if (formSchema[key].type === "text") {
                _validationSchema[key] = Yup.string();
            } else if (formSchema[key].type === "email") {
                _validationSchema[key] = Yup.string().email()
            } else if (formSchema[key].type === "select") {
                _validationSchema[key] = Yup.string().oneOf(formSchema[key].options.map((o: any) => o.value));
            }

            if (formSchema[key].required) {
                _validationSchema[key] = _validationSchema[key].required('Required');
            }
        }

        setFormData(_formData);
        setValidationSchema(Yup.object().shape({ ..._validationSchema }));
    }

    const getFormElement = (elementName: string, elementSchema: any) => {
        const props = {
            name: elementName,
            label: elementSchema.label,
            placeholder: elementSchema.placeholder,
            options: elementSchema.options,
            required: elementSchema.required
        };

        if (elementSchema.type === "text" || elementSchema.type === "email") {
            return <TextField {...props} />
        }

        if (elementSchema.type === "select") {
            return <SelectField  {...props} />
        }

    }

    const onSubmit = (values: any, { setSubmitting }: any) => {
        evaluateFormula(formula.id, values)
            .then(response => {
                if (response.error) {
                    setResult('An error occured');
                    setShowResult(true);
                } else {
                    setResult(response);
                    setShowResult(true);
                }
            }).finally(() => {
                setSubmitting(false);
            });
    }

    return (
        <>
            <div>
                <Form
                    enableReinitialize
                    initialValues={formData}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    <IonList>
                        <IonItem>
                            <IonLabel position="stacked">Name</IonLabel>
                            <IonInput readonly id="name" name="name" value={formula.name} ></IonInput>
                        </IonItem>

                        {formula?.expression && <IonItem>
                            <IonLabel position="stacked">Expression</IonLabel>
                            <IonTextarea readonly id="expression" name="expression" value={formula.expression} ></IonTextarea>
                        </IonItem>}

                        {formula.description &&
                            <IonItem>
                                <IonLabel position="stacked">Description</IonLabel>
                                <IonTextarea readonly id="description" name="description" value={formula.description} ></IonTextarea>
                            </IonItem>
                        }

                        <IonItemDivider>
                            <IonLabel>
                                Enter below values
                            </IonLabel>
                        </IonItemDivider>

                        {Object.keys(formSchema).map((key, ind) => (
                            <div key={key}>
                                {getFormElement(key, (formSchema as any)[key])}
                            </div>
                        ))}

                        {showResult && <IonCard>
                            {Object.keys(result).map((key: string) => {
                                return (
                                    <IonItem>
                                        <IonLabel position="stacked">{key} </IonLabel>
                                        <IonNote color="dark">{result[key]}</IonNote>
                                    </IonItem>
                                )
                            })}
                        </IonCard>
                        }

                        <SubmitButton title="Calculate" />

                    </IonList>
                </Form>
            </div>
        </>
    );
}

export default EvaluateFormula;