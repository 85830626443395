import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonList, IonItem, IonLabel, IonSelect, IonSelectOption, IonGrid, IonRow, IonCol } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getPlotIdsByCrops, getPlotIdsByTags } from '../services/plot.service';
import { cropTypesState, plotTagsState } from '../services/state.service';
import './PlotFilter.css';
import { getAreas, getDistricts, getPlotIdsByLocation, getStates, getSubDistricts } from '../services/location.service';

export interface Filters {
    crop?: boolean;
    tag?: boolean;
    location?: boolean;
}

interface IProps {
    filter: any;
    filterChangeHandler: Function;
    onDoneHandler: Function;
    onCloseHandler: Function;
    hideFilters?: Filters;
}

const PlotFilter: React.FC<IProps> = (props) => {
    const cropTypes = useRecoilValue(cropTypesState);
    const plotTags = useRecoilValue(plotTagsState);

    const [selectedCrops, setSelectedCrops] = useState<string[]>(props?.filter?.crops || []);
    const [selectedTags, setSelectedTags] = useState<string[]>(props?.filter?.tags || []);

    const { hideFilters } = props;

    // location dropdown options
    const [states, setStates] = useState<any[]>([]);
    const [districts, setDistricts] = useState<any[]>([]);
    const [subDistricts, setSubdistricts] = useState<any[]>([]);
    const [area, setArea] = useState<any[]>([]);

    // selected locations
    const [selectedState, setSelectedState] = useState<string>('');
    const [selectedDistrict, setSelectedDistrict] = useState<string>('');
    const [selectedSubDistrict, setSelectedSubDistrict] = useState<string>('');
    const [selectedArea, setSelectedArea] = useState<string>('');

    // loading states
    const [isStateLoading, setIsStateLoading] = useState<boolean>(true);
    const [isDistrictLoading, setIsDistrictLoading] = useState<boolean>(false);
    const [isSubDistrictLoading, setIsSubDistrictLoading] = useState<boolean>(false);
    const [isAreaLoading, setIsAreaLoading] = useState<boolean>(false);

    useEffect(() => {
        getStates()
            .then((res: { state: string }[]) => {
                setStates(generateOptions(
                    res.map(obj => obj.state).sort()
                ));
                setIsStateLoading(false);
            })
    }, [])

    const compareWith = (o1: any, o2: any) => {
        if (Array.isArray(o2)) {
            return o2.indexOf(o1) !== -1;
        }
        return o1 === o2;
    };

    function getPayloadForLocation(){
        let payload: any = {};

        if(selectedState.length > 0) {
            payload.state = selectedState;
        }
      
        if (selectedDistrict.length > 0) {
            payload.district = selectedDistrict;
        }
    
        if (selectedSubDistrict.length > 0) {
            payload.subDistrict = selectedSubDistrict;
        }
    
        if (selectedArea.length > 0) {
            payload.area = selectedArea;
        }

        return payload;
    }

    async function onDone() {
        try {
            const plotIdsByCrop = await getPlotIdsByCrops(selectedCrops);
            const uniquePlotIdsByCrop = new Set(plotIdsByCrop.map((item: any) => item.plotId));

            const plotIdsByTag = await getPlotIdsByTags(selectedTags);
            const uniquePlotIdsByTag = new Set(plotIdsByTag.map((item: any) => item.plotId));

            const locationPayload = getPayloadForLocation();
            const plotsByLocation = selectedState.length > 0 ? await getPlotIdsByLocation(locationPayload) : [];
            const uniquePlotIdsByLocation = new Set(plotsByLocation.map((data: any) => data.plotId) as string[]);

            props.filterChangeHandler(Object.assign({}, props?.filter, { crops: selectedCrops, tags: selectedTags, location: locationPayload }));
            props.onDoneHandler(
                Array.from(uniquePlotIdsByCrop)
                .concat(
                    Array.from(uniquePlotIdsByTag)
                    .concat(Array.from(uniquePlotIdsByLocation))
                )
            );
            onClose();
        } catch (err: any) {
            console.log(err);
        }
    }

    function onReset() {
        props.filterChangeHandler({});
        props.onDoneHandler([]);
        onClose();
    }

    function onClose() {
        props.onCloseHandler(false);
    }

    function generateOptions(option: string[]) {
        return option?.map((opt) => {
            return {
                label: opt,
                value: opt
            };
        });
    }

    function handleStateChange(selected: string) {
        setSelectedState(selected);
        setIsDistrictLoading(true);
        getDistricts(selected)
            .then((res: { district: string }[]) => {
                // unselect others
                unselectOptions(['district', 'subDistrict', 'area']);

                setDistricts(generateOptions(
                    res.map(obj => obj.district).sort()
                ));
                setIsDistrictLoading(false);
            })
    }

    function handleDistrictChange(selected: string) {
        setSelectedDistrict(selected);
        setIsSubDistrictLoading(true);
        getSubDistricts(selectedState, selected)
            .then((res: { subDistrict: string }[]) => {
                unselectOptions(['subDistrict', 'area']);
                setSubdistricts(generateOptions(
                    res.map(obj => obj.subDistrict).sort()
                ));
                setIsSubDistrictLoading(false);
            })
    }

    function handleSubDistrictChange(selected: string) {
        setSelectedSubDistrict(selected);
        setIsAreaLoading(true);
        getAreas(selectedState, selectedDistrict, selected)
            .then((res: { area: string }[]) => {
                unselectOptions(['area']);
                setArea(generateOptions(
                    res.map(obj => obj.area).sort()
                ));
                setIsAreaLoading(false);
            })
    }

    function handleAreaChange(selected: string) {
        setSelectedArea(selected);
    }

    function handleDropdownChange(dropdown: string, selected: string) {
        const value = selected;
        switch (dropdown) {
            case 'state':
                if (value) handleStateChange(value);
                else {
                    unselectOptions(['state', 'district', 'subDistrict', 'area']);
                    setDistricts([]);
                    setIsDistrictLoading(false);
                }
                break;
            case 'district':
                if (value) handleDistrictChange(value);
                else {
                    unselectOptions(['district', 'subDistrict', 'area']);
                    setSubdistricts([]);
                    setIsSubDistrictLoading(false);
                }
                break;
            case 'subDistrict':
                if (value) handleSubDistrictChange(value);
                else {
                    setSelectedSubDistrict('');
                    unselectOptions(['subDistrict', 'area']);
                    setArea([]);
                    setIsAreaLoading(false);
                }
                break;
            case 'area':
                if (value) handleAreaChange(value);
                else {
                    unselectOptions(['area']);
                }
                break;
            default:
                return;
        }
    }
    
    /* 
        - function
        - unselects the locations passed in arguments
        - empty the dropdown options of its children, 
            i.e count == 0, it's parent
            count > 0, it's child
    */ 
    function unselectOptions(toUnselect: string[]) { 
        let cnt = 0;
        for (const field of toUnselect) {
            if (field === 'state') {
                setSelectedState('');
                cnt++;
            } else if (field === 'district') {
                setSelectedDistrict('');
                if (cnt++ > 0) setDistricts([]);
            } else if (field === 'subDistrict') {
                setSelectedSubDistrict('');
                if (cnt++ > 0) setSubdistricts([]);
            } else {
                setSelectedArea('');
                if (cnt++ > 0) setArea([]);
            }
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Filter</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonList>
                    {
                        !hideFilters?.crop && 
                        <IonItem>
                            <IonLabel position="stacked">Crops</IonLabel>
                            <IonSelect id="crops" name="crops" compareWith={compareWith} multiple={true} value={selectedCrops} placeholder="Select Crops" onIonChange={(e) => setSelectedCrops(e.detail.value)}>
                                {cropTypes && cropTypes.map((item: any) => {
                                    return <IonSelectOption key={item.cropId} value={item.cropId}>{item.cropName}</IonSelectOption>;
                                })}
                            </IonSelect>
                        </IonItem>
                    }

                    {
                        !hideFilters?.tag && 
                        <IonItem>
                            <IonLabel position="stacked">Tags</IonLabel>
                            <IonSelect id="tags" name="tags" compareWith={compareWith} multiple={true} value={selectedTags} placeholder="Select Tags" onIonChange={(e) => setSelectedTags(e.detail.value)}>
                                {plotTags && plotTags.map((item: any) => {
                                    return <IonSelectOption key={item.id} value={item.id}>{item.id}</IonSelectOption>;
                                })}
                            </IonSelect>
                        </IonItem>
                    }

                    { !hideFilters?.location &&
                        <>
                            <IonItem>
                                <IonLabel position="stacked">State</IonLabel>
                                <IonSelect id="states" name="states" compareWith={compareWith} value={selectedState} placeholder="Select State" disabled={isStateLoading} onIonChange={(e) => handleDropdownChange('state', e.detail.value)}>
                                    {!!states && states.map((item: any) => {
                                        return <IonSelectOption key={item.value} value={item.value}>{item.label}</IonSelectOption>;
                                    })}
                                </IonSelect>
                            </IonItem>

                            <IonItem>
                                <IonLabel position="stacked">District</IonLabel>
                                <IonSelect id="districs" name="districs" compareWith={compareWith} value={selectedDistrict} placeholder="Select District" disabled={selectedState?.length === 0 || isDistrictLoading} onIonChange={(e) => handleDropdownChange('district', e.detail.value)}>
                                    {!!districts && districts.map((item: any) => {
                                        return <IonSelectOption key={item.value} value={item.value}>{item.label}</IonSelectOption>;
                                    })}
                                </IonSelect>
                            </IonItem>

                            <IonItem>
                                <IonLabel position="stacked">Sub District</IonLabel>
                                <IonSelect id="subDistricts" name="subDistricts" compareWith={compareWith} value={selectedSubDistrict} placeholder="Select Sub District" disabled={selectedDistrict?.length === 0 || isSubDistrictLoading} onIonChange={(e) => handleDropdownChange('subDistrict', e.detail.value)}>
                                    {!!subDistricts && subDistricts.map((item: any) => {
                                        return <IonSelectOption key={item.value} value={item.value}>{item.label}</IonSelectOption>;
                                    })}
                                </IonSelect>
                            </IonItem>

                            <IonItem>
                                <IonLabel position="stacked">Area</IonLabel>
                                <IonSelect id="areas" name="areas" compareWith={compareWith} value={selectedArea} placeholder="Select Area" disabled={selectedSubDistrict?.length === 0 || isAreaLoading} onIonChange={(e) => handleDropdownChange('area', e.detail.value)}>
                                    {!!area && area.map((item: any) => {
                                        return <IonSelectOption key={item.value} value={item.value}>{item.label}</IonSelectOption>;
                                    })}
                                </IonSelect>
                            </IonItem>
                        </>
                    }

                </IonList>
                <IonGrid>
                    <IonRow>
                        <IonCol><IonButton color="danger" className="shiftRight" onClick={onReset}>Reset</IonButton></IonCol>
                        <IonCol><IonButton onClick={onDone}>Done</IonButton></IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default PlotFilter;
