import { getData, postData } from "./common.service"

export async function getFormulas(text: string = '') {
    const qs = {
        where: {
            or:
                [
                    { name: { like: `.*${text}.*`, options: 'i' } },
                    { description: { like: `.*${text}.*`, options: 'i' } },
                ]
        },
        order: 'name ASC',
        limit: 20
    };
    return getData('/formulas?filter=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getFormula(formulaId: string) {
    return getData(`/formulas/${formulaId}`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function evaluateFormula(formulaId: string, params: any) {
    return postData(`/formulas/${formulaId}/evaluate`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: params
    }).then(data => data.json())
}