import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonFab,
  IonFabButton,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonLoading,
} from "@ionic/react";
import { add, chevronDownCircleOutline } from "ionicons/icons";
import Tickets from "../components/Tickets";
import { doReload } from "../hooks/common";
import useNavigation from "../hooks/useNavigation";
import { useRecoilValue } from "recoil";
import {
  openTicketsState,
  zammadProfileState,
} from "../services/state.service";
import { useEffect, useState } from "react";
import { getTickets } from "../services/zammad.service";
import { useLocation } from "react-router";
const TicketList: React.FC = () => {
  const navigateTo = useNavigation();
  const location = useLocation();
  const zammadProfile: any = useRecoilValue(zammadProfileState);
  const [tickets, setTickets] = useState<any>(useRecoilValue(openTicketsState));
  const updated: any = (location.state as any)?.updated ?? false;
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);


  function sortTickets(tickets: any[]) {
    return tickets.sort((a: any, b: any) => {
      if (a.priority_id !== b.priority_id) {
        return b.priority_id - a.priority_id;
      }
      return (
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
    });
  }

  const fetchData = (event?: CustomEvent) => {
    setLoading(true);  
    setHasError(false)
    setTickets([]);

    if (zammadProfile?.id) {
      getTickets(zammadProfile.id, 1)
        .then((items) => {
          setHasError(false)
          //stimulating
          // throw Error("stimulating error")
          const ticketsWithOpenState = [];
          for (const ticketId of items?.tickets) {
            const ticketData = items?.assets?.Ticket[ticketId];
            if ([1, 2, 3].includes(ticketData.state_id)) {
              ticketsWithOpenState.push(ticketData);
            }
          }
          const sortedTickets = sortTickets(ticketsWithOpenState);
          setTickets(sortedTickets);
          
        })
        .catch((error) => {
          console.error("Error fetching tickets:", error);
          setHasError(true);  
        })
        .finally(() => {
          setLoading(false); 
          console.log("final")
          event?.detail.complete();
        });
    }
  };

  const handleRefresh = (e: any) => {
    fetchData();
    e.detail.complete();
  };

  useEffect(() => {
    if (!!updated) {
      fetchData();
    }
  }, [updated]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>Tickets</IonTitle>
      </IonToolbar>
    </IonHeader>

    <IonContent>
      {/* IonLoading component to show loader */}
      <IonLoading isOpen={loading} message="Loading tickets..." spinner="circles" />

      {/* Pull-to-refresh mechanism */}
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent
          pullingIcon={chevronDownCircleOutline}
          pullingText="Pull to refresh"
          refreshingSpinner="circles"
          refreshingText="Refreshing..."
        ></IonRefresherContent>
      </IonRefresher>

      {/* Error Handling */}
      {hasError ? (
        <div style={{ color: "red", textAlign: "center" }}>
          <p>Error fetching tickets. Please refresh the page.</p>
        </div>
      ) : (
        <Tickets tickets={tickets} />
      )}

        {!hasError && (
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton>
              <IonIcon
                icon={add}
                onClick={() => navigateTo("/tabs/ticket", { newTicket: true })}
              />
            </IonFabButton>
          </IonFab>
        )}
      </IonContent>
    </IonPage>
  );
};


export default TicketList;
