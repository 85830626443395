import moment from "moment";
import { getData, postData } from "./common.service";

export const getObservationType = async (cropId: string, diseasePestId: string) => {
	const url = `/observation-types/${cropId}/${diseasePestId}`;
	return getData(url, {
		sendTokenInHeader: true,
		basePath: process.env.REACT_APP_CORE_API_BASEPATH,
	}).then(res => res.json());
}

export const postObservationData = async (payload: any) => {
	const url = "/observations";

	return postData(url, {
		sendTokenInHeader: true,
		basePath: process.env.REACT_APP_CORE_API_BASEPATH,
		body: payload,
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
	}).then(res => res.json());
}

/**
 * @function fetches observation for passed config in last 36 hours
 * @argument {object} { observationType: string, diseasePestId: string, plotId: string } 
 */
export const getPreviousSessions = async (filter: PreviousSessionArgs) => {
	const { observationTypeId, plotId, diseasePestId, date } = filter;

	const lowerDate = moment(date).subtract(36, "hours").toISOString();
	const upperDate = moment(date).add(36, "hours").toISOString();

	const filterQuery = {
		where: {
			and: [
				{ plotId }, 
				{ observationTypeId }, 
				{ diseasePestId },
				{ observationDate: { "gte": lowerDate } },
				{ observationDate: { "lte": upperDate } },
			]
		}
	}

	const url = "/observations?filter=" + JSON.stringify(filterQuery);

	return getData(url, {
		sendTokenInHeader: true,
		basePath: process.env.REACT_APP_CORE_API_BASEPATH,		
	}).then(res => res.json());
}

export const putObservationData = async (id: string, payload: any) => {
	const url = '/observations/' + id;

	return postData(url, {
		usePutMethod: true,
		sendTokenInHeader: true,
		basePath: process.env.REACT_APP_CORE_API_BASEPATH,
		body: payload,
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
	})
}

type PreviousSessionArgs = {
	observationTypeId: string;
	diseasePestId: string;
	plotId: string;
	date?: string;
}