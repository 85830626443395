import { getData } from './common.service';

export const getStates = async (country: string = 'India') => {
  const url = `/regions/states?country=${country}`
  return getData(url, {
    sendTokenInHeader: true,
    basePath: process.env.REACT_APP_CORE_API_BASEPATH
  }).then(res => res.json())
}

export const getDistricts = async (state: string) => {
  const url = `/regions/districts?state=${state}`
  return getData(url, {
    sendTokenInHeader: true,
    basePath: process.env.REACT_APP_CORE_API_BASEPATH
  }).then(res => res.json())
}

export const getSubDistricts = async (state: string, district: string) => {
  const url = `/regions/sub-districts?state=${state}&district=${district}`
  return getData(url, {
    sendTokenInHeader: true,
    basePath: process.env.REACT_APP_CORE_API_BASEPATH
  }).then(res => res.json())
}

export const getAreas = async (state: string, district: string, subDistrict: string) => {
  const url = `/regions/areas?state=${state}&district=${district}&subDistrict=${subDistrict}`
  return getData(url, {
    sendTokenInHeader: true,
    basePath: process.env.REACT_APP_CORE_API_BASEPATH
  }).then(res => res.json())
}

export const getPlotIdsByLocation = async (payload: any) => {
  let url = '/plots/by-location';
  if(payload.state) {
    url+=`?state=${payload.state}`
  }
  if(payload.district) {
    url+=`&district=${payload.district}`
  }
  if(payload.subDistrict) {
    url+=`&subDistrict=${payload.subDistrict}`
  }
  if(payload.area) {
    url+=`&area=${payload.area}`
  }
  return getData(url, {
    sendTokenInHeader: true,
    basePath: process.env.REACT_APP_CORE_API_BASEPATH
  }).then(res => res.json())
}

export const getTargetRegions = async () => {
  const qs = {
    where: {
      targetRegion: true
    },
  };

  const url = `/regions?filter=${JSON.stringify(qs)}`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: process.env.REACT_APP_CORE_API_BASEPATH,
  }).then(res => res.json());
};

