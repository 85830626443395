import { getData, postData } from "./common.service";

export async function getMaintenances(text: string = '', skip = 0) {
    const qs = {
        where: {
            or:
                [
                    { plotId: { like: `.*${text}.*`, options: 'i' } },
                    { farmId: { like: `.*${text}.*`, options: 'i' } },
                    { farmUserId: { like: `.*${text}.*`, options: 'i' } },
                    { servicedBy: { like: `.*${text}.*`, options: 'i' } },
                    { maintenanceType: { like: `.*${text}.*`, options: 'i' } }
                ]
        },
        order: 'date DESC',
        limit: 20,
        skip: skip
    };
    return getData('/maintenances?filter=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getUpcomingTasks(assignedTo: string) {
    const qs = {
        where: {
            servicedBy: assignedTo,
            done:false,
            date: { gte: new Date().setHours(0, 0, 0, 0) }
        },
        order: 'date ASC',
        limit: 10
    };
    return getData('/maintenances?filter=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getMaintenance(maintenanceId: string) {
    return getData(`/maintenances/${maintenanceId}`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getMaintenanceConfig() {
    return getData('/maintenances/config', {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function addMaintenance(data: any) {
    return postData('/maintenances', {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    })
}

export async function updateMaintenance(data: any) {
    return postData(`/maintenances/${data.id}`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        usePutMethod: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    })
}