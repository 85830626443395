import { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonModal,
  IonPicker,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { getFarmIdsOfUser, getPlotIdsOfFarm } from "../services/farm.service";
import {
  getZammadUserByMobile,
  getUserListByRole,
  createUser,
} from "../services/zammad.service";
import { makeCall } from "../hooks/common";
import UserList from "../pages/UserList";
import { getUser } from "../services/user.service";

const FarmPicker = {
  name: "FarmId",
  options: [] as any[],
};

const setFarmPicker = (farmIds: any[]) => {
  FarmPicker.options = [];
  farmIds.forEach((item) => {
    FarmPicker.options.push({
      text: item.farmId,
      value: item.farmId,
    });
  });
};

const PlotPicker = {
  name: "PlotId",
  options: [] as any[],
};

const setPlotPicker = (plotIds: any[]) => {
  PlotPicker.options = [];
  plotIds.forEach((item) => {
    PlotPicker.options.push({
      text: item.plotId,
      value: item.plotId,
    });
  });
};

interface IProps {
  formik?: any;
  isUpdate?: boolean;
}

const NewTicket: React.FC<IProps> = (props) => {
  const { formik, isUpdate = false } = props;

  const [showSelectUserModal, setShowSelectUserModal] = useState(false);
  const [showAgentUserModal, setShowAgentUserModal] = useState(false);

  const [onError, setOnError] = useState("");

  function setCustomerUserId(item: any) {
    formik.setFieldValue("customerUserId", item.farmUserId);
    formik.setFieldValue("customerMobile", item.mobile);
    formik.setFieldValue("customerId", "");
    formik.setFieldValue("farmId", "");
    formik.setFieldValue("plotId", "");
    formik.setFieldValue("title", "");
    formik.setFieldValue("body", "");
    setShowSelectUserModal(false);
  }

  const compareWith = (o1: any, o2: any) => {
    if (Array.isArray(o2)) {
      return o2.indexOf(o1) !== -1;
    }
    return o1 === o2;
  };

  const [farmPickerIsOpen, setFarmPickerIsOpen] = useState(false);
  useEffect(() => {
    let mounted = true;
    if (formik.values.customerUserId) {
      getFarmIdsOfUser(formik.values.customerUserId).then((items) => {
        if (mounted) {
          setFarmPicker(items);
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [formik.values.customerUserId]);

  useEffect(() => {
    if (formik.values.customerMobile) {
      getZammadUserByMobile(formik.values.customerMobile).then(
        (profile: any[]) => {
          if (profile[0]?.id) {
            formik.setFieldValue("customerId", profile[0]?.id);
          } else {
            getUser(formik.values.customerUserId).then((res) => {
              const [firstName, ...lastName] = (res.name ?? "").split(" ");
              const newUser = {
                firstname: firstName,
                lastname: lastName.join(" "),
                mobile: formik.values.customerMobile,
                roles: ["Customer"],
              };
              createUser(newUser).then((res) => {
                formik.setFieldValue("customerId", res.id);
              });
            });
          }
        }
      );
    }
  }, [formik.values.customerMobile]);

  const [plotPickerIsOpen, setPlotPickerIsOpen] = useState(false);
  useEffect(() => {
    let mounted = true;
    if (formik.values.farmId) {
      getPlotIdsOfFarm(formik.values.farmId).then((items) => {
        if (mounted) {
          setPlotPicker(items);
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [formik.values.farmId]);

  const [agentsList, setAgentsList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setSearchTerm("");
  }, [showAgentUserModal]);

  useEffect(() => {
    getUserListByRole(2).then((res) => setAgentsList(res));
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <IonList>
          {!isUpdate && (
            <>
              <IonItem>
                <IonLabel position="stacked">Customer Id</IonLabel>
                <IonInput
                  readonly
                  id="customerUserId"
                  name="customerUserId"
                  value={formik.values.customerUserId}
                  placeholder="Select Customer Id"
                  onClick={(e) => setShowSelectUserModal(true)}
                ></IonInput>
              </IonItem>
              {formik.touched.customerMobile && formik.errors.customerMobile ? (
                <div className="errorMsg">{formik.errors.customerMobile}</div>
              ) : null}

              <IonItem>
                <IonLabel position="stacked">Farm Id</IonLabel>
                <IonInput
                  readonly
                  disabled={!formik.values.customerMobile}
                  id="farmId"
                  name="farmId"
                  value={formik.values.farmId}
                  placeholder="Select Farm Id"
                  onClick={(e) => setFarmPickerIsOpen(true)}
                ></IonInput>
              </IonItem>
              {formik.touched.farmId && formik.errors.farmId ? (
                <div className="errorMsg">{formik.errors.farmId}</div>
              ) : null}

              <IonItem>
                <IonLabel position="stacked">Plot Id</IonLabel>
                <IonInput
                  readonly
                  disabled={!formik.values.farmId}
                  id="plotId"
                  name="plotId"
                  value={formik.values.plotId}
                  placeholder="Plot Id"
                  onClick={(e) => setPlotPickerIsOpen(true)}
                ></IonInput>
              </IonItem>
              {formik.touched.plotId && formik.errors.plotId ? (
                <div className="errorMsg">{formik.errors.plotId}</div>
              ) : null}

              <IonItem>
                <IonLabel position="stacked">Title</IonLabel>
                <IonInput
                  id="title"
                  name="title"
                  value={formik.values.title}
                  placeholder="Add a title"
                  onIonChange={(e) =>
                    formik.setFieldValue("title", e.detail.value)
                  }
                ></IonInput>
              </IonItem>
              {formik.touched.title && formik.errors.title ? (
                <div className="errorMsg">{formik.errors.title}</div>
              ) : null}

              <IonItem>
                <IonLabel position="stacked">Message</IonLabel>
                <IonTextarea
                  id="body"
                  name="body"
                  value={formik.values.body}
                  placeholder="Write a message"
                  onIonChange={(e) =>
                    formik.setFieldValue("body", e.detail.value)
                  }
                ></IonTextarea>
              </IonItem>
            </>
          )}
          {isUpdate && (
            <IonItem>
              <IonLabel position="stacked">Title</IonLabel>
              <IonInput
                readonly
                id="title"
                name="title"
                value={formik.values.title}
              ></IonInput>
            </IonItem>
          )}
          <IonItem>
            <IonLabel position="stacked">Assigned To</IonLabel>
            <IonInput
              readonly
              id="ownerId"
              name="ownerId"
              value={formik.values.ownerName}
              placeholder="Select Agent"
              onClick={(e) => setShowAgentUserModal(true)}
            ></IonInput>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Priority</IonLabel>
            <IonSelect
              id="priority"
              name="priority"
              compareWith={compareWith}
              value={formik.values.priority}
              placeholder="Priority"
              onIonChange={(e) =>
                formik.setFieldValue("priority", e.detail.value)
              }
            >
              <IonSelectOption key="1" value={1}>
                Low
              </IonSelectOption>
              <IonSelectOption key="2" value={2}>
                Medium
              </IonSelectOption>
              <IonSelectOption key="3" value={3}>
                High
              </IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonList>
      </form>

      {showSelectUserModal && (
        <IonModal isOpen={showSelectUserModal}>
          <UserList
            selectedValue={formik.values.customerUserId}
            onClickHandler={setCustomerUserId}
            onClose={setShowSelectUserModal}
          />
        </IonModal>
      )}
      {showAgentUserModal && (
        <IonModal isOpen={showAgentUserModal}>
          <IonHeader>
            <IonToolbar>
              {showAgentUserModal ? (
                <IonButtons slot="end">
                  <IonButton onClick={(e) => setShowAgentUserModal(false)}>
                    Close
                  </IonButton>
                </IonButtons>
              ) : (
                <IonButtons slot="start">
                  <IonMenuButton />
                </IonButtons>
              )}
              <IonTitle>Agents</IonTitle>
            </IonToolbar>
            <IonToolbar>
              <IonSearchbar
                placeholder="Search by name or mobile..."
                onIonChange={(e) => setSearchTerm(e.detail.value!)}
              ></IonSearchbar>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            {agentsList
              ?.filter((agent: any) =>
                searchTerm
                  ? `${agent.firstname} ${agent.lastname} ${agent.mobile}`
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  : true
              )
              .map((agent: any) => (
                <IonCard
                  key={agent.id}
                  onClick={() => {
                    formik.setFieldValue("ownerId", agent?.id);
                    formik.setFieldValue(
                      "ownerName",
                      `${agent?.firstname} ${agent?.lastname}`
                    );
                    setShowAgentUserModal(false);
                  }}
                >
                  <IonItem lines="none">
                    <IonLabel>
                      <h2>Name: {`${agent?.firstname} ${agent?.lastname}`}</h2>
                    </IonLabel>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>
                      <h2>
                        Mobile:{" "}
                        <a onClick={(e) => makeCall(e, agent?.mobile)}>
                          {agent?.mobile}
                        </a>
                      </h2>
                    </IonLabel>
                  </IonItem>
                </IonCard>
              ))}
          </IonContent>
        </IonModal>
      )}

      <IonPicker
        isOpen={farmPickerIsOpen}
        columns={[FarmPicker]}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: (value) => {
              setFarmPickerIsOpen(false);
            },
          },
          {
            text: "Confirm",
            handler: (value) => {
              formik.values.farmId = value.FarmId.value;
              formik.values.plotId = "";
              setFarmPickerIsOpen(false);
            },
          },
        ]}
      ></IonPicker>

      <IonPicker
        isOpen={plotPickerIsOpen}
        columns={[PlotPicker]}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: (value) => {
              setPlotPickerIsOpen(false);
            },
          },
          {
            text: "Confirm",
            handler: (value) => {
              formik.values.plotId = value.PlotId.value;
              setPlotPickerIsOpen(false);
            },
          },
        ]}
      ></IonPicker>

      <IonToast
        isOpen={!!onError}
        onDidDismiss={() => setOnError("")}
        message={onError}
        duration={2000}
        color="danger"
      />
    </>
  );
};

export default NewTicket;
