import * as React from "react";
import "./CustomSearchBox.css"

type searchBoxProp = {
  setSearchText: any,
  placeholder?: string,
  searchText?: string
};


export default function CustomSearchBox(props: searchBoxProp) {
  return (
    
      <div className="searchBoxContainer">
        <input
          type="search"
          name="plotSearchBox"
          id="plotSearchBox"
          value={props?.searchText}
          onChange={(e) => props.setSearchText(e.target.value)}
        placeholder={props.placeholder ?? "Search"}
        />
       
      </div>
   
  );
}
