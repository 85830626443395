import React from 'react';
import MaintenanceCard from './MaintenanceCard';

interface IProps {
    maintenances: any[];
}

const Maintenances: React.FC<IProps> = (props) => {
    return (
        <>
            {props.maintenances && props.maintenances.map((maintenance, index) => <MaintenanceCard maintenance={maintenance} key={index} />)}
        </>
    );
};

export default Maintenances;