import React, { useCallback, useState } from 'react';
import { IonContent, IonPage, IonList, IonItem, IonLabel, IonInput, IonButton, IonToast, IonCard, IonImg, IonGrid, IonRow, IonCol, IonFooter } from '@ionic/react';
import './Login.css';
import { generateOTP, login } from '../services/user.service';
import { handleErrors, setAccessToken } from '../services/common.service';

interface ComponentProps {
    connected: boolean;
    onSuccess: Function;
}

const Login: React.FC<ComponentProps> = (props: ComponentProps) => {
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState('');
    const [otp, setOtp] = useState('');
    const [otpGenerated, setOtpGenerated] = useState(false);
    const [otpError, setOtpError] = useState('');
    const [loginError, setLoginError] = useState('');
    const [otpMsg, setOtpMsg] = useState('');

    const sendOTPRequest = useCallback(() => {
        setOtpMsg('');
        generateOTP(userName)
            .then(handleErrors)
            .then((data) => {
                setOtpGenerated(true);
                setUserId(data.farmUserId);
                setOtpMsg('OTP generated successfully');
            }).catch(err => {
                setOtpError(err);
            });
    }, [userName]);

    const sendLoginRequest = useCallback(() => {
        const credentials = {
            farmUserId: userId || userName,
            otp: otp
        }
        login(credentials)
            .then(handleErrors)
            .then((data) => {
                setAccessToken(data.access_token);
                props.onSuccess(true);
            }).catch(err => {
                setLoginError(err);
            });
    }, [userName, otp]);

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonCard class="login-card">
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonImg class="logo" src='assets/logo.png' /></IonCol>
                            <IonCol><h1 className="title">Fyllo</h1></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol><h3 className="sub-title">Where technology meets agriculture</h3></IonCol>
                        </IonRow>
                        <IonRow class="login-content">
                            <IonCol>
                                <IonList>
                                    {!otpGenerated && <IonItem>
                                        <IonLabel>UserID</IonLabel>
                                        <IonInput id="userName" name="userName" value={userName} placeholder="UserId / Mobile Number" onIonChange={e => setUserName(e.detail.value!)}></IonInput>
                                    </IonItem>}

                                    {otpGenerated && <IonItem>
                                        <IonLabel>OTP</IonLabel>
                                        <IonInput id="otp" name="otp" type="password" value={otp} placeholder="Enter OTP" onIonChange={e => setOtp(e.detail.value!)}></IonInput>
                                    </IonItem>}

                                    <div className="button-panel">
                                        {otpGenerated && <IonButton expand="block" onClick={sendLoginRequest}>Login</IonButton>}

                                        <IonButton
                                            expand="block"
                                            disabled={!otpGenerated && (!userName || userName.trim().length === 0)}
                                            color={otpGenerated ? 'medium' : 'primary'}
                                            onClick={sendOTPRequest}>{otpGenerated ? 'Re-generate' : 'Generate'} OTP
                                        </IonButton>
                                    </div>
                                </IonList>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCard>

                <IonToast
                    isOpen={!!otpMsg}
                    onDidDismiss={() => setOtpMsg('')}
                    message={otpMsg}
                    duration={2000}
                    color="success"
                />

                <IonToast
                    isOpen={!!otpError}
                    onDidDismiss={() => setOtpError('')}
                    message={otpError}
                    duration={2000}
                    color="danger"
                />

                <IonToast
                    isOpen={!!loginError}
                    onDidDismiss={() => setLoginError('')}
                    message={loginError}
                    duration={2000}
                    color="danger"
                />
            </IonContent>
            <IonFooter>
            {!props.connected && <IonButton color="danger" expand="block">
                    Not connected to Internet
                </IonButton>}
            </IonFooter>
        </IonPage>
    );
};


export default Login;
