import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonMenuButton, IonBackButton } from '@ionic/react';
import { useState } from 'react';
import { Document, Page, pdfjs  } from 'react-pdf/dist/esm/entry.webpack';
import './PaymentQRCode.css';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PaymentQRCode: React.FC = () => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const filePath = process.env.REACT_APP_CORE_API_BASEPATH + '/payment-qr-code.pdf';

    function onDocumentLoadSuccess({ numPages }: any) {
        setNumPages(numPages);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Payment</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Payment</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div>
                    <Document
                        file={filePath}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page width={370} className="center-align" pageNumber={pageNumber} />
                    </Document>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default PaymentQRCode;
