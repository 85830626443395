import React from 'react';
import PendingPaymentCard from './PendingPaymentCard';

interface IProps {
    payments: any[];
}

const PendingPayments: React.FC<IProps> = (props) => {
    return (
        <>
            {props.payments && props.payments.map((payment, index) => <PendingPaymentCard payment={payment} key={index} />)}
        </>
    );
};

export default PendingPayments;