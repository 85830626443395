import React from 'react';
import FormulaCard from './FormulaCard';

interface IProps {
    formulas: any[];
}

const Formulas: React.FC<IProps> = (props) => {
    return (
        <>
            {props.formulas && props.formulas.map((Formula, index) => <FormulaCard formula={Formula} key={index} />)}
        </>
    );
};

export default Formulas;