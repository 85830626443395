import React from 'react';
import { IonCard, IonIcon, IonItem, IonLabel, useIonAlert } from '@ionic/react';
import { checkmarkSharp } from 'ionicons/icons';
import { authorizePayment } from '../services/payment.service';
import { handleErrors } from '../services/common.service';

interface IProps {
    payment: any;
}

const PendingPaymentCard: React.FC<IProps> = (props) => {
    const [present] = useIonAlert();

    const authorize = (payment: any) => {
        present({
            header: 'Authorize',
            message: `Authorize payment of ${payment.currency} ${payment.amount}`,
            buttons: [
                'Cancel',
                {
                    text: 'Ok', handler: async (d) => {
                        await authorizePayment(payment.id).then(handleErrors);
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }
                },
            ],
            onDidDismiss: (e) => console.log('did dismiss'),
        });
    };

    return (
        <>
            <IonCard>
                <IonItem>
                    <IonLabel>{props.payment.userId}</IonLabel>
                    <IonIcon icon={checkmarkSharp} onClick={e => authorize(props.payment)}></IonIcon>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Amount: {props.payment.amount} {props.payment.currency}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Created On: {new Intl.DateTimeFormat('en-GB', {
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric',
                        }).format(new Date(props.payment.createdAt))}</h2>
                    </IonLabel>
                </IonItem>
                {props.payment.items?.map((item: any) => {
                    return <>
                        <IonItem lines='none'>
                            <IonLabel>
                                <h2>PlotId: {item.plotId}</h2>
                            </IonLabel>
                        </IonItem>
                    </>
                })
                }
            </IonCard>
        </>
    );
};

export default PendingPaymentCard;