import {    IonFab, IonFabButton, IonIcon,
    IonRefresher, IonRefresherContent, IonInfiniteScroll, IonInfiniteScrollContent
} from '@ionic/react';
import { add, chevronDownCircleOutline } from 'ionicons/icons';
import SalesPlans from '../components/SalesPlans';
import { doReload } from '../hooks/common';
import { useEffect, useState } from 'react';
import useDebounce from "../hooks/useDebounce";
import useNavigation from '../hooks/useNavigation';
import { useLocation } from 'react-router';
import { getMonthlySalesPlans } from '../services/sales.service';
import CustomSearchBox from './CustomSearchBox/CustomSearchBox';

const SalesPlanCardList:React.FC = () => {
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
    const [salesPlans, setSalesPlans] = useState([] as any[]);
    const [searchText, setSearchText] = useState('');
    
    const [skip, setSkip] = useState<number>(20);
    const navigateTo = useNavigation();
    const location = useLocation();
    const debounceSearchText = useDebounce(searchText, 1000);

    useEffect(() => {
        fetchPlans();
    }, [debounceSearchText]);
    
    useEffect(() => {
        if ((location?.state as any)?.reload) {
            fetchPlans();
            (location?.state as any).reload = false;
        }
    })

    const fetchPlans = () => {
        let mounted = true;
        getMonthlySalesPlans(debounceSearchText)
            .then(items => {
                if (mounted) {
                    setSalesPlans(items);
                }
            });
        return () => { mounted = false };
    }
    

    async function fetchNext($event: CustomEvent<void>) {
        setSkip(skip + 20);
        getMonthlySalesPlans(debounceSearchText, skip)
            .then(items => {
                if (items?.length > 0) {
                    setSalesPlans([...salesPlans, ...items]);
                    setDisableInfiniteScroll(items.length < 20);
                } else {
                    setDisableInfiniteScroll(true);
                }
                ($event.target as HTMLIonInfiniteScrollElement).complete();
            });
    }
    return (
        <>
        
        <CustomSearchBox setSearchText={setSearchText} />
        <IonRefresher slot="fixed" onIonRefresh={doReload}>
            <IonRefresherContent
                pullingIcon={chevronDownCircleOutline}
                pullingText="Pull to refresh"
                refreshingSpinner="circles"
                refreshingText="Refreshing...">
            </IonRefresherContent>
        </IonRefresher>
        <SalesPlans salesPlans={salesPlans} />
        <IonInfiniteScroll threshold="100px"
            disabled={disableInfiniteScroll}
            onIonInfinite={(e: CustomEvent<void>) => fetchNext(e)}>
            <IonInfiniteScrollContent
                loadingText="Loading more...">
            </IonInfiniteScrollContent>
        </IonInfiniteScroll>
        {/* <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton>
                <IonIcon icon={add} onClick={() => navigateTo('/tabs/salesPlan', { salesPlanId: null })} />
            </IonFabButton>
        </IonFab> */}
        </>
    )
}

export default SalesPlanCardList; 

