import React, { useEffect, useState } from "react";
import {
  IonContent, IonHeader, IonPage,
  IonTitle, IonToolbar, IonButtons, IonMenuButton,
  IonRefresher, IonRefresherContent, IonLabel, IonButton,
  IonIcon, IonItem, IonInfiniteScroll, IonInfiniteScrollContent,
  IonCard,
  IonText,
} from '@ionic/react';
import { doReload } from "../hooks/common";
import { chevronDownCircleOutline, bookmark, bookmarkOutline, closeCircleOutline } from "ionicons/icons";
import CustomSearchBox from "../components/CustomSearchBox/CustomSearchBox";
import useNavigation from "../hooks/useNavigation";
import useDebounce from "../hooks/useDebounce";
import { searchDevices } from "../services/device.service";

const BOOKMARK_LIMIT = 5;
const MIN_SEARCH_TEXT_LENGTH = 3;
const PAGINATION_SIZE = 20;

type TDevice = {
  imei?: string;
  deviceId?: string;
  isAssigned?: boolean;
  plotId?: string;
}

type DevicesProps = {
  devices: Array<TDevice>,
  updateBookmark: () => void;
}


/**
 * Bookmark util functions
 * device: string
 *  `{id: string, imei: string}`
 */
const getBookmarkedDevices = (): string[] => {
  return JSON.parse(localStorage.getItem('bookmarked-devices') ?? '[]');
}

const bookmarkDevice = (device: string) => {
  let bookmarkedDevices = getBookmarkedDevices();
  if (bookmarkedDevices.length > BOOKMARK_LIMIT) {
    alert('Max limit reached, remove bookmarks to add it');
    return;
  }
  bookmarkedDevices = [device, ...bookmarkedDevices];
  localStorage.setItem('bookmarked-devices', JSON.stringify(bookmarkedDevices));
}

const removeBookmark = (device: string) => {
  let bookmarkedDevices = getBookmarkedDevices();
  bookmarkedDevices = bookmarkedDevices.filter(deviceId => deviceId !== device);
  localStorage.setItem('bookmarked-devices', JSON.stringify(bookmarkedDevices));
}

const isBookmarked = (device: string) => {
  let bookmarkedDevices = getBookmarkedDevices();
  return bookmarkedDevices.includes(device);
}

const toggleBookmark = (device: string, bookmarked: boolean) => {
  if (bookmarked) removeBookmark(device);
  else bookmarkDevice(device);
}


/**
 * @component renders devices list
 * @props 
 *    devices: list of devices to render
 *    updateBookmark: function to toggle the clickedOnBookmark state
 */
const Devices: React.FC<DevicesProps> = (props) => {
  const { devices, updateBookmark } = props;
  const navigateTo = useNavigation();

  return (
    <>
      {
        devices.map((device) => {
          const id = JSON.stringify({
            id: device.deviceId ?? '',
            imei: device.imei ?? ''
          });

          const bookmarked = isBookmarked(id);

          return (
            <IonCard key={`test-device-${device.deviceId}-${device.imei}`}>
              {device.imei && <IonItem><IonLabel> IMEI : {device.imei}</IonLabel></IonItem>}
              {device.deviceId && <IonItem lines="none"><IonLabel> Device Id : {device.deviceId}</IonLabel></IonItem>}
              {device.isAssigned && (<IonItem lines="none"><IonLabel> PlotId : {device.plotId}</IonLabel> </IonItem>)}

              <IonItem lines="none">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <IonButton size="default" onClick={() => navigateTo('/tabs/test-devices/graph', { deviceId: device.deviceId, imei: device.imei })} >View Graph</IonButton>
                  <IonButton fill="clear" onClick={() => {
                    toggleBookmark(id!, bookmarked);
                    updateBookmark();
                  }} ><IonIcon icon={bookmarked ? bookmark : bookmarkOutline}></IonIcon></IonButton>
                </div>
              </IonItem>
            </IonCard>
          )
        })
      }
    </>
  )
}


/**
 * @component renders test-device screen
 */
const TestDevice: React.FC = () => {
  const navigateTo = useNavigation();

  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(0);

  // search text states
  const [searchText, setSearchText] = useState('');
  const debounceSearchText = useDebounce(searchText, 1000);

  // devices to render
  const [devices, setDevices] = useState<Array<TDevice>>([]);
  const [latestDevices, setLatestDevices] = useState<Array<TDevice>>([]);

  // bookmarks
  const [bookmarkedDevices, setBookmarkedDevices] = useState<string[]>([]);

  // flag to re-fetch bookmarked devices on every state change (doesn't matter if it's true or false)
  const [clickedOnBookmark, setClickedOnBookmark] = useState<boolean>(false); 

  useEffect(() => {
    getLatestDevices();
  }, [])

  useEffect(() => {
    setBookmarkedDevices(getBookmarkedDevices());
  }, [clickedOnBookmark])


  /**
   * updates devices list
   * if searchLength >= MIN_SEARCH_TEXT_LENGTH
   *   - makes api call
   * if input field is cleared
   *   - renders the latest devices
   */
  useEffect(() => {
    setSkip(0);
    if (debounceSearchText.length >= MIN_SEARCH_TEXT_LENGTH) {
      searchDevices(debounceSearchText, 0).then((items) => {
        updateDevices(items, true);
      });
    } else if (debounceSearchText.length === 0) {
      updateDevices(latestDevices, true);
    }
  }, [debounceSearchText])

  async function getLatestDevices() {
    searchDevices('', 0, 20)
    .then((items) => {
      setLatestDevices(items);
      updateDevices(items, true);
    })
  }

  async function fetchNext($event: CustomEvent<void>) {
    const nextSkip = skip + PAGINATION_SIZE;
    searchDevices(debounceSearchText, nextSkip).then((items) => {
      updateDevices(items, false);
      setSkip(nextSkip);
      ($event.target as HTMLIonInfiniteScrollElement).complete();
    });
  }

  /**
   * @function updates devices list
   * @param items array of devices
   * @param firstCall states whether it's first call or not
   *  firstCall will be true on
   *    - initial render
   *    - searched something / cleared search box
   * 
   *  firstCall will be false on
   *    - scrolling
   * 
   *  if firstCall:
   *    replaces the devices list with `items`
   *  else:
   *    appends the `items` in devices
   */
  function updateDevices(items: Array<TDevice>, firstCall: boolean = true) {
    const formatedItems: Array<TDevice> = items.map(item => {
      const plotId = item.plotId ?? '';
      const deviceId = item.deviceId ?? '';
      const imei = item.imei ?? '';
      const isAssigned = !!(item?.isAssigned);

      return {
        plotId, deviceId, imei, isAssigned
      }
    });

    if (firstCall) {
      setDevices(formatedItems);
    } else {
      setDevices(devices => [...devices, ...formatedItems]);
    }

    if (items?.length > 0) {
      setDisableInfiniteScroll(items.length < PAGINATION_SIZE);
    } else {
      setDisableInfiniteScroll(true);
    }
  }

  /**
   * @function toggles the clickedOnBookmark state
   * to be called on every bookmark action
   */
  const updateBookmark = () => {
    setClickedOnBookmark(clickedOnBookmark => !clickedOnBookmark);
  }

  const remove = (deviceId: string) => {
    removeBookmark(deviceId);
    updateBookmark();
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Test Devices</IonTitle>
        </IonToolbar>
        <IonItem lines="none">
          <div className="searchBarContainer">
            <div className="sbContainer">
              <CustomSearchBox placeholder="Search (min 3 digits)" setSearchText={setSearchText} />
            </div>
          </div>
        </IonItem>

        <IonItem>
          <div style={{ display: 'flex', overflow: 'auto', paddingBottom: '10px' }} >
            {
              bookmarkedDevices.map((bookmarked, index) => {
                const device = JSON.parse(bookmarked);
                const imei = device.imei ?? '';
                const id = device.id ?? '';

                return (
                  <span
                    style={{
                      position: 'relative',
                      border: '0.5px solid blue',
                      paddingRight: '20px',
                      borderRadius: '5px',
                      marginRight: '5px'
                    }}
                    key={`bookmarked-${id}-${imei}-${index}`}
                  >
                    <IonButton
                      color="primary"
                      size="small"
                      fill="clear"
                      onClick={() => navigateTo('/tabs/test-devices/graph', { deviceId: id, imei })}
                    >
                      {imei}
                    </IonButton>
                    <span style={{ position: 'absolute', right: '-8px', top: '-1px' }}>
                      <IonButton onClick={() => remove(bookmarked)} size="small" fill="clear" >
                        <IonIcon icon={closeCircleOutline} ></IonIcon>
                      </IonButton>
                    </span>
                  </span>
                )
              })
            }
          </div>

          {
            bookmarkedDevices.length === 0 && <IonText color="medium"> Bookmark devices to display here </IonText>
          }
        </IonItem>
      </IonHeader>

      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doReload}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing..."
          ></IonRefresherContent>
        </IonRefresher>

        <Devices devices={devices} updateBookmark={updateBookmark} />

        <IonInfiniteScroll
          threshold="100px"
          disabled={disableInfiniteScroll}
          onIonInfinite={(e: CustomEvent<void>) => fetchNext(e)}
        >
          <IonInfiniteScrollContent loadingText="Loading more..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>

    </IonPage>
  )
}

export default TestDevice;