import React from "react";
import { IonCard, IonItem, IonLabel } from "@ionic/react";
import useNavigation from "../hooks/useNavigation";

interface IProps {
  profile: any;
}

const FarmerProfileCard: React.FC<IProps> = (props) => {
  const navigateTo = useNavigation();

  const renderFilteredProfileFields = (maxFields: number) => {
    const excludedFields = ["id", "created_date"];

    const profileEntries = Object.entries(props.profile?.profile);

    const filteredEntries = profileEntries
      .filter(
        ([key, value]) => !excludedFields.includes(key) && !Array.isArray(value)
      )
      .slice(0, maxFields);

    return filteredEntries?.map(([key, value], index) => (
      <IonItem key={index} lines="none">
        <IonLabel>
          <h2>{`${key}: ${value}`}</h2>
        </IonLabel>
      </IonItem>
    ));
  };

  return (
    <>
      <IonCard
        onClick={() =>
          navigateTo("/tabs/farmer-profile", { profileId: props.profile.id })
        }
      >
        {renderFilteredProfileFields(4)}
      </IonCard>
    </>
  );
};

export default FarmerProfileCard;
