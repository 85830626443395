import {
    IonContent, IonHeader, IonPage,
    IonTitle, IonToolbar, IonButtons, IonMenuButton,
    IonSearchbar, IonFab, IonFabButton, IonIcon,
    IonRefresher, IonRefresherContent, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonToggle, IonAlert, useIonAlert
} from '@ionic/react';
import { add, chevronDownCircleOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Interactions from '../components/Interactions';
import { doReload } from '../hooks/common';
import useNavigation from '../hooks/useNavigation';
import { handleErrors } from '../services/common.service';
import { getInteractions, updateInteraction } from '../services/interaction.service';
import { userProfileState } from '../services/state.service';
// debounce hook
import useDebounce from "../hooks/useDebounce";
import CustomSearchBox from '../components/CustomSearchBox/CustomSearchBox';

const InteractionList: React.FC = () => {
    const [searchText, setSearchText] = useState('');
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
    const [showAutoGenerated, setShowAutoGenerated] = useState<boolean>(false);
    const [skip, setSkip] = useState<number>(20);
    const navigateTo = useNavigation();
    const userProfile: any = useRecoilValue(userProfileState);
    const [present] = useIonAlert();
    const debounceSearchText = useDebounce(searchText, 1000);

    const [interactions, setInteractions] = useState([] as any[]);
    useEffect(() => {
        let mounted = true;
        getInteractions(debounceSearchText, showAutoGenerated)
            .then(items => {
                if (mounted) {
                    setInteractions(items);
                    setDisableInfiniteScroll(false);
                }
            });
        return () => { mounted = false };
    }, [debounceSearchText, showAutoGenerated]);

    async function fetchNext($event: CustomEvent<void>) {
        setSkip(skip + 20);
        getInteractions(debounceSearchText, showAutoGenerated, skip)
            .then(items => {
                if (items?.length > 0) {
                    setInteractions([...interactions, ...items]);
                    setDisableInfiniteScroll(items.length < 20);
                } else {
                    setDisableInfiniteScroll(true);
                }
                ($event.target as HTMLIonInfiniteScrollElement).complete();
            });
    }

    function autoGeneratedToggle(e: CustomEvent) {
        setShowAutoGenerated(e.detail.checked);
    }

    const ignoreInteraction = (index: number, interactionId: string, event: CustomEvent) => {
        event.stopPropagation();
        present({
            header: 'Confirm!',
            message: 'Are you sure you want to ignore this interaction?',
            buttons: [
                'No',
                {
                    text: 'Yes', handler: async () => {
                        const ignoredInteractionData: any = {
                            ignored: true,
                            supportUserId: userProfile.username
                        };
                        await updateInteraction(interactionId, ignoredInteractionData).then(handleErrors);
                        interactions.splice(index, 1);
                        setInteractions([...interactions]);
                    }
                },
            ],
        });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Conversations</IonTitle>
                </IonToolbar>
                <CustomSearchBox setSearchText={setSearchText} />
                <IonItem>
                    <IonLabel>Show Auto-generated Interactions</IonLabel>
                    <IonToggle id="autoGeneratedToggle" name="autoGeneratedToggle" checked={showAutoGenerated} onIonChange={autoGeneratedToggle} />
                </IonItem>
            </IonHeader>
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={doReload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>
                <Interactions interactions={interactions} ignoreInteractionHandler={ignoreInteraction} />
                <IonInfiniteScroll threshold="100px"
                    disabled={disableInfiniteScroll}
                    onIonInfinite={(e: CustomEvent<void>) => fetchNext(e)}>
                    <IonInfiniteScrollContent
                        loadingText="Loading more...">
                    </IonInfiniteScrollContent>
                </IonInfiniteScroll>
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton>
                        <IonIcon icon={add} onClick={() => navigateTo('/tabs/interaction', { interactionId: null })} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
        </IonPage>
    );
};

export default InteractionList;
