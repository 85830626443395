import { getData, postData } from "./common.service";

export async function getMonthlySalesPlans(text: string = '', skip = 0) {
    const qs = {
        where: {
            and: [
                {
                    or:
                        [
                            { month: { like: `.*${text}.*`, options: 'i' } },
                            { year: { like: `.*${text}.*`, options: 'i' } },
                            { district: { like: `.*${text}.*`, options: 'i' } },
                            { state: { like: `.*${text}.*`, options: 'i' } },
                            { subDistrict: { like: `.*${text}.*`, options: 'i' } },
                            { village: { like: `.*${text}.*`, options: 'i' } },
                            { region: { like: `.*${text}.*`, options: 'i'} }
                        ]
                }
            ]
        },
        order: 'created_date DESC',
        limit: 20,
        skip: skip
    };
    return getData('/monthly-sales-plans?filter=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getAllSalesActivities() {
    const qs = {
        order: 'order ASC',
    };
    return getData("/sales-activity-types?filter=" + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getSalesActivities(text: string = '', activityIds: string[] = [], skip = 0){
    
    const qs: any = {
        where: {
            or:[
                {comment: { like: `.*${text}.*`, options: 'i' }},
                {userid: { like: `.*${text}.*`, options: 'i' }},
                {servicedBy: { like: `.*${text}.*`, options: 'i' }},
            ]
            
        },
        order: 'date DESC',
        skip: skip,
        limit: 20,
        include: [
            {
              relation: "salesActivityType",
              scope: {
                offset: 0,
                limit: 100,
                skip: 0,
                order: "string",
                fields: {}
                
              }
            }
          ]
       
    };
    if(activityIds.length){
        qs.where = {
            and:[
                {id: {inq: activityIds}},
                qs.where
            ]
            
        }
    }
    return getData("/sales-activities?filter=" + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getSalesActivityByActivityTypeId(salesActivityIds: string[]){
    const qs = {
        where: {
            salesActivityTypeId: {
                inq: salesActivityIds
            }
            },
            fields: ['id']
        };
    return getData('/sales-activities?filter=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH, 
        sendTokenInHeader: true, 
    }).then(data => data.json())
};

export async function getPlanById(salesPlanId: string) {
    return getData(`/monthly-sales-plans/${salesPlanId}`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getCompletedSalesActivity(planId: string) {
    const qs = {
        where: {
            monthlySalesPlanId: planId
        }
    }
    return getData('/sales-activities?filter='+JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function addSalesPlan(data: any) {
    return postData('/monthly-sales-plans', {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    })
}

export async function updateSalesPlan(id: string, data: any) {
    return postData(`/monthly-sales-plans/${id}`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        usePatchMethod: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    })
}

export async function addSalesActivity(data: any) {
    return postData('/sales-activities', {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    })
}
