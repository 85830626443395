import { IonCard, IonSlides, IonSlide, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonAvatar, IonHeader, IonPage, IonTitle, IonToolbar, IonSearchbar, IonButtons, IonButton, IonList, IonLabel, IonItem, IonIcon, IonRefresher, IonRefresherContent, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import { checkmark, chevronDownCircleOutline } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
// debounce hook
import './SelectItem.css';
import useDebounce from "../hooks/useDebounce";
import CustomSearchBox from './CustomSearchBox/CustomSearchBox';
import { RefresherEventDetail } from '@ionic/core';
interface IProps {
    label: string;
    items?: any[];
    selectedValue: string;
    labelKey: string;
    displayType?: string;
    valueKey: string;
    setItem: Function;
    onClose: Function;
    onRefresh?: (event: CustomEvent<RefresherEventDetail>) => void;
    getPaginatedItems?: Function;
    selectedItems?: any[];
    batchSize?: number;
}
const slideOpts = {
    initialSlide: 1,
    speed: 500
};
const SelectItem: React.FC<IProps> = (props) => {
    const batchSize = props?.batchSize || 20;
    const showPaginatedList = Boolean(props.getPaginatedItems);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [fetchedItems, setFetchedItems] = useState([] as any[]);
    const [skip, setSkip] = useState(batchSize);
    const [disableInfinteScroll, setDisableInfinteScroll] = useState(false);
    const debounceSearchText = useDebounce(searchText, 1000);

    useEffect(() => {
        if (props.getPaginatedItems) {
            setLoading(true);
            setSkip(batchSize);
            props.getPaginatedItems(debounceSearchText, 0, batchSize)
                .then((it: any[]) => {
                    let filteredIt = it;
                    if (props.selectedItems) {
                        filteredIt = it.filter((i) => {
                            return !props.selectedItems?.includes(i[props.valueKey]);
                        })
                    }
                    setFetchedItems(filteredIt);
                    setLoading(false);
                })
                .catch(() => setLoading(false));
        }
    }, [debounceSearchText, batchSize, props])

    function fetchNext($event: CustomEvent<void>) {
        setSkip((prevSkip: number) => prevSkip + batchSize);
        props.getPaginatedItems && props.getPaginatedItems(debounceSearchText, skip, batchSize)
            .then((it: any[]) => {
                if (it?.length > 0) {
                    setFetchedItems(items => [...items, ...it]);
                    setDisableInfinteScroll(Boolean(it.length < batchSize));
                }
                ($event.target as HTMLIonInfiniteScrollElement).complete();
            });
    }
    return (
        <IonPage >
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton onClick={e => props.onClose(false)}>
                            Close
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{props.label}</IonTitle>
                </IonToolbar>
                <CustomSearchBox setSearchText={setSearchText} />
            </IonHeader>
            <IonContent >
                <IonRefresher slot="fixed" onIonRefresh={e => props.onRefresh ? props.onRefresh(e) : e.detail.complete()}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {
                    showPaginatedList ?
                        <>
                            <IonList>
                                {fetchedItems && fetchedItems.map((item, index) => {
                                    return (
                                        item[props.labelKey].toLocaleLowerCase().indexOf(debounceSearchText.toLocaleLowerCase().trim()) > -1
                                        || item.name.toLocaleLowerCase().indexOf(debounceSearchText.toLocaleLowerCase().trim()) > -1)
                                        && <IonItem key={index} button onClick={e => props.setItem(item)} detail={false}>
                                            <IonLabel>{item[props.labelKey]}</IonLabel>
                                            {props.selectedValue === item[props.valueKey]
                                                && <IonIcon icon={checkmark} slot="end" />}
                                        </IonItem>;
                                })}
                                {loading ?
                                    <IonItem>
                                        <IonLabel style={{ width: "100%", textAlign: "center" }}>
                                            {"Loading..."}
                                        </IonLabel>
                                    </IonItem>
                                    :
                                    <>
                                        {fetchedItems.length === 0 &&
                                            <IonItem>
                                                <IonLabel style={{ width: "100%", textAlign: "center" }}>
                                                    {"No Data"}
                                                </IonLabel>
                                            </IonItem>
                                        }
                                    </>
                                }
                            </IonList>
                            <IonInfiniteScroll
                                threshold="100px"
                                disabled={disableInfinteScroll}
                                onIonInfinite={(e: CustomEvent<void>) => fetchNext(e)}
                            >
                                <IonInfiniteScrollContent loadingText="Loading more..."></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </>
                        :
                        <IonList>
                            {props.items && props.displayType == 'card' && props.items.map((item, index) => {
                                return item[props.labelKey].toLocaleLowerCase().indexOf(debounceSearchText.toLocaleLowerCase().trim()) > -1
                                    &&
                                    <IonCard id='card-style' color={props.selectedValue === item[props.valueKey] ? "dark" : "light"} key={'card' + index} button
                                        onClick={e => {
                                            props.setItem(item);
                                            props.onClose(false);
                                        }}
                                    >
                                        <IonSlides pager={true} options={slideOpts}>
                                            <IonSlide className='card-image'>
                                                <img className='card-image' alt='Soil Type Pictures' src={item.src[0]} />
                                            </IonSlide>
                                            <IonSlide className='card-image'>
                                                <img className='card-image' alt='Soil Type Pictures' src={item.src[1]} />
                                            </IonSlide>
                                        </IonSlides>
                                        {/* <img className='card-image' alt='Soil Type Pictures' src={item.src[1]} /> */}
                                        <IonCardHeader id='card-header'>
                                            <IonCardTitle id='card-text'>{item[props.labelKey]}
                                                {props.selectedValue === item[props.valueKey]
                                                    && <IonIcon icon={checkmark} slot="end" />}
                                            </IonCardTitle>
                                            <IonCardSubtitle id='card-subttitle'>{item.description}</IonCardSubtitle>
                                        </IonCardHeader>
                                    </IonCard>

                                    ;
                            })}
                            {props.items && props.displayType != 'card' && props.items.map((item, index) => {
                                return item[props.labelKey].toLocaleLowerCase().indexOf(debounceSearchText.toLocaleLowerCase().trim()) > -1
                                    && <IonItem
                                        key={'list' + index}
                                        button
                                        onClick={e => props.setItem(item)}
                                        detail={false}>
                                        <IonLabel>{item[props.labelKey]}</IonLabel>
                                        {props.selectedValue === item[props.valueKey]
                                            && <IonIcon icon={checkmark} slot="end" />}

                                    </IonItem>;
                            })}
                        </IonList>
                }
            </IonContent>
        </IonPage>
    );
};

export default SelectItem;
